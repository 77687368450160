<div class="general-container register flex flex-col" [ngClass]="{'mob-general': isMobileApp !== undefined}">
    <div class="body-content">
        <!--Pwd step-->
        <div *ngIf="loading" class="step pwd">
            <fun-skeleton-list></fun-skeleton-list>
        </div>
        <div *ngIf="!loading" class="step pwd">
            <div *ngFor="let stp of stepsL" class="header">
                <div class="span-container">
                    <span *ngIf="stp.name === 'dni_file' && stp.index === step" id="page-title">Foto del DNI</span>
                    <span *ngIf="stp.name === 'nie_file' && stp.index === step" id="page-title">Foto del NIE</span>
                    <span *ngIf="stp.name === 'passport_file' && stp.index === step" id="page-title">Foto del Pasaporte</span>
                </div>
                <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]" *ngIf="stp.name === 'dni_file' && stp.index === step">{{ 'register.identity.dni' | translate }}</h2>
                <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]" *ngIf="stp.name === 'nie_file' && stp.index === step">{{ 'register.identity.nie' | translate }}</h2>
                <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]" *ngIf="stp.name === 'passport_file' && stp.index === step">{{ 'register.identity.passport' | translate }}</h2>
                <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]" *ngIf="(stp.name === 'nie_number' || stp.name === 'passport_number') && stp.index === step">{{ 'register.identity.id' | translate }}</h2>
                <!-- <p class="font-sans text-center text-xl text-gray-40" *ngIf="stp.name === 'dni_file' && stp.index === step">{{ 'register.identity.dni_subtitle' | translate }}</p> -->
                <p class="font-sans text-center text-xl text-gray-40" *ngIf="stp.name === 'dni_file' && stp.index === step">{{ 'register.identity.recomendations' | translate }}</p>
                <p class="font-sans text-center text-xl text-gray-40" *ngIf="stp.name === 'nie_file' && stp.index === step">{{ 'register.identity.nie_subtitle' | translate }}</p>
                <p class="font-sans text-center text-xl text-gray-40" *ngIf="stp.name === 'passport_file' && stp.index === step">{{ 'register.identity.passport_subtitle' | translate }}</p>
                <p class="font-sans text-center text-xl text-gray-40" *ngIf="(stp.name === 'nie_number' || stp.name === 'passport_number') && stp.index === step">{{ 'register.identity.id_introduce' | translate }}</p>
            </div>
            <div *ngIf="!cameraEnable" class="w-full p-12">
                <fun-register-file-carrusel></fun-register-file-carrusel>
            </div>
            <div class="body">
                <div *ngFor="let stp of stepsL">
                    <div *ngIf="stp.name === 'dni_file' && stp.index === step">
                        <div class="flex flex-col md:flex-row gap-32 md:gap-12 w-full">
                            <div class="w-full">
                                <img *ngIf="urlImageFront && !cameraEnable" [src]="urlImageFront" />
                                <!--Para app-->
                                <div *ngIf="platform !== 'web' && !cameraEnable && urlImageFront" class="flex gap-12 items-center mt-32">
                                    <img class="file" id="documentId" src="../../../../../assets/images/img/file.svg" alt="file" />
                                    <p class="filename font-sans text-center text-l text-eggplant-100/[0.4]"> Parte frontal </p>
                                    <img (click)="errorFile()" class="delete" src="../../../../../assets/images/img/delete.svg" alt="x">
                                    <!--(click)="documentId.click()"-->
                                </div>

                                <div *ngIf="platform !== 'web' && !cameraEnable && !urlImageFront" class="rounded-xl border border-eggplant-100/[0.4] p-12 pt-[26px] mt-12">
                                    <fun-new-button (eventClick)="openCamera('front')" class="next-btn" buttonType="primaryLight" [title]="urlImageFront ? 'Repetir foto frontal' : 'Hacer foto frontal'"
                                    [classExtra]="' !w-full '" [size]="'xl'"></fun-new-button>
                                    <p class="font-sans text-xl text-eggplant-100 pt-[12px] text-center">Si lo prefieres</p>
                                    <fun-file-card  (getFile)="getFrontalFile($event)" (errorEvent)="errorFile()" [fileType]="'identityDocumentFront'"
                                    [username]="info.email" [allowedPdf]="false" fileText="Parte frontal del DNI" version="button"></fun-file-card>
                                </div>
                                <!--Para web-->
                                <div *ngIf="platform === 'web'">
                                    <fun-file-card  (getFile)="getFrontalFile($event)" (errorEvent)="errorFile()" [fileType]="'identityDocumentFront'"
                                    [username]="info.email" [allowedPdf]="false" fileText="Parte frontal del DNI"></fun-file-card>
                                </div>
                            </div>
                            <div class="w-full">
                                <img *ngIf="urlImageBack && !cameraEnable" [src]="urlImageBack" />
                                <!--Para app-->
                                <div *ngIf="platform !== 'web' && !cameraEnable && urlImageBack" class="flex gap-12 items-center mt-32">
                                    <img class="file" id="documentId" src="../../../../../assets/images/img/file.svg" alt="file" />
                                    <p class="filename font-sans text-center text-l text-eggplant-100/[0.4]"> Parte trasera </p>
                                    <img (click)="errorBackDoc()" class="delete" src="../../../../../assets/images/img/delete.svg" alt="x">
                                    <!--(click)="documentId.click()"-->
                                </div>

                                <div *ngIf="platform !== 'web' && !cameraEnable && !urlImageBack" class="rounded-xl border border-eggplant-100/[0.4] p-12 pt-[26px] mt-12">
                                    <fun-new-button (eventClick)="openCamera('back')" class="next-btn" buttonType="primaryLight" [title]="urlImageBack ? 'Repetir foto trasera' : 'Hacer foto trasera'"
                                    [classExtra]="' !w-full '" [size]="'xl'"></fun-new-button>
                                    <p class="font-sans text-xl text-eggplant-100 pt-[12px] text-center">Si lo prefieres</p>
                                    <fun-file-card  (getFile)="getBackFile($event)" [fileType]="'identityDocumentBack'" version="button"
                                    [username]="info.email" [allowedPdf]="false" (errorEvent)="errorBackDoc()" fileText="Parte trasera del DNI"></fun-file-card>
                                </div>
                                <!--Para web-->
                                <div *ngIf="platform === 'web'">
                                    <fun-file-card  (getFile)="getBackFile($event)" [fileType]="'identityDocumentBack'"
                                    [username]="info.email" [allowedPdf]="false" (errorEvent)="errorBackDoc()" fileText="Parte trasera del DNI"></fun-file-card>
                                </div>
                            </div>
                            <img src="../../../../../assets/icons/picture_btn.svg" class="btn_photo" *ngIf="cameraEnable" (click)="takePicture()">
                        </div>

                    </div>
                    <div *ngIf="stp.name === 'nie_file' && stp.index === step">
                        <fun-file-card (getFile)="getFrontalFile($event)" (errorEvent)="errorFile()" [fileType]="'identityDocumentFront'"
                        [username]="info.email"></fun-file-card>
                        <div [ngClass]="{'hasFile': hasFrontalDocument}"></div>
                        <fun-file-card *ngIf="hasFrontalDocument" (getFile)="getBackFile($event)" [fileType]="'identityDocumentBack'"
                        [username]="info.email"></fun-file-card>
                    </div>
                    <div *ngIf="stp.name === 'passport_file' && stp.index === step">
                        <fun-file-card (getFile)="getFrontalFile($event)" (errorEvent)="errorFile()" [fileType]="'identityDocumentFront'"
                        [username]="info.email"></fun-file-card>
                    </div>
                    <div *ngIf="stp.name === 'nie_number' && stp.index === step">
                        <fun-register-person-document-id
                            [data]="info"
                            [defaultSelected]="defaultNie"
                            [isMobile]="isMobile"
                            (sendInfoEvent)="getDniInfo($event)"
                            (sendErrorEvent)="getError()"
                        ></fun-register-person-document-id>
                    </div>
                    <div *ngIf="stp.name === 'dni_number' && stp.index === step">
                        <fun-register-person-document-id
                            [data]="info"
                            [defaultSelected]="defaultDni"
                            [isMobile]="isMobile"
                            (sendInfoEvent)="getDniInfo($event)"
                            (sendErrorEvent)="getError()"
                        ></fun-register-person-document-id>
                    </div>
                    <div *ngIf="stp.name === 'passport_number' && stp.index === step">
                        <fun-register-person-document-id
                            [data]="info"
                            [defaultSelected]="defaultPassport"
                            [isMobile]="isMobile"
                            (sendInfoEvent)="getDniInfo($event)"
                            (sendErrorEvent)="getError()"
                        ></fun-register-person-document-id>
                    </div>
                </div>
            </div>
        </div>
        <!--Code step-->
    </div>
    <div class="footer" *ngIf="!cameraEnable" >
        <fun-new-button size="xl" (eventClick)="next()" class="next-btn" [classExtra]="' !w-full '"
        [buttonType]="sendXHR" title="{{ 'register.btn.continue' | translate }}"  icon="arrow-right" [iconPosition]="'right'"></fun-new-button>
        <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
        (click)="finishRegister()" id="not_now">{{ 'register.btn.another_time' | translate }}</h4>
    </div>
</div>

