import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from './communication.service';
import { UtilsService } from './utils.service';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import * as momentNs from 'moment';
import { ToastService } from '../shared/components/toast/toast.service';
import { AlertService } from '../shared/components/alert/alert.service';
import { PasswordRecoveryComponent } from '../shared/components/password/password-recovery/password-recovery.component';
import { Profile } from '../shared/models/register.models';
import { OveralMessageComponent } from '../shared/components/overal-message/overal-message.component';
import { ModalService } from '../shared/components/modal/modal.service';
const moment = momentNs;

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private alertOpen = false;
  public profileCompleted;
  public profileIsNew;

  constructor(
    private modalService: ModalService,
    private communicationService: CommunicationService,
    private utilsService: UtilsService,
    private toastService: ToastService,
    private translate: TranslateService,
    private alertService: AlertService,
    private storage: Storage,
    private router: Router
    ) { }

  public getProfile(user: string) {
    const payload = {
      username: user.toLowerCase(),
    };
    return this.communicationService.post('apiservices/profile/v1/profiles/get', payload);
  }

  resetAcreditationFiles() {
    return this.communicationService.put('apiservices/profile/v1/profiles/resetAcreditationFiles', null);
  }

  generateProfile(model) {
    return this.communicationService.post( 'apiservices/profile/v1/profiles', model);
  }

  updateProfile(model) {
    return this.communicationService.put( 'apiservices/profile/v1/profiles', model);
  }

  updateUser(type) {
    return this.communicationService.put( 'apiservices/user/v1/users/updateprofiletype/' + type, null);
  }

  getAccreditUrl() {
    return this.communicationService.post('apiservices/profile/v1/profiles/acreditate', null, 'text');
  }

  getDocuments() {
    return this.communicationService.get( 'apiservices/profile/v1/profiles/documents/', null);
  }

  getInvestedSpecificDocument(id) {
    return this.communicationService.get( `apiservices/profile/v1/profiles/documents/invest/${id}/`, null, 'blob');
  }

  getDocumentsType(type) {
    return this.communicationService.get( `apiservices/profile/v1/profiles/documents/${type}/`, null);
  }

  getSpecificDocument(type, uuid) {
    return this.communicationService.get( `apiservices/profile/v1/profiles/documents/${type}/${uuid}/`, null, 'text');
  }

  getInvestDocuments(id) {
    return this.communicationService.get( `apiservices/profile/v1/profiles/documents/invest/${id}/`, null, 'text');
  }

  verifyPostalCode() {
    return this.communicationService.post('apiservices/profile/v1/profiles/postalcodeverificationtask', null);
  }

  getUserData() {
    return this.communicationService.get('apiservices/user/v1/users/', null);
  }

  getCookie(name) {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }

  register(
    user: string,
    pwd: string,
    acceptComm: boolean,
    source: string,
    utm: string,
    hubspot: string,
    type: any,
    promotionalCode: any
  ) {
    const payload = {
      username: user.toLowerCase(),
      password: pwd,
      acceptCommunications: acceptComm,
      source: source,
      utm: utm,
      hubspotutk: hubspot,
      profileType: type,
      activateByUrl: true,
      referredPromotionalCode: promotionalCode,
      baseUrl: window.location.hostname
    };
    return this.communicationService.post('apiservices/user/v1/users', payload);
  }

  uploadFile(file: any) {
    return this.communicationService.post('apiservices/userfile/v1/userfiles', file);
  }

  subscribeHubspot(eMail: any, ids: any, idP?: any) {
    let payload;
    payload = {
      email: eMail,
      subscriptions: ids,
    };
    if (idP && idP !== null && idP !== undefined) {
      payload['comunicaciones_sobre_proyectos'] = idP;
    }

    return this.communicationService.post('apiservices/user/v1/users/subscribe/hubspot', payload);
  }

  subscribeFullCommunications(email) {
    const ids = ['5239388', '6441045']; //['218645'] //['5239388', '5889597', '6441045'];
    const payload = {
      'email': email,
      'subscriptions': ids,
      'suscripcion_a_la_newsletter': true,
      'newsletter_web': true
    };
    return this.communicationService.post('apiservices/user/v1/users/subscribe/hubspot', payload);
  }

  getPhoneCode(phone) {
    return this.communicationService.post(`apiservices/user/v1/users/verify/phone/${phone}/`, null);
  }

  getPhoneVerification(phone, code) {
    return this.communicationService.put(`apiservices/user/v1/users/verify/phone/${phone}/${code}/`, null);
  }

  getMailCode(mail) {
    const params = { activateByUrl: true };
    return this.communicationService.post(`apiservices/user/v1/users/verify/mail/${mail}/`, params);
  }

  verifyMailByPin(pin) {
    return this.communicationService.put(`apiservices/user/v1/users/verify/bypin/${pin}/`, null);
  }

  verifyMailByToken(id, token) {
    return this.communicationService.put(`apiservices/user/v1/users/verify/bytoken/${id}/${token}`, null);
  }

  getMailVerification(code, mail) {
    return this.communicationService.put(`apiservices/user/v1/users/verify/mail/${mail}/${code}/`, null);
  }

  getCities(country, region) {
    return this.communicationService.get(`apiservices/utils/v1/utils/get/country/${country}/region/${region}/cities`, null);
  }

  getCountries() {
    return this.communicationService.get('apiservices/utils/v1/utils/get/countries', null);
  }

  getUserFile(uuid) {
    return this.communicationService.get(`apiservices/userfile/v1/userfiles/${uuid}/`, null);
  }

  changePassword(params) {
    return this.communicationService.put('apiservices/user/v1/users/password/change', params);
  }

  getRefCodeValidation(code) {
    return this.communicationService.get(`apiservices/user/v1/users/referredprofilename/${code}`, null);
  }

  getReferencesScore() {
    return this.communicationService.get('apiservices/user/v1/users/referencesscore', null);
  }

  getPromoCodeInfo() {
    return this.communicationService.get('apiservices/user/v1/users/referredpromocodeinfo', null);
  }

  deleteAccount() {
    return this.communicationService.put('apiservices/user/v1/users/deactivate', null);
  }

  public getText(id, lan) {
    return this.communicationService.get(`apiservices/utils/v1/utils/get/text/${id}/${lan}`, null);
  }

  rememberPwd(emailUser?: string) {
    const modalParams = { side: 'recover', email: emailUser};

    this.modalService.showModal(PasswordRecoveryComponent, modalParams, 'Contraseña').subscribe((modal: any) => {
      this.modalService.setPageTitle('Fundeen - Recuperar contraseña');
      modal.getChildComponent().subscribe((componentRef: PasswordRecoveryComponent) => {
        componentRef.changeLevel.subscribe(
          (resp) => {
            const payload = {
              username: resp.toLowerCase(),
              application: 'app',
            };
            this.communicationService.post('apiservices/user/v1/users/password/forgot', payload).subscribe(
              (x) => {
                componentRef.next();
              },
              (error) => {
                console.error(error);
              }
            );
          },
          (error) => {
            componentRef.prev();
          }
        );
        componentRef.goProjectsEvent.subscribe((x) => {
          this.modalService.removeModal();
        });
      });
      modal.onClose().subscribe();
    });
  }

  forgotChangePass(user, tkn) {
    const modalParams = { side: 'change' };
    this.modalService.showModal(PasswordRecoveryComponent, modalParams, 'Contraseña').subscribe((modal: any) => {
      this.modalService.setPageTitle('Fundeen - Recuperar contraseña');
      modal.getChildComponent().subscribe((componentRef: PasswordRecoveryComponent) => {
        componentRef.changeLevel.subscribe(
          (resp) => {
            const payload = {
              password: resp.pw1,
              internalId: user,
              token: tkn,
            };
            this.communicationService.post('apiservices/user/v1/users/password/reset', payload).subscribe(
              (x) => {
                componentRef.next();
              },
              (error) => {
                componentRef.prev();
              }
            );
          },
          (error) => {
            componentRef.prev();
          }
        );
        componentRef.goProjectsEvent.subscribe((x) => {
          this.modalService.removeModal();
        });
      });
      modal.onClose().subscribe();
    });
  }

  profileStepsToInvest(profile: Profile, projectType: number) {
    const params = {}
    const steps = [];
    let stepList = [];
    let hasToAccredit = false;
    let hasToAddNie;

    if (profile.profileType === 1) {
      // if (profile.mobile === null || profile.mobile === undefined || profile.mobile === '') {
      //   //steps.push('phoneUser', 'code');
      //   steps.push('phoneUser');
      // }
      if (profile.name === null || profile.name === undefined || profile.name === '') {
        steps.push('nameUser');
      }
      if (profile.nationality === null || profile.nationality === undefined) {
        steps.push('nationalityUser');
      }
      if ((profile.taxCity === null || profile.taxCity === undefined || profile.taxCity === '')
      && (profile.taxInternationalCity === null || profile.taxInternationalCity === undefined || profile.taxInternationalCity === '')) {
        steps.push('address');
      }
      if (
        (profile.identityDocumentDni === null || profile.identityDocumentDni === undefined || profile.identityDocumentDni === '')
        // && (profile.identityDocumentNie === null || profile.identityDocumentNie === undefined || profile.identityDocumentNie === '')
        && (profile.identityDocumentPassport === null || profile.identityDocumentPassport === undefined || profile.identityDocumentPassport === '')
        ) {
          steps.push('docTypeNumber');
      } else if (
        profile.identityDocumentPassport !== null
        && profile.identityDocumentDni === null
        && (profile.identityDocumentNie === null || profile.identityDocumentNie === undefined)
        && projectType === 1) {
          steps.push('docTypeNumber');
          this.toastService.showToast(this.translate.instant('MESSAGES.INCLUDE_NIE'), 'warning', true);
          hasToAddNie = true;
        }
      if (
        (profile.identityDocumentFront === null || profile.identityDocumentFront === '' || profile.identityDocumentFront === undefined)
        && (profile.identityDocumentFrontNie === null || profile.identityDocumentFrontNie === '' || profile.identityDocumentFrontNie === undefined)
        && (profile.identityDocumentFrontPassport === null || profile.identityDocumentFrontPassport === '' || profile.identityDocumentFrontPassport === undefined)
      ) {
        steps.push('documentFolder');
      } else if (profile.identityDocumentFrontPassport !== null && profile.identityDocumentFrontPassport !== '' && profile.identityDocumentFrontPassport !== undefined) {
        if ((profile.identityDocumentFrontNie === null || profile.identityDocumentFrontNie === '' || profile.identityDocumentFrontNie === undefined)
        && (profile.identityDocumentFront === null || profile.identityDocumentFront === '' || profile.identityDocumentFront === undefined)
        && projectType === 1) {
          if (hasToAddNie !== true) {
            this.toastService.showToast(this.translate.instant('MESSAGES.INCLUDE_NIE'), 'warning', true);
            hasToAddNie = true;
          }
          steps.push('documentFolder');
        }
      }
      if (profile.civilStatus === null || profile.civilStatus === undefined) {
        steps.push('civilStatus');
      }
      if (profile.mangopayWalletId === null && (profile.walletstatus === 0 || this.checkCreationDate(profile.creationDate))) {
        steps.push('mangopayCheckStep');
      }
      if (profile.walletstatus === 0) {
        steps.push('okInvestFinishMessage');
      } else if (profile.walletstatus !== 0 && !hasToAccredit) {
        steps.push('investFinishMessage');
      }
      if (steps.length > 1 || (steps.length > 0 && steps[0] !== 'investFinishMessage' && steps[0] !== 'okInvestFinishMessage')) {
        steps.unshift('investMessage');
      }
      /*if (!profile.mailVerified || profile.mailVerified === null || profile.mailVerified === undefined) {
        steps.push('emailCode');
      }*/


      for(let i = 0; i < steps.length; i++) {
        stepList.push({
          name: steps[i],
          index: i
        });
      }

      if (stepList[0].name === 'okInvestFinishMessage') {
        stepList = [];
      }
      params['stepList'] = stepList;
      if (hasToAddNie) {
        params['addNie'] = true;
      }
      return params;
    } else {
      // Para juridica
      if (!profile.mailVerified || this.utilsService.checkEmptyField(profile.mailVerified)) {
        steps.push('emailCode');
      }
      if (!profile.mobileVerified || this.utilsService.checkEmptyField(profile.mobile)) {
            //steps.push('phone');
            //steps.push('phoneCode');
          }
      if (this.utilsService.checkEmptyField(profile.name)) {
        steps.push('contactPersonData');
      }
      if (this.utilsService.checkEmptyField(profile.socialDenomination)) {
        steps.push('companyData');
      }
      if (this.utilsService.checkEmptyField(profile.taxAddress)) {
        steps.push('companyAddress');
      }
      if (this.utilsService.checkEmptyField(profile.constitutionDocument1)) {
        steps.push('constitutionDocs');
      }
      if (this.utilsService.checkEmptyField(profile.realOwnershipDocument)) {
        steps.push('realOwnershipDoc');
      }
      // if (this.utilsService.checkEmptyField(profile.enterpriseModel200) && (profile.creationDate !== null && (new Date(Number(profile.creationDate.split('/')[2]), Number(profile.creationDate.split('/')[1]) - 1, Number(profile.creationDate.split('/')[0])) > new Date(2021, 10, 15)))) {
      //   steps.push('model200Doc');
      // }
      if (this.utilsService.checkEmptyField(profile.identityDocumentPartner1)) {
        steps.push('identityPartnerDocs');
      }
      if (this.utilsService.checkEmptyField(profile.nameRepresentative)) {
        steps.push('RLPersonalData');
      }
      if (this.utilsService.checkEmptyField(profile.addressRepresentative === undefined)
      && this.utilsService.checkEmptyField(profile.postalCodeRepresentative === undefined)) {
        steps.push('RLPersonalAddress')
      }
      if (this.utilsService.checkEmptyField(profile.nationalityRepresentative)) {
        steps.push('RLNationality');
      }
      if ((this.utilsService.checkEmptyField(profile.identityDocumentDniRepresentative)
              && this.utilsService.checkEmptyField(profile.identityDocumentNieRepresentative)
              && this.utilsService.checkEmptyField(profile.identityDocumentPassportRepresentative))
            || (this.utilsService.checkEmptyField(profile.identityDocumentFrontRepresentative)
              && this.utilsService.checkEmptyField(profile.identityDocumentFrontNieRepresentative)
              && this.utilsService.checkEmptyField(profile.identityDocumentFrontPassportRepresentative))) {
          steps.push('RLDocumentType');
          steps.push('RLIdentityDoc');
        }
      // if (this.utilsService.checkEmptyField(profile.addressVerifyRepresentative)) {
      //   steps.push('RLAddressDoc');
      // }
      if (profile.mangopayWalletId === null && (profile.walletstatus === 0 || this.checkCreationDate(profile.creationDate))) {
        steps.push('mangopayCheckStep');
      }
      if (profile.walletstatus === 0) {
        steps.push('okInvestFinishMessage');
      } else if (profile.walletstatus !== 0 && !hasToAccredit) {
        steps.push('investFinishMessage');
      }
      if (steps.length > 1 || (steps.length > 0 && steps[0] !== 'investFinishMessage' && steps[0] !== 'okInvestFinishMessage')) {
        steps.unshift('investMessage');
      }

      for(let i = 0; i < steps.length; i++) {
        stepList.push({
          name: steps[i],
          index: i
        });
      }

      if (stepList[0].name === 'okInvestFinishMessage') {
        stepList = [];
      }
      params['stepList'] = stepList;
      return params;
    }
  }

  checkStepsRequired(prof: Profile) {
    if (prof.profileType === 1) {
      if ((prof.mangopayWalletId === null && prof.walletstatus === 0)
        || (prof.acceptPaymentEntity === null || prof.acceptPaymentEntity === undefined || prof.acceptPaymentEntity === false)
        || ((prof.identityDocumentFront === null || prof.identityDocumentFront === '' || prof.identityDocumentFront === undefined)
        && (prof.identityDocumentFrontNie === null || prof.identityDocumentFrontNie === '' || prof.identityDocumentFrontNie === undefined)
        && (prof.identityDocumentFrontPassport === null || prof.identityDocumentFrontPassport === '' || prof.identityDocumentFrontPassport === undefined))
        || ((!prof.identityDocumentDni || prof.identityDocumentDni === null || prof.identityDocumentDni === undefined || prof.identityDocumentDni === '')
        && (!prof.identityDocumentNie || prof.identityDocumentNie === null || prof.identityDocumentNie === undefined || prof.identityDocumentNie === '')
        && (!prof.identityDocumentPassport || prof.identityDocumentPassport === null || prof.identityDocumentPassport === undefined || prof.identityDocumentPassport === ''))
        || (prof.nationality === null || prof.nationality === undefined )) {
          return false;
        } else {
          return true;
        }
    } else {
      if ((prof.mangopayWalletId === null && prof.walletstatus === 0)
          || (this.utilsService.checkEmptyField(prof.nameRepresentative)
          || this.utilsService.checkEmptyField(prof.nationalityRepresentative)
          || (this.utilsService.checkEmptyField(prof.identityDocumentDniRepresentative)
              && this.utilsService.checkEmptyField(prof.identityDocumentNieRepresentative)
              && this.utilsService.checkEmptyField(prof.identityDocumentPassportRepresentative))
          //|| this.utilsService.checkEmptyField(prof.addressVerifyRepresentative)
          )
      //|| this.utilsService.checkEmptyField(prof.addressVerifyRepresentative)
      || ((this.utilsService.checkEmptyField(prof.identityDocumentDniRepresentative)
              && this.utilsService.checkEmptyField(prof.identityDocumentNieRepresentative)
              && this.utilsService.checkEmptyField(prof.identityDocumentPassportRepresentative))
          || (this.utilsService.checkEmptyField(prof.identityDocumentFrontRepresentative)
              && this.utilsService.checkEmptyField(prof.identityDocumentFrontNieRepresentative)
              && this.utilsService.checkEmptyField(prof.identityDocumentFrontPassportRepresentative)))
      || this.utilsService.checkEmptyField(prof.nationalityRepresentative)
      || this.utilsService.checkEmptyField(prof.nameRepresentative)
      || (this.utilsService.checkEmptyField(prof.nameRepresentative)
          || this.utilsService.checkEmptyField(prof.nationalityRepresentative)
          || (this.utilsService.checkEmptyField(prof.identityDocumentDniRepresentative)
              && this.utilsService.checkEmptyField(prof.identityDocumentNieRepresentative)
              && this.utilsService.checkEmptyField(prof.identityDocumentPassportRepresentative))
          //|| this.utilsService.checkEmptyField(prof.addressVerifyRepresentative)
          )
      || this.utilsService.checkEmptyField(prof.identityDocumentPartner1)
      || this.utilsService.checkEmptyField(prof.realOwnershipDocument)
      || this.utilsService.checkEmptyField(prof.constitutionDocument1)
      || this.utilsService.checkEmptyField(prof.taxAddress)
      || this.utilsService.checkEmptyField(prof.socialDenomination)) {
        return false;
      } else {
        return true;
      }
    }
  }

  verificationMailAdvice() {
    this.alertOpen = true;
    const modalParams = {
      text: this.translate.instant('REGISTER.MY_PROFILE.VERIFY_MAIL'),
      image: 4,
      btnText: this.translate.instant('REGISTER.MY_PROFILE.VERIFY_MAIL_3'),
    };

    this.alertService.showModal(OveralMessageComponent, modalParams, '', '', true).subscribe((modal: any) => {
      modal.getChildComponent().subscribe((componentRef: OveralMessageComponent) => {
        componentRef.btnTxtEvent.subscribe(() => {
          this.storage.get('AUTHDATA').then((val) => {
            this.getMailCode(val.username).subscribe(() => {
              this.alertOpen = false;
              this.toastService.showToast(this.translate.instant('REGISTER.MY_PROFILE.VERIFY_MAIL_2'), 'info', true);
            });
            this.alertService.removeModal();
          });
        });
        this.checkMailVerified();
      });
      modal.onClose().subscribe();
    });
  }

  checkMailVerified() {
    this.getUserData().subscribe(user => {
      if (user.enabled) {
        this.alertService.removeModal();
        this.reloadPage();
        this.storage.get('USERDATA').then((val) => {
          const model = val;
          model.mailVerified = true;
          this.storage.set('USERDATA', model);
          this.alertOpen = false;
        });
      } else {
        this.timer();
      }
    });
  }

  timer() {
    if (this.alertOpen) {
      setTimeout(() => {this.checkMailVerified()}, 5000);
    }
  }

  reloadPage() {
    window.location.reload();
  }

  setProfileCompleted(completed) {
    this.profileCompleted = completed;
  }

  setProfileIsNew(isnew) {
    this.profileIsNew = isnew;
  }

  checkCreationDate(date) {
    const dt = `${date.substring(6, 10)}-${date.substring(3, 5)}-${date.substring(0, 2)}`;
    const isBefore = moment('2022-06-28').diff(dt);
    if (isBefore > 0) {
      return true;
    }
    return false;
  }
}