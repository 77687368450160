import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoundNumberService {

  constructor() { }

  roundNumber(num: number) {
    if (num > 100) {
      return Math.round(num);
    } else {
      return num;
    }
  }
}
