import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavController } from '@ionic/angular';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { NavigationExtras } from '@angular/router';
import * as momentNs from 'moment';
import * as XLSX from 'xlsx-with-styles';
import write_blob from 'capacitor-blob-writer';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { ToastService } from '../shared/components/toast/toast.service';
import { ViewerComponent } from '../shared/components/viewer/viewer.component';
import { ModalService } from '../shared/components/modal/modal.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(
    private translate: TranslateService,
    private navCtrl: NavController,
    private localize: LocalizeRouterService,
    private modalService: ModalService,
    private toastService: ToastService) {}

  GetTimeNow(): number {
    return Math.floor(Date.now() / 1000);
  }

  isExpiredTOKEN(expiration: number): boolean {
    if (expiration === -1) {
      return true;
    }

    const now = this.GetTimeNow();
    return expiration < now;
  }

  getExpirationDate(token: string): number {
    if (typeof token !== 'undefined') {
      const splitToken: string[] = token.split('.');
      if (splitToken.length === 3) {
        const decodedstring: string = atob(splitToken[1]);
        const deserializedJSON: any = JSON.parse(decodedstring);
        return Number.parseInt(deserializedJSON.exp);
      }
      return -1;
    } else {
      return -1;
    }
  }

  navigateTo(route: string, options?: NavigationExtras) {
    const translatedRoute = this.localize.translateRoute(route);
    if (options) {
      if (!options.hasOwnProperty('animated')) {
        Object.defineProperty(options, 'animated', {
          value: true,
          writable: true,
          configurable: true,
          enumerable: true,
        });
      }
      this.navCtrl.navigateRoot(translatedRoute, options);
    } else {
      this.navCtrl.navigateRoot(translatedRoute);
    }
  }

  detectOS() {
    const platform = navigator.platform.toLowerCase(), iosPlatforms = ['iphone', 'ipad', 'ipod', 'ipod touch'];
    if (platform.includes('mac')) return 'MacOS';
    if (iosPlatforms.includes(platform)) return 'iOS';
    if (platform.includes('win')) return 'Windows';
    if (/android/.test(navigator.userAgent.toLowerCase())) return 'Android';
    if (/linux/.test(platform)) return 'Linux';
    return 'unknown';
  }

  checkBrowser() {
    let browser = "";
    let c = navigator.userAgent.search("Chrome");
    let f = navigator.userAgent.search("Firefox");
    let m8 = navigator.userAgent.search("MSIE 8.0");
    let m9 = navigator.userAgent.search("MSIE 9.0");
    if (c > -1) {
        browser = "Chrome";
    } else if (f > -1) {
        browser = "Firefox";
    } else if (m9 > -1) {
        browser ="MSIE 9.0";
    } else if (m8 > -1) {
        browser ="MSIE 8.0";
    }
    return browser;
  }

  viewdFile(doc: any, force?: boolean) {
    const platform = this.detectOS();
    if (platform === 'iOS' || platform === 'Android' || force) {
      const modalParams = {
        file: doc.url,
        imageList: doc.imageList
      };
      this.modalService.showModal(ViewerComponent, modalParams, doc.name).subscribe(
        (modal: any) => {
          modal.getChildComponent().subscribe((componentRef: ViewerComponent) => {
            componentRef.errorEvent.subscribe(() => {
              this.modalService.removeModal();
              window.location.href = doc.url;
              this.toastService.showToast(this.translate.instant('MESSAGES.FORMAT_DOCUMENT'), 'check', true);
            });
          });
          modal.onClose().subscribe();
        }
      );
    } else {
      window.open(doc.url);
    }
  }

  segmentUsersByIdAndDate() {
    const internalId = localStorage.getItem('internalId');
    const lastChar = internalId.substr(internalId.length - 1);
    const lastNumbId = parseInt(lastChar);
    const isNumber = !isNaN(lastNumbId);
    if (isNumber) {
      const dayN = momentNs().day();
      if ((dayN%2 === 0 && lastNumbId%2 === 0) || (dayN%2 !== 0 && lastNumbId%2 !== 0)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isSafariBrowser() {
    const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;
    return isSafari;
  }

  checkEmptyField(field: any): boolean {
    if (field === null || field === undefined || field === '') {
      return true;
    } else {
      return false;
    }
  }

  b64toBlob(b64Data, contentType, sliceSize?) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  convertToIos(date) {
    const arr = date.split(/[- :]/);
    const r = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    return r;
  }

  parseMonth(value) {
    const lan = this.translate.currentLang;
    let month;
    switch (value) {
      case 1:
        month = lan === 'en' ? 'January' : 'enero';
        break;
      case 2:
        month = lan === 'en' ? 'February' : 'febrero';
        break;
      case 3:
        month = lan === 'en' ? 'March' : 'marzo';
        break;
      case 4:
        month = lan === 'en' ? 'April' : 'abril';
        break;
      case 5:
        month = lan === 'en' ? 'May' : 'mayo';
        break;
      case 6:
        month = lan === 'en' ? 'June' : 'junio';
        break;
      case 7:
        month = lan === 'en' ? 'July' : 'julio';
        break;
      case 8:
        month = lan === 'en' ? 'August' : 'agosto';
        break;
      case 9:
        month = lan === 'en' ? 'September' : 'septiembre';
        break;
      case 10:
        month = lan === 'en' ? 'October' : 'octubre';
        break;
      case 11:
        month = lan === 'en' ? 'November' : 'noviembre';
        break;
      case 12:
        month = lan === 'en' ? 'December' : 'diciembre';
        break;
    }
    return month;
  }

  parseMonthShort(value) {
    const lan = this.translate.currentLang;
    let month;
    switch (value) {
      case 1:
        month = lan === 'en' ? 'Jan' : 'ene';
        break;
      case 2:
        month = lan === 'en' ? 'Feb' : 'feb';
        break;
      case 3:
        month = lan === 'en' ? 'Mar' : 'mar';
        break;
      case 4:
        month = lan === 'en' ? 'Apr' : 'abr';
        break;
      case 5:
        month = lan === 'en' ? 'May' : 'may';
        break;
      case 6:
        month = lan === 'en' ? 'June' : 'jun';
        break;
      case 7:
        month = lan === 'en' ? 'July' : 'jul';
        break;
      case 8:
        month = lan === 'en' ? 'Aug' : 'ago';
        break;
      case 9:
        month = lan === 'en' ? 'Sep' : 'sep';
        break;
      case 10:
        month = lan === 'en' ? 'Oct' : 'oct';
        break;
      case 11:
        month = lan === 'en' ? 'Nov' : 'nov';
        break;
      case 12:
        month = lan === 'en' ? 'Dec' : 'dic';
        break;
    }
    return month;
  }

  generateXlsxFromArrayObj(params) {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(params.list);
    const colWidth = [];
    const numberSymbol = params.numberSymbol || '';
    const colorPositiveNumbers = params.colorPositiveNumbers || '111111';
    const borderStyle = {
      top: { style: 'thin', color: {rgb: '111111'} },
      bottom: { style: 'thin', color: {rgb: '111111'} },
      left: { style: 'thin', color: {rgb: '111111'} },
      right: { style: 'thin', color: {rgb: '111111'} }
    };

    Object.keys(ws).map((key) => {
      if (ws[key].v !== null && ws[key].v !== undefined) {
        ws[key].s = { border: borderStyle };
        if (typeof ws[key].v === 'number') {
          ws[key].z = ws[key].v % 1 === 0 ?  `#,##0\"${numberSymbol}\"\ ` : `#,##0.00\"${numberSymbol}\"\ `;
          if (ws[key].v > 0) {
            ws[key].s = {border: borderStyle, font: {color: {rgb: colorPositiveNumbers}}};
          }
        }
      }
    });

    for (let i = 0; i < params.titleProp.length; i++) {
      colWidth.push({ width: params.titleProp[i].width || 30 });
      ws[XLSX.utils.encode_cell({c: i, r: 0})] = {v: params.titleProp[i].title, s: { border: borderStyle, fill: {fgColor: { rgb: 'E54937' }}, font : {sz : '12', bold : true}}};
    }

    ws['!cols'] = colWidth;
    ws['!autofilter']={ref:"E1:E1"};
    XLSX.utils.book_append_sheet(wb, ws, params.title);
    const pathFile = `${params.title}.xlsx`;

    if (params.platform !== 'web') {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const excelBuffer: any = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
      const excelData: Blob = new Blob([excelBuffer], {type: EXCEL_TYPE});
      const dirPlatform = params.platform === 'ios' ? Directory.Documents : Directory.Cache;
      write_blob({
        path: pathFile,
        directory: dirPlatform,
        blob: excelData
      }).then((x) => {
        if (params.platform === 'ios') {
          Share.share({
            title: params.title,
            url: x,
          }).then(resShare => {
            this.toastService.showToast(this.translate.instant('MESSAGES.DOCUMENT_DOWNLOAD_OK'), 'success', true);
          });
        } else {
          let stat = Filesystem.stat({
            path: pathFile,
            directory: Directory.Cache
          });
          stat.then(val => {
            // console.log(val)
          });
          this.toastService.showToast(this.translate.instant('MESSAGES.DOCUMENT_DOWNLOAD_OK'), 'success', true);
        }
      }).catch((e) => {
        console.log(e);
      });
    } else {
      XLSX.writeFile(wb, pathFile);
    }
  }

  isBetweenDates(date1: string, date2: string): boolean { // Formato mes dia año
    const moment = momentNs;
    const now = moment();
    const d1 = moment(date1);
    const d2 = moment(date2);
    return now.isBetween(d1, d2);
  }

  async addFinanceAdsScript() {
    if (environment.production) {
      const script = document.createElement('script');
      script.innerHTML = `
        var faOrderID = "OOOO";
        var faProgramID = "3234";
        var faCategory = "lead";
        var faValue = "0";
      `;
      document.body.appendChild(script);

      const fpcScript = document.createElement('script');
      fpcScript.src = 'https://fat.financeads.net/fpc.js';
      document.body.appendChild(fpcScript);
    }
  }

}
