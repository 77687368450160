<div class=" pb-32 pt-[28px] md:pt-[78px] md:pb-80">
  <div class="flex title-header place-content-center">
    <img class="md:hidden mr-12" src="../../../../assets/images/new_branding/isotipo_black.svg" alt="isotipo">
    <h2 class="font-sansMedium text-eggplant-100 text-h500 md:text-h800 text-center title flex items-center justify-center">{{ title }}</h2>
  </div>

  <div class="flex md:hidden pt-[28px] py-[12px] w-full place-content-center" *ngIf="hasBadge">
    <div class="w-full block m-auto">
      <fun-badge-group *ngIf="hasBadge" [props]="badgeProps"></fun-badge-group>
    </div>
  </div>
</div>