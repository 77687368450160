<div class="uploadFile">

    <input
    name="file"
    parentForm="fileForm"
    id="documentId"
    controlName="file"
    #documentId type="file"
    (change)="selectFile($event)"
    style="display: block" />
    <div *ngIf="!this.fileId && !isUploadingFile && version === 'card'" class="icon_image" (click)="documentId.click()" style="cursor: pointer">
      <img  id="documentId" src="../../../../assets/images/img/upload-file.svg" alt=""
        style="cursor: pointer" />
        <!--<h3>{{ uploadTxt }}</h3>-->
        <p class="allowed font-sans text-center text-l text-eggplant-100/[0.4]">{{ uploadTxt }}</p>
        <div class="btn_content pt-12">
          <fun-new-button [buttonType]="'primaryDark'" [size]="'xl'" title="Subir archivo"
          [classExtra]="' !w-full '" iconPosition="right" icon="upload"></fun-new-button>
        </div>
    </div>
    <div *ngIf="!this.fileId && !isUploadingFile && version !== 'card'" (click)="documentId.click()">
      <fun-new-button [buttonType]="'primaryDark'" [size]="'xl'" title="Subir archivo"
          [classExtra]="' !w-full '" iconPosition="right" icon="upload"></fun-new-button>
    </div>
    <div class="loader-container" *ngIf="isUploadingFile">
        <ion-spinner *ngIf="isUploadingFile" name="lines"></ion-spinner>
    </div>

    <div *ngIf="this.fileId && !isUploadingFile" class="file-success">
        <img class="file" id="documentId" src="../../../../assets/images/img/file.svg" alt="file" (click)="documentId.click()"
        style="cursor: pointer" />
        <p class="filename font-sans text-center text-l text-eggplant-100/[0.4]"> {{ fileName }} </p>
        <img (click)="resetStatusFile(idDocument)" class="delete" src="../../../../assets/images/img/delete.svg" alt="x">
        <!--(click)="documentId.click()"-->
    </div>

  </div>
