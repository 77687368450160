export const environment = {
  production: false,
  configFilePath: 'assets/configuration/configuration.test.json',
  env: 'test',
  endPointLW: 'https://sandbox-webkit.lemonway.fr/Fundeen/dev?moneyInToken=',
  endPointHubspot: 'https://test.fundeen.com/api/HubSpotInvest',
  activateInvest: true,
  activateMarketplace: true,
  enableTagManager: false,
  enableDatalayer: false,
  enableAutomaticInvest: false,
  enableCommunications: false,
  rollbarToken: false,
  origin: 'https://app.test.fundeen.com',
  amplitudeToken: 'fa2bd22db5953fd1acab4049bb4b681b',
  askForCommunications: false,
  endPointN8NReserve: 'https://n8n.fundeen.com/webhook-test/6f7b288e-1efe-4504-a6fd-660931327269',
  enableRicenergyPortfolio: false
};
