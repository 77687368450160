import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'fun-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {

  @Input() title: any;
  @Input() subtitle: any;
  @Input() projectList: any;
  @Input() type: any;
  @Input('featured') featured = false;
  @Input('cardType') cardType = 'generic';
  @Input() isFlexibleCompensation = false;
  @Output() private eventClick = new EventEmitter<void>();

  @ViewChild('currentSlider') currentSlider: IonSlides;

  index = 0;
  hasPager: boolean;
  group: number;

  slideOpts: any;
  isEnd = false;

  constructor() { }

  ngOnInit() {
    this.hasPager = this.projectList.length > 1 ? true : false;
    this.group = this.projectList.length > 6 ? 3 : 1;
    this.slideOpts = {
      initialSlide: 0,
      speed: 1000,
      effect: 'slide',
      slidesPerView: 3,
      slidesPerGroup: this.group,
      spaceBetween: 16,
      grabCursor: false,
      slideToClickedSlide: true,
      paginationClickable: true,
      freeMode: this.hasPager,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      breakpoints: {
        '774': {
          slidesPerView: 'auto'
        },
        '995': {
          slidesPerView: 2
        },
      }
    }
    this.currentSlider.isEnd().then(val => {
      this.isEnd = val;
    });
  }

  getCurrentItem() {
    this.currentSlider.getActiveIndex().then(val => {
      this.index = val;
    });
    this.currentSlider.isEnd().then(val => {
      this.isEnd = val;
    });
  }

  openNewProject(project) {
    this.eventClick.emit(project);
  }

  next() {
    this.currentSlider.slideNext();
  }

  prev() {
    this.currentSlider.slidePrev();
  }

}
