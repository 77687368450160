import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'fun-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit {

  @Input() file: any;
  @Input() imageList: any;
  @Input() zoom: any;
  @Input() maxHeight: any;
  @Output() public errorEvent = new EventEmitter<any>();
  @Output() public pageChangeEvent = new EventEmitter<any>();
  @Output() public pageNumberEvent = new EventEmitter<any>();

  loading: boolean;
  zoomNumber;

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.file !== undefined) {
      this.loading = true;
    }
    this.zoomNumber = this.zoom !== null && this.zoom !== undefined ? this.zoom : 1.7;
    this.pageChangeEvent.emit({pageNumber: 1});
    this.chdRef.detectChanges();
  }

  callBackFn(event) {
    this.loading = false;
    this.chdRef.detectChanges();
  }

  onError(event) {
    this.errorEvent.emit();
    this.chdRef.detectChanges();
  }

  pagechanging(event) {
    this.pageChangeEvent.emit(event);
    this.chdRef.detectChanges();
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pageNumberEvent.emit(pdf.numPages);
    this.chdRef.detectChanges();
  }

}
