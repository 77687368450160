<div *ngFor="let item of faqus">
  <div id={{item.index}} >
    <div [class]="contentClass">
        <div>
            <div (click)="toggleItem(item)" [class]="textStyle">
                <p>{{item.title}}</p>
                <div class="min-w-58">
                  <fun-icon *ngIf="item.opened" [name]="'minus-circle'" [style]="iconStyle"></fun-icon>
                  <fun-icon *ngIf="!item.opened" [name]="'plus-circle'" [style]="iconStyle"></fun-icon>
                </div>
            </div>
            <div [id]="'container-' + item.index">
              <div class="pb-32">
                <div class="pb-20 pl-4 pr-40 md:pr-64">
                  <p class="font-sans text-m md:text-l text-eggplant-100/[0.55]">{{ item.subtitle }}</p>
                </div>
              </div>
            </div>
        </div>
    </div>
  </div>
</div>