import { EventEmitter, Injectable, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as momentNs from 'moment-timezone';
import { CommunicationService } from './communication.service';
import { Storage } from '@ionic/storage';
import { ProfileService } from './profile.service';
import { DataLayerService } from './data-layer.service';
import { ListOfProjectsService } from './list-of-projects.service';
import { NavigationExtras } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { UtilsService } from './utils.service';
import { NavController } from '@ionic/angular';
import { ActivationWalletMessageComponent } from '../shared/components/activation-wallet-message/activation-wallet-message.component';
import { ModalService } from '../shared/components/modal/modal.service';
const moment = momentNs;

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  @Output() public readNotificationEvent = new EventEmitter<any>();
  @Output() public deleteNotificationEvent = new EventEmitter<any>();
  @Output() public emitNotificationEvent = new EventEmitter<any>();
  @Output() public setNotificationListEvent = new EventEmitter<any>();

  notificationList: [];

  constructor(
    private communicationService: CommunicationService,
    private translate: TranslateService,
    private storage: Storage,
    private profileService: ProfileService,
    private dataLayerService: DataLayerService,
    private modalService: ModalService,
    private projectsService: ListOfProjectsService,
    private localize: LocalizeRouterService,
    public utilsService: UtilsService,
    private navCtrl: NavController
    ) { }

  setTimeRemaining(notifications) {
    this.sortByDate(notifications);
    notifications.forEach(element => {
      let endDateN = new Date(this.convertToIos(moment(element.date).format('YYYY-MM-DD  HH:mm:ss'))).getTime();
      const todayN = new Date(this.convertToIos(moment().format('YYYY-MM-DD  00:00:00'))).getTime();
      if (isNaN(endDateN) || endDateN === undefined || !endDateN) {
        endDateN = new Date(
          this.convertToIos(moment(element.date.replace('/', '-').replace('/', '-')).format('YYYY-MM-DD  HH:mm:ss'))
        ).getTime();
      }
      const timeR = (todayN - endDateN) / (1000 * 60 * 60 * 24);
      let timeRemaining;
      if (timeR <= 0) {
        timeRemaining = this.translate.instant('NOTIFICATIONS.TODAY');
      } else if (timeR < 1) {
        timeRemaining = this.translate.instant('NOTIFICATIONS.ONE_DAY_AGO');
      } else {
        const parseData = timeR.toString().split(".");
        if (Number(parseData[0]) < 31) {
          timeRemaining = this.translate.instant('NOTIFICATIONS.MORE_THAN_DAY_AGO', { days: parseData[0] });
        } else if (Number(parseData[0]) < 365) {
          const month = Math.trunc(Number(parseData[0]) / 30);
          if (month === 1) {
            timeRemaining = this.translate.instant('NOTIFICATIONS.MONTH_AGO');
          } else {
            timeRemaining = this.translate.instant('NOTIFICATIONS.MORE_THAN_MONTH_AGO', { months: month });
          }
        } else {
          const year = Math.trunc(Number(parseData[0]) / 365);
          if (year === 1) {
            timeRemaining = this.translate.instant('NOTIFICATIONS.YEAR_AGO');
          } else {
            timeRemaining = this.translate.instant('NOTIFICATIONS.MORE_THAN_YEAR_AGO', {
              years: year,
            });
          }
        }
      }
      element['timeRemaining'] = timeRemaining;
    });
    return notifications;
  }

  convertToIos(date) {
    const arr = date.split(/[- :]/);
    const r = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    return r;
  }

  sortByDate(arr) {
    arr.sort(function (a, b) {
      return Number(new Date(b.date)) - Number(new Date(a.date));
    });
    return arr;
  }

  getNotificationList(readAgain?: boolean) {
    this.storage.get('WALLET').then((val) => {
      if (val !== null && val !== undefined && val.walletstatus === 0) {
        if (this.notificationList === null || this.notificationList === undefined || readAgain) {
          this.getNotifications();
        } else {
          this.setNotificationListEvent.emit(this.notificationList);
        }
      } else {
        this.getNotifications();
      }
    });
  }

  getNotifications() {
    this.communicationService.get('apiservices/profile/v1/notifications', null).subscribe(resp => {
      this.notificationList = resp;
      this.setNotificationListEvent.emit(resp);
      resp.forEach(element => {
        if (element.type === 'WALLET_VALIDATION_OK' && element.status !== 'VIEWED') {
          // TODO: Marca como leída
          this.readNotification(element.id, element.category, element.newNotification);
          // Mandamos evento a GTM
          this.storage.get('USERNAME').then((val) => {
            this.profileService.getProfile(val).subscribe((resp) => {
              if (resp && resp.profileType === 1) {
                this.dataLayerService.pushCustomEventGTM('pf_full_profile');
              } else if (resp && resp.profileType === 2) {
                this.dataLayerService.pushCustomEventGTM('pj_full_profile');
              }
            });
          });
          const modalParams = {};
          // Abre la modal
          this.modalService.showModal(ActivationWalletMessageComponent, modalParams, this.translate.instant('NOTIFICATIONS.ACTIVATED_WALLET')).subscribe(
            (modal: any) => {
              this.modalService.setPageTitle('Fundeen - Notificaciones');
              modal.getChildComponent().subscribe((componentRef: ActivationWalletMessageComponent) => {
                let project: any;
                this.projectsService.getProjectsList().subscribe(
                  (projects) => {
                    // Busca proyectos en financiación
                    if (project === undefined) {
                      project = projects.find(element => (element.status === 4 || element.status === 12));
                    }
                    // Busca proyectos futuros
                    if (project === undefined) {
                      project = projects.find(element => (element.status === 2 || element.status === 3));
                    }
                    // Busca proyectos financiados
                    if (project === undefined) {
                      project = projects.find(element => (element.status > 4 && element.status < 12));
                    }
                    if (project) {
                      componentRef.loadedPage(project);
                    }
                  }
                );
                componentRef.clickedProjectEvent.subscribe(
                  (resp) => {
                    if (project.status > 2) {
                      this.clickProjectDataLayer(resp.event, resp.project);

                      //const param = resp.project.slug && resp.project.slug !== undefined ? resp.project.slug : resp.project.id;
                      const navigationExtras: NavigationExtras = {
                        state: {
                          id: resp.event,
                          page: 'overview',
                          position: resp.project.position,
                          status: resp.project.status,
                          slug: resp.project.slug
                        },
                      };
                      this.storage.set('LASTPAGE', navigationExtras);
                      this.navCtrl.navigateRoot([this.localize.translateRoute(`/list-of-projects`)], navigationExtras);
                      modal.close();
                    }
                  }
                );
                componentRef.transferEvent.subscribe(
                  (resp) => {
                    this.navigateTo('movements');
                    modal.close();
                  }
                );
              })
            }
          );
          this.renewProfile();
        }
      });
    });
  }

  deleteSpecificNotification(id, category, isNew) {
    return this.communicationService.put(`apiservices/profile/v1/notifications/update_status/deleted/${category}/${isNew}/${id}`, null);
  }

  deleteNotifications() {
    this.communicationService.put('apiservices/profile/v1/notifications/update_status/deleted', null).subscribe(() => {
      this.getNotificationList(true);
      this.deleteNotificationEvent.emit();
    });
  }

  readNotification(id, category, isNew) {
    this.communicationService.put(`apiservices/profile/v1/notifications/update_status/viewed/${category}/${isNew}/${id}`, null).subscribe(() => {
      this.getNotificationList(true);
      this.readNotificationEvent.emit(id);
    });
  }

  emitNotificationList(list) {
    this.emitNotificationEvent.emit(list);
  }

  renewProfile() {
    this.storage.get('USERNAME').then(val => {
      this.profileService.getProfile(val).subscribe(
        (response) => {
          this.storage.set('PROFILE', response);
          let documentType: number;
          if (response.identityDocumentDni !== null && response.identityDocumentDni !== undefined) {
            documentType = 1;
          } else if (response.identityDocumentNie !== null && response.identityDocumentNie !== undefined) {
            documentType = 2;
          } else if (response.identityDocumentPassport !== null && response.identityDocumentPassport !== undefined) {
            documentType = 3;
          }
          const wallet = {
            walletstatus: response.walletstatus,
            docType: documentType,
            refCode: response.referralCode,
          };
          this.storage.set('WALLET', wallet);
        }
      );
    });
  }

  clickProjectDataLayer(id, project) {
    const obj = {
      item_name: project.name,
      item_id: project.id.toString(),
      item_brand: project.company,
      item_variant: 'Energía Solar',
      item_list_name: project.status,
      location_id: 'ES',
      item_category: project.globalProfitability ? project.globalProfitability.toString() : project.profitability.toString(),
      item_category2: project.total.toString(),
      item_category3: project.term.toString(),
      index: project.position,
      quantity: 1,
      price: 500
    };

    const objDL = {
      name: 'select_item',
      params: obj
    };
    this.dataLayerService.pushOnDataLayer(objDL);
  }

  navigateTo(path) {
    this.utilsService.navigateTo('/' + path);
  }

  filterNotifications(notifications) {
    const notificationsCustom = notifications;
    notificationsCustom.forEach(element => {
      const msg = element.messages;
      const projectName = element.projectName !== null ? `${element.projectName}: ` : '';
      let notification = msg.filter((item) => item.language === this.translate.currentLang);
      const spanishMsg = msg.filter((item) => item.language === 'es');
      if (notification.length === 0 && spanishMsg.length > 0) {
        notification = spanishMsg;
      }
      element['title'] = projectName + notification[0].title;
      element['text'] = notification[0].text;
      element['language'] = this.translate.currentLang;
    });
    return notificationsCustom;
  }

  resetList() {
    this.notificationList = null;
  }
}
