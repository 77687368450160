import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-overview-sections',
  templateUrl: './overview-sections.component.html',
  styleUrls: ['./overview-sections.component.scss'],
})
export class OverviewSectionsComponent implements OnInit {
  @Input() section: any;
  @Input() sectionInfo: any;
  @Output() private eventClickViewProject = new EventEmitter<any>();
  color: string;
  avatarLetters: string;
  slideOpts = {
    initialSlide: 0,
    speed: 1000,
    width: 275,
    effect: 'slide', // slide, fade, cube, coverflow, flip
    spaceBetween: 16,
    slidesPerView: 1,
    grabCursor: true,
    //slideToClickedSlide: true,
    freeMode: true,
  };
  cardImage = '../../../assets/images/img/project-default.webp';
  otherProjects: any;
  isOpen: boolean;
  hasViewMore: any;
  url: any;

  hasTeamData: boolean;

  constructor(private chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.hasTeamData = false;
    registerLocaleData(es);
    this.customAvatar();
    this.chdRef.detectChanges();
    if (this.section !== 'consumer' && this.sectionInfo.responsible.team !== undefined && this.sectionInfo.responsible !== undefined) {
      if (this.sectionInfo.responsible.team[0].name && this.sectionInfo.responsible.team[0].name !== undefined) {
        this.hasTeamData = true;
      }
    }
  }
  ngAfterViewInit() {
    this.chdRef.detectChanges();
    this.viewAll('less');
  }
  customAvatar() {
    if (this.sectionInfo.name.includes(' ')) {
      const space = this.sectionInfo.name.indexOf(' ');
      this.avatarLetters = this.sectionInfo.name.charAt(0) + this.sectionInfo.name.charAt(space + 1);
    } else {
      this.avatarLetters = this.sectionInfo.name.charAt(0);
    }
    switch (this.section) {
      case 'consumer':
        this.color = 'brown';
        break;
      case 'constructor':
        this.color = 'blue';
        break;
      case 'management':
        this.color = 'red';
        break;
    }
    if (this.sectionInfo.responsible && this.sectionInfo.responsible !== undefined
      && this.sectionInfo.responsible.team && this.sectionInfo.responsible.team !== undefined) {
      if (this.sectionInfo.responsible.team.length > 0) {
        this.sectionInfo.responsible.team.forEach(person => {
          if (person.avt && person.avt !== undefined) {
            person.hasAvatarImage = true;
          } else {
            person.hasAvatarImage = false;
            if (person.name && person.name !== undefined) {
              person.avatar = this.randomAvatar(person.name);
            } else {}
            // person.avatar = this.randomAvatar(person.name);
          }
        });
      }
    }
  }
  openFile(link) {
    window.open(link);
  }
  onEventClick(idP, slugP) {
    const params = {
      id: idP,
      slug: slugP
    };
    this.eventClickViewProject.emit(params);
  }
  randomAvatar(name) {
    const colorList = ['#6ce580', '#e8837d', '#8caad9', '#e8c17d'];
    let letter: string;
    if (name.includes(' ')) {
      const space = name.indexOf(' ');
      letter = name.charAt(0) + name.charAt(space + 1);
    } else {
      letter = name.charAt(0);
    }
    const color = colorList[Math.floor(Math.random() * 4)];
    const avt = {
      avtColor: color,
      avtLetter: letter
    };
    return avt;
  }
  viewAll(isOpen) {
    if (
      this.sectionInfo.responsible !== undefined &&
      this.sectionInfo.responsible.other_projects !== undefined
      ) {
        if (this.sectionInfo.responsible.other_projects.length &&
          this.sectionInfo.responsible.other_projects.length > 2) {
            this.hasViewMore = true;
          } else {
            this.hasViewMore = false;
          }

      } else {
        this.hasViewMore = false;
      }
    if (this.sectionInfo.responsible.other_projects && this.sectionInfo.responsible.other_projects !== undefined) {
      const projects = this.sectionInfo.responsible.other_projects;
      if (isOpen === 'less') {
        this.isOpen = false;
        if (projects[0] && !projects[1]) {
          this.otherProjects = [projects[0]];
        }
        if (projects[0] && projects[1]) {
          this.otherProjects = [projects[0], projects[1]];
        }
      } else {
        this.isOpen = true;
        this.otherProjects = projects;
      }
    }
    this.chdRef.detectChanges();
  }
}
