import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../toast/toast.service';

@Component({
  selector: 'app-invest-success',
  templateUrl: './invest-success.component.html',
  styleUrls: ['./invest-success.component.scss'],
})
export class InvestSuccessComponent implements OnInit {

  @Output() public eventGoHome = new EventEmitter<any>();
  @Output() public eventShare = new EventEmitter<any>();
  @Input() name: any;
  @Input() amount: any;
  @Input() prevInvest: any;

  @Input() refCode: any;
  @Input() data: any;

  shareText: string;
  isCopied: boolean;
  paymentType: string;
  ref: string;

  constructor(public translate: TranslateService, public toastService: ToastService) { }

  ngOnInit() {
    if (this.data.investByCard) {
      this.paymentType = 'Card';
    } else {
      this.paymentType = 'Wallet';
    }
    registerLocaleData(es);

    this.isCopied = false;

    this.shareText =
      this.translate.instant('REFERRAL.PAGE.SHARE_TEXT_1') +
      this.translate.instant('REFERRAL.PAGE.SHARE_TEXT_2') +
      this.refCode +
      this.translate.instant('REFERRAL.PAGE.SHARE_TEXT_3');

    if (this.refCode !== undefined) {
      this.ref = 'Referido';
    } else {
      this.ref = undefined;
    }
  }
  goHome() {
    this.eventGoHome.emit();
  }
  share() {
    this.eventShare.emit();
  }
  copyCode(refCode) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = refCode;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.showMsgCopied(refCode);
    this.toastService.showToast(refCode + ' copiado', 'success', true);
  }
  showMsgCopied(ref) {
    this.isCopied = true;
    setTimeout(() => (this.isCopied = false), 3000);
  }
  shareWatsapp() {
    const share = 'https://api.whatsapp.com/send?text=' + this.shareText;
    window.open(share);
  }
  shareFacebook() {
    const share = 'http://www.facebook.com/sharer/sharer.php?u=www.fundeen.com/es/';
    window.open(share);
  }
  shareTwitter() {
    const share = 'http://twitter.com/intent/tweet?text=' + this.shareText;
    window.open(share);
  }
  shareLinkedin() {
    const share =
      'https://www.linkedin.com/shareArticle?mini=true&url=https://www.fundeen.com/es/&title=' +
      this.shareText +
      '&summary=fundeen.com&source=Fundeen';
    window.open(share);
  }
  shareMail() {
    const ssubjectMail = this.translate.instant('REFERRAL.SUBJECT_MAIL');
    const share = 'mailto:?subject=' + ssubjectMail + '&body=' + this.shareText;
    window.open(share);
  }

}
