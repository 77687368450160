import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fun-my-profile-submenu',
  templateUrl: './my-profile-submenu.component.html',
  styleUrls: ['./my-profile-submenu.component.scss']
})
export class MyProfileSubmenuComponent implements OnInit {

  @Input('page') page;
  @Output() public changeLevel = new EventEmitter<any>();

  slideOpts: any;

  constructor() { }

  ngOnInit() {
    this.createSlider();
  }

  createSlider() {
    let index: number;

    switch (this.page) {
      case 'my-profile':
        index = 0;
        break;
      case 'movements':
        index = 1;
        break;
      case 'notifications':
        index = 2;
        break;
      case 'referral-program':
        index = 3;
    }
    this.slideOpts = {
      initialSlide: 0, //index,
      speed: 1000,
      //width: 100,
      effect: 'slide', // slide, fade, cube, coverflow, flip
      slidesPerView: 4,//'auto',
      spaceBetween: 4,
      grabCursor: true,
      slideToClickedSlide: false, //this.isMobile,
      freeMode: true, //this.isMobile,
      //centeredSlides: true//this.isMobile,
    };
  }

  selected(url) {
    this.changeLevel.emit(url);
  }

}
