import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { NavController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Capacitor } from '@capacitor/core';
import { SidemenuService } from '../sidemenu/sidemenu.service';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnInit {

  name: string;
  tabSelected: string;
  platform: any;

  constructor(
    private navCtrl: NavController,
    private localize: LocalizeRouterService,
    private router: Router,
    private profileService: ProfileService,
    private authService: AuthService,
    private sideMenuService: SidemenuService,
    private chdRef: ChangeDetectorRef
    ) { }

  ngOnInit() {
    this.getNameProfile();
    this.getTabSelected();
    this.platform = Capacitor.getPlatform();
    this.authService.profileGeneratedEvent.subscribe(() => {
      setTimeout(() => {
        this.getNameProfile();
      }, 400);
      this.chdRef.detectChanges();
    });
  }

  async getNameProfile() {
    this.name = await this.sideMenuService.getUserName();
  }

  getTabSelected() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (
          val.url.includes('/es/portfolio') ||
          val.url.includes('/en/portfolio') ||
          val.url.includes('/ca/portfolio') ||
          val.url.includes('/es/graficas') ||
          val.url.includes('/en/graphics') ||
          val.url.includes('/ca/grafiques') ||
          val.url.includes('/es/actualizaciones') ||
          val.url.includes('/en/updates') ||
          val.url.includes('/ca/actualitzacions') ||
          val.url.includes('/en/documentation') ||
          val.url.includes('/es/documentacion') ||
          val.url.includes('/ca/documentació') ||
          val.url.includes('/es/calendario-de-pagos') ||
          val.url.includes('/en/payment-calendar') ||
          val.url.includes('/ca/calendari-de-pagaments')
        ) {
          this.tabSelected = 'portfolio';
        } else if (
          val.url.includes('/es/agora') ||
          val.url.includes('/en/agora') ||
          val.url.includes('/ca/agora') ||
          val.url.includes('/es/mis-pujas') ||
          val.url.includes('/en/my-bids') ||
          val.url.includes('/ca/les-meves-licitacions') ||
          val.url.includes('/es/mis-ofertas') ||
          val.url.includes('/en/my-offers') ||
          val.url.includes('/ca/les-meves-ofertes') ||
          val.url.includes('/es/listado-de-proyectos') ||
          val.url.includes('/en/list-of-projects') ||
          val.url.includes('/ca/llistat-de-projectes')
        ) {
          this.tabSelected = 'investments';
        } else if (
          val.url.includes('/es/movimientos') ||
          val.url.includes('/en/movements') ||
          val.url.includes('/ca/moviments')
        ) {
          this.tabSelected = 'movements';
        } else if (
          val.url.includes('/es/ayuda')
          || val.url.includes('/ca/ajuda')
          || val.url.includes('/en/help')
        ) {
          this.tabSelected = 'help';
        } else if (
          val.url.includes('/es/mi-perfil')
          || val.url.includes('/ca/el-meu-perfil')
          || val.url.includes('/en/my-profile')
        ) {
          this.tabSelected = 'profile';
        }
      }
    });
  }

  goTo(url) {
    if ((this.name === null || this.name === '' || this.name === undefined) && url === '/my-profile') {
      this.profileService.getUserData().subscribe(resp => {
        const model = {
          username: resp.username,
          mailVerified: resp.enabled,
          profileType: resp.profileType,
          mobile: resp.mobile,
          phone: resp.mobile,
          mobileVerified: resp.mobileVerified
        }
        this.authService.completeProfile(model);
      });
    } else {
      this.navCtrl.navigateRoot([this.localize.translateRoute(url)]);
    }
  }


}
