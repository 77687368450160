<div class="w-full">
  <div class="flex justify-between">
    <div class="relative">
      <div class="absolute bottom-0 px-[8px] py-[6px] bg-eggplant-100/[0.1] rounded-[50%] cursor-pointer"
      *ngIf="isMyOffers || isMyBids || isPaymentCalendar || isDocumentation || isUpdates || isUpdateProfile" (click)="goBack()">
        <fun-icon [name]="'chevron-left'" [style]="'stroke-2 stroke-eggplant-100 w-[8px] h-[13px] '"></fun-icon>
      </div>
      <div class="hidden md:block absolute bottom-0 left-32 px-[8px] py-[6px] w-max bottom-[-32px] md:bottom-[-9px]">
        <fun-badge-group *ngIf="hasBadge" [props]="badgeProps"></fun-badge-group>
      </div>
    </div>
    <div [ngClass]="{ 'pt-34': detectOS() !== 'iOS', 'pt-48': detectOS() === 'iOS' }" class="md:pr-32 md:pt-[62px] right-0 w-full w-auto">
      <div class="flex flex-end">
        <div class="pr-32">
          <img class="cursor-pointer" src="../../../assets/images/new_branding/gift.svg" alt="gift" (click)="navigateTo('referral-program')">
        </div>
        <div class="relative" (click)="openMenu('notifications')" id="userMenu">
          <img id="bell" class="cursor-pointer" src="../../../assets/images/new_branding/bel.svg" alt="bel">
          <p id="notification-number" *ngIf="notificationsNumber > 0"
            class="notifications_number font-sans text-s text-white w-[20px] h-[20px] text-center absolute cursor-pointer"
            [ngClass]="{ 'img-transition': notificationsAction }">{{ notificationsNumber }}</p>
        </div>

        <div id="submenuNav" class="submenu-notifications submenu-transition absolute mt-32 w-[416px] right-0 rounded-2xl bg-white"
        [ngClass]="{ 'openMenu': submenuOpen, 'closeMenu': !submenuOpen }">
        <!---->
          <div *ngIf="realList.length > 0 && !loadingNotifications" class="bg-white rounded-2xl p-24 appear">
            <!-- <fun-notification-list [realList]="realList"></fun-notification-list> -->
            <p class="font-sans text-center text-xl text-eggplant-100 pb-12">{{ 'NOTIFICATIONS.TITLE' | translate }}</p>
            <div *ngFor="let notification of realList" class="notification" [id]="'notification-' + notification.id">
              <div (click)="readNotification(notification)" class="pb-8 cursor-pointer" [id]="'notification-body-' + notification.id">
                <div [id]="'notification-content-' + notification.id" class="notification_content w-full p-8 rounded-xl border border-eggplant-100/[0.1] relative"
                [ngClass]="{ 'bg-cream-100/[0.2]': notification.status !== 'VIEWED' }">

                  <div class="flex " [id]="'notification-bodycontent-' + notification.id">
                    <div class="pt-4 icon-container" [id]="'notification-icons-' + notification.id">
                       <fun-icon *ngIf="notification.status !== 'VIEWED'" [name]="'investments'" [style]="'w-16 h-16 stroke-[4px] stroke-[#29141F]'"></fun-icon>
                       <fun-icon *ngIf="notification.status === 'VIEWED'" [name]="'investments'" [style]="'w-16 h-16 stroke-[4px] stroke-[#C9C4C7]'"></fun-icon>
                    </div>
                    <p class="font-sans text-l pl-12" [id]="'notification-text-' + notification.id"
                    [ngClass]="{ 'text-eggplant-100 not-readed': notification.status !== 'VIEWED', 'text-eggplant-100/[0.4]': notification.status === 'VIEWED' }">
                      {{ notification.title }}. {{ notification.short_text }}
                    </p>
                  </div>
                  <p class="font-sans text-s text-eggplant-100/[0.4] pl-32">{{ notification.timeRemaining }}</p>
                </div>
              </div>
            </div>
            <p class="view_more font-sans text-orange-100 cursor-pointer text-xl text-center mt-12" (click)="navigateTo('notifications')">{{ 'INFO.BTN_MSG_BALEARES' | translate }}</p>
          </div>
          <!---->
          <div *ngIf="loadingNotifications">
            <div class="notification">
              <div class="notification_content">
                <fun-spinner></fun-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
