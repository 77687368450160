<div class="register">
    <div class="body">
        <div *ngIf="isNationalResidence && autocompleteMode" class="name-container">
            <div class="inp">
                <fun-input
                    placeholder="{{ 'register.identity.street' | translate }}"
                    [parentForm]="addressForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="addressAutocomplete"
                    [id]="'addressAutocomplete'"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="Dirección"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    (addressPlaced)="getAddress($event)"
                    [isDesktop]="desktopApp"
                ></fun-input>
            </div>
            <p class="discard-autocomplete">{{ 'REGISTER.MY_PROFILE.ADDRESS_AUTOCOMPLETE_SWITCH' | translate }} <span (click)="switchAutocomplete(false)">{{ 'REGISTER.MY_PROFILE.CHANGE_PHONE_2' | translate }}</span></p>
        </div>
        <div *ngIf="!autocompleteMode" class="name-container">
            <div class="inp">
                <fun-input
                    placeholder="{{ 'register.identity.street' | translate }}"
                    [parentForm]="addressForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="address"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="Dirección"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [value]="profile.taxAddress"
                ></fun-input>
            </div>
            <div class="inp">
                <fun-input
                    placeholder="{{ 'register.identity.post_code' | translate }}"
                    [parentForm]="addressForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="cp"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="{{ 'register.identity.post_code' | translate }}"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    (change)="getInfo()"
                    [value]="profile.taxPostalCode"
                ></fun-input>
            </div>
            <div class="inp" *ngIf="!isNationalResidence">
                <fun-input
                    placeholder="{{ 'register.identity.province' | translate }}"
                    [parentForm]="addressForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="internationalRegion"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="{{ 'register.identity.province' | translate }}"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [value]="profile.taxInternationalRegion"
                ></fun-input>
            </div>
            <div class="inp" *ngIf="isNationalResidence">
                <fun-input
                    *ngIf="!isMobile"
                    placeholder="{{'register.identity.province' | translate}}"
                    [parentForm]="addressForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="region"
                    type="select"
                    [errorLogin]="errorAmount"
                    labelName="{{ 'register.identity.province' | translate }}"
                    (selectChange)="changeRegion()"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [items]="regionList"
                    [selectedItem]="taxRegion"
                ></fun-input>
                <form *ngIf="isMobile" action="" [formGroup]="addressForm">
                    <div class="inp select form-input">
                        <label>{{ 'register.identity.province' | translate }}</label>
                        <select class="select-box" [ngClass]="{'option': addressForm.controls['region'].value !== null}"
                        name="region" formControlName="region" (change)="changeRegion()">
                            <option hidden [value]="null">{{'register.identity.province' | translate}}</option>
                            <option *ngFor="let region of regionList" [value]="region.id">
                                {{ region.name }}
                            </option>
                        </select>
                    </div>
                </form>
            </div>
            <div class="inp">
                <fun-input
                    *ngIf="towns === undefined || profile.taxCountry !== 108"
                    placeholder="{{ 'register.identity.town' | translate }}"
                    [parentForm]="addressForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="city"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="{{ 'register.identity.town' | translate }}"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [value]="city"
                ></fun-input>
                <!---->
                <fun-input
                    *ngIf="towns !== undefined && profile.taxCountry === 108 && !isMobile"
                    placeholder="{{'register.identity.town' | translate}}"
                    [parentForm]="addressForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="city"
                    type="select"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.identity.town' | translate}}"
                    (selectChange)="getInfo()"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [items]="townList"
                    [selectedItem]="city"
                ></fun-input>
                <form *ngIf="towns !== undefined && profile.taxCountry === 108 && isMobile" action="" [formGroup]="addressForm">
                    <div class="inp select form-input">
                        <label>{{ 'register.identity.town' | translate }}</label>
                        <select class="select-box" [ngClass]="{'option': addressForm.controls['city'].value !== null}"
                        name="city" formControlName="city" (change)="getInfo()">
                        <option hidden [value]="null">{{'register.identity.town' | translate}}</option>
                            <option *ngFor="let town of townList" [value]="town.id">
                                {{ town.name }}
                            </option>
                        </select>
                    </div>
                </form>
            </div>
            <p *ngIf="isNationalResidence" class="discard-autocomplete">{{ 'REGISTER.MY_PROFILE.ADDRESS_AUTOCOMPLETE_SWITCH_ON' | translate }} <span (click)="switchAutocomplete(true)">{{ 'REGISTER.MY_PROFILE.CHANGE_PHONE_2' | translate }}</span></p>
        </div>
    </div>
</div>

