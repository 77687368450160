<div>
  <div *ngIf="!isSubmitted && !loading" class="max-w-screen-md block m-auto pt-48">
    <p class="font-sans text-l text-eggplant-100/[0.55] text-center pb-24">{{ 'NEWS.TXT' | translate }}</p>
    <div class="pb-32">
      <p *ngIf="!wantToChangeMail" class="font-sans text-m text-eggplant-100/[0.4] text-center">Tu email:</p>
      <p *ngIf="!wantToChangeMail" class="font-sans text-xl text-eggplant-80 py-6 text-center">{{ defaultEmail }}</p>
      <fun-input
        *ngIf="wantToChangeMail"
        labelName="Dirección de correo"
        placeholder="nombre@tucorreo.es"
        [parentForm]="emailForm"
        [formControlKeys]="formControlKeys"
        [errorMessages]="errorMessages"
        [isSubmit]="isSubmit"
        controlName="email"
        type="email"
        [errorLogin]="errorLogin"
        [disabled]="sendXHR"
        (keyup)="validateForm()"
      ></fun-input>
      <!-- <p class="font-sans text-m text-orange-100 cursor-pointer text-center" (click)="changeM()">Cambiar</p> -->

      <div *ngIf="showCheck" class="form-input checkbox block m-auto max-w-[320px] my-32">
        <input [(ngModel)]="acceptCommunications" (change)="toggleCommunicationss()" type="checkbox" id="checkboxSame">
        <label class="font-sans pt-8 text-eggplant-70 text-l" for="checkboxSame">{{ 'register.email.news' | translate }}</label>
      </div>
    </div>
    <div class="w-full flex justify-center items-center">
      <fun-new-button
        [buttonType]="btnType"
        (eventClick)="submitForm()"
        title="{{ 'NEWS.CTA' | translate }}"
        [size]="'xl'"
      ></fun-new-button>
    </div>
  </div>
  <div *ngIf="isSubmitted && !loading">
    <!--Aqui añado un overal message-->
    <fun-overal-message [text]="text" [image]="image" ></fun-overal-message>
  </div>
  <div *ngIf="loading">
    <fun-spinner></fun-spinner>
  </div>

</div>