<div *ngIf="realList.length > 0" class="bg-white rounded-2xl p-24 pb-48 h-full overflow-auto scrollbar-none">
  <div class="flex justify-end">
    <fun-icon [name]="'x'" [style]="'w-32 h-32 stroke-[4px] stroke-[#29141F]'" (click)="closeModal()"></fun-icon>
  </div>
  <p class="font-sans text-center text-xl text-eggplant-100 pb-12">{{ 'NOTIFICATIONS.TITLE' | translate }}</p>
  <div *ngFor="let notification of realList" class="notification">
    <div (click)="readNotification(notification.id)" class="pb-8 cursor-pointer">
      <div class="notification_content w-full p-[16px] rounded-xl border border-eggplant-100/[0.1] relative"
      [ngClass]="{ 'bg-cream-100/[0.2]': notification.status !== 'VIEWED' }">

        <div class="flex ">
          <div class="pt-4 icon-container">
             <fun-icon *ngIf="notification.status !== 'VIEWED'" [name]="'investments'" [style]="'w-16 h-16 stroke-[4px] stroke-[#29141F]'"></fun-icon>
             <fun-icon *ngIf="notification.status === 'VIEWED'" [name]="'investments'" [style]="'w-16 h-16 stroke-[4px] stroke-[#C9C4C7]'"></fun-icon>
          </div>
          <p class="font-sans text-l pl-12 pr-12"
          [ngClass]="{ 'text-eggplant-100 not-readed': notification.status !== 'VIEWED', 'text-eggplant-100/[0.4]': notification.status === 'VIEWED' }">
            {{ notification.title }}. {{ notification.short_text }}
          </p>
        </div>
        <p class="font-sans text-s text-eggplant-100/[0.4] pl-32">{{ notification.timeRemaining }}</p>
      </div>
    </div>
  </div>
  <p class="view_more font-sans text-orange-100 cursor-pointer text-xl text-center mt-12 pb-12" (click)="navigateTo('notifications')">{{ 'INFO.BTN_MSG_BALEARES' | translate }}</p>
</div>