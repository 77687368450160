<div *ngIf="!loading" class="procedures_container">
  <div class="procedures_table">
    <div class="procedures_header">
    <div class="procedures_content w-full rounded-2xl border border-eggplant-100/[0.1] bg-white">
      <div *ngFor="let procedure of procedures" class="rounded-2xl">
        <div class="procedure_cell ml-12 mr-12 md:ml-24 md:mr-24">
          <div class="cell project-cell">
            <p class="font-sans text-m md:text-l text-eggplant-100">{{ procedure.project_name }}</p>
            <h4 class="decision-mob font-sans text-m md:text-l text-eggplant-100/[0.55]">{{ procedure.description }}: <span *ngIf="procedure.status === 'INVESTMENT_INTENTION'"> {{ procedure.amount | number: '1.0-2':'es' }} €</span></h4>
          </div>
          <div class="cell decision-cell decision-desktop">
            <p class="font-sans text-m md:text-l text-eggplant-100/[0.55] text-center">{{ procedure.description }}: <span *ngIf="procedure.status === 'INVESTMENT_INTENTION'"> {{ procedure.amount | number: '1.0-2':'es' }} €</span></p>
          </div>
          <div class="cell date-cell">
            <div class="cell full-date-cell">
              <p class="font-sans text-m md:text-l text-eggplant-100/[0.55]">
                <span *ngIf="procedure.status === 'INVESTMENT_INTENTION'">{{ procedure.date }}</span>
                <span *ngIf="procedure.status !== 'INVESTMENT_INTENTION'">{{ 'FORMALITIES.RESERVE_PROCESS.BTN' | translate }}: {{ procedure.amount | number: '1.0-2':'es' }} €</span>
              </p>
            </div>
            <div class="cell action-cell" [ngClass]="{'action-cell': procedure.status === 'INVESTMENT_INTENTION' }">
                <fun-new-button
                *ngIf="procedure.status !== 'INVESTMENT_INTENTION'"
                  [buttonType]="'primaryDark'"
                  (eventClick)="formalize(procedure)"
                  title="Formalizar"
                  size="m"
                ></fun-new-button>
                <div class="more_info_action items-center justify-center" *ngIf="procedure.status === 'INVESTMENT_INTENTION'">
                  <p class="delete_btn font-sans text-l" *ngIf="procedure.deleted_opened" (click)="deleteProcedure(procedure)">{{ 'NOTIFICATIONS.BTN_1' | translate }}</p>
                  <img src="../../../../assets/icons/dots.svg" alt="" (click)="toggleDeleteBtn(procedure.id)" class="pt-12">
                  <!-- <img src="../../../../assets/icons/down-disabled.svg" alt="" [ngClass]="{'up-content': procedure.opened, 'down-content': !procedure.opened }" (click)="openMoreInfoProcedure(procedure.id)"> -->
                </div>
            </div>
          </div>
          <div class="more_info_cell" [ngClass]="{'more_info': procedure.opened, 'more_info_hidden': !procedure.opened }" [id]="'content_' + procedure.id">
            <div *ngIf="procedure.moreInfo && procedure.moreInfo !== null && procedure.moreInfo !== undefined">
              <p *ngIf="procedure.moreInfoDate !== null && procedure.moreInfoDate !== undefined" class="date font-sans text-m text-eggplant-100/[0.4]">{{ procedure.moreInfoDate }}</p>
              <h3 class="font-sans text-xl text-eggplant-80">{{ procedure.project_name }}</h3>
              <p class="txt font-sans text-m text-eggplant-100/[0.4]">{{ procedure.moreInfo }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading">
  <fun-spinner></fun-spinner>
</div>