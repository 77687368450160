import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fun-new-detail',
  templateUrl: './new-detail.component.html',
  styleUrls: ['./new-detail.component.scss'],
})
export class NewDetailComponent implements OnInit {

  @Output() private closeModalEvent = new EventEmitter<void>();
  @Input() public project;
  @Input() public page = 'overview';
  @Input() public openProjectInvestmentProccess = false;

  mobileScreen = window.innerWidth < 780 ? true : false;
  closeProccess = false;

  constructor() { }

  ngOnInit() { }

  closeModal() {
    if (window.innerWidth < 780) {
      this.closeModalEvent.emit();
    } else {
      this.closeProccess = true;
      setTimeout(() => {
        this.closeModalEvent.emit();
      }, 200);
    }
  }

}
