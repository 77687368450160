<div class="general-container">
    <div class="text-container">
        <h2 *ngIf="!loading">{{ 'Wallet_activated.title' | translate }}</h2>
        <p *ngIf="!loading && (info.status > 4 && info.status !== 12)">{{ 'Wallet_activated.Txt_1_financed' | translate }}</p>
        <p *ngIf="!loading && (info.status <= 4 || info.status === 12)">{{ 'Wallet_activated.Txt_1_future' | translate }}</p>
        <p *ngIf="!loading">{{ 'Wallet_activated.you_can' | translate }} <span (click)="transfer()">{{ 'Wallet_activated.transfer' | translate }}</span> {{ 'Wallet_activated.Txt_2' | translate }}</p>

        <div class="mob-txt-skl content-skl" *ngIf="loading">
            <ion-skeleton-text class="mob-txt-skl" *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
            <ion-skeleton-text class="mob-txt-skl" *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
            <ion-skeleton-text class="mob-txt-skl" *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
        </div>
        <div class="mob-txt-skl content-skl" *ngIf="loading">
            <ion-skeleton-text class="mob-txt-skl" *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
        </div>
        <div class="mob-txt-skl content-skl" *ngIf="loading">
            <ion-skeleton-text class="mob-txt-skl" *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
        </div>
        <div class="mob-txt-skl content-skl" *ngIf="loading">
            <ion-skeleton-text class="mob-txt-skl" *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
        </div>
        <div class="mob-txt-skl content-skl" *ngIf="loading">
            <ion-skeleton-text class="mob-txt-skl" *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
        </div>
        <div class="mob-txt-skl content-skl" *ngIf="loading">
            <ion-skeleton-text class="mob-txt-skl" *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
        </div>
        <div class="mob-txt-skl content-skl" *ngIf="loading">
            <ion-skeleton-text class="mob-txt-skl" *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
        </div>

        <fun-skeleton-list *ngIf="loading"></fun-skeleton-list>
    </div>
    <div class="card-container">
        <fun-skeleton-card *ngIf="loading"></fun-skeleton-card>
        <fun-new-card *ngIf="!loading" [project]="info" [size]="'m'" (viewProjectEvent)="emitClickProject($event, info)"></fun-new-card>
    </div>
</div>