<div class="w-full rounded-2xl border border-eggplant-100/[0.1] bg-white content">
  <div *ngFor="let item of list" class="lst flex appear">
    <div class="p-[18px]">
      <fun-icon [name]="item.icon" [style]="'stroke-[4px] stroke-eggplant-100 w-20 h-20'"></fun-icon>
    </div>
    <div class="w-full py-8 cursor-pointer" [ngClass]="{'border-b border-b-eggplant-100/[0.1]': item.border }" (click)="iconClicked(item.id)">
      <div class="flex justify-between">
        <div>
          <p class="font-sans text-l text-eggplant-100">{{ item.title }}</p>
          <p class="font-sans text-s text-eggplant-100/[0.55]">{{ item.subtitle }}</p>
        </div>
        <div class="pt-16 pr-24">
          <fun-icon [name]="'chevron-right'" [style]="'stroke-[2px] stroke-eggplant-100/[0.4] w-[8px] h-[14px]'"></fun-icon>
        </div>
      </div>
    </div>
  </div>
</div>