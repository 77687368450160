import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RegisterDocument } from 'src/app/shared/models/register.models';
import { AlertService } from '../../alert/alert.service';
import { OveralMessageComponent } from '../../overal-message/overal-message.component';
import { Capacitor } from '@capacitor/core';
import { CameraPreview, CameraPreviewOptions, CameraPreviewPictureOptions } from '@ionic-native/camera-preview/ngx';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'fun-register-file',
  templateUrl: './register-file.component.html',
  styleUrls: ['./register-file.component.scss']
})
export class RegisterFileComponent implements OnInit, AfterViewInit {

  @Output() public prevStepEvent = new EventEmitter<any>();
  @Output() public lastStepEvent = new EventEmitter<any>();
  @Output() public finishRegisterEvent = new EventEmitter<any>();
  @Output() public documentEvent = new EventEmitter<any>();
  @Output() public updateDocumentEvent = new EventEmitter<any>();
  @Output() public updateNumberEvent = new EventEmitter<any>();
  @Input() public isMobileApp: any;
  @Input() public info;
  @Input() public isRegister;
  @Input() public canGoBack;
  @Input() public stepList;
  @Input() isMobile: boolean;

  hasFrontalDocument: boolean;
  sendXHR: string;
  backBtn: string;
  public docPage: string;
  public idBtnName: string;
  public step = 0;
  public document: RegisterDocument = {};
  loading = false;
  stepsL = [];
  defaultDni = { name: 'DNI', id: '1' };
  defaultNie = { name: 'NIE', id: '2'};
  defaultPassport = { name: 'Pasaporte', id: '3'};
  docNumberModel: any;

  dniFileIndex: number;
  nieFileIndex: number;
  dniDocIndex: number;
  passportFileIndex: number;
  nieDocIndex: number;
  passportDocIndex: number;
  btnId: string;

  bothDocumentsInSameFile = false;

  urlImageFront: string;
  urlImageBack: string;
  platform: string;
  cameraEnable = false;
  currentPicture;

  constructor(
    private chdRef: ChangeDetectorRef,
    private alertService: AlertService,
    public translate: TranslateService,
    private cameraPreview: CameraPreview,
    private platformSoft: Platform,
    ) { }

  ngOnInit() {
    this.platform = Capacitor.getPlatform();
    if (this.canGoBack !== undefined) {
      this.backBtn = 'secondary';
    } else {
      this.backBtn = 'secondary-disabled';
    }

    if (this.stepList !== undefined) {
      this.stepsL = this.stepList;
    } else {
      const type = Number(this.info.docType);
      switch (type) {
        case 1:
          this.docPage = 'nif';
          this.idBtnName = 'dni_photo';
          if (this.stepList === undefined) {
            this.stepsL = [
              {index: 0, name: 'dni_file'}
            ];
          }
          break;
        case 2:
          this.docPage = 'nie';
          this.idBtnName = 'nie_photo';
          if (this.stepList === undefined) {
            this.stepsL = [
              {index: 0, name: 'nie_file'},
              //{index: 1, name: 'passport_number'},
              //{index: 2, name: 'passport_file'}
            ];
          }
          break;
        case 3:
          this.docPage = 'passport';
          this.idBtnName = 'passport_photo';
          // this.openModalMessage();
          if (this.stepList === undefined) {
            this.stepsL = [
              {index: 0, name: 'passport_file'},
              //{index: 1, name: 'nie_number'},
              //{index: 2, name: 'nie_file'}
            ];
          }
          break;
      }
    }

    this.stepsL.forEach(element => {
      switch (element.name) {
        case 'dni_file':
          this.dniFileIndex = element.index;
          break;
        case 'nie_file':
          this.nieFileIndex = element.index;
          break;
        case 'passport_file':
          this.passportFileIndex = element.index;
          break;
        case 'passport_number':
          this.passportDocIndex = element.index;
          break;
        case 'nie_number':
          this.nieDocIndex = element.index;
          break;
        case 'dni_number':
          this.dniDocIndex = element.index;
          break;
      }
    });
    this.errorFile();
    this.getBtnId();
    this.chdRef.detectChanges();
  }

  ngAfterViewInit() {
    this.listenOnHardwareBackButton();
  }

  getFrontalFile(value) {
    this.urlImageFront = `data:${value.format};base64,${value.file}`;
    this.hasFrontalDocument = true;
    if (this.step === this.dniFileIndex) {
      this.document.frontDocument = value;
      this.sendXHR = this.urlImageBack !== null ? 'primaryDark' : 'primaryDark-disabled';
    }
    if (this.step === this.nieFileIndex) {
      this.document.frontNieDocument = value;
    }
    if (this.step === this.passportFileIndex) {
      this.document.frontPassportDocument = value;
    }
    if (this.info.docType !== '1' && this.info.docType !== 1) {
      this.sendXHR = 'primaryDark';
    } else if ((this.info.docType === '1' || this.info.docType === 1) && this.bothDocumentsInSameFile) {
      this.sendXHR = 'primaryDark';
    }

    this.documentEvent.emit(this.document);
    this.chdRef.detectChanges();
  }

  getBackFile(value) {
    this.urlImageBack = `data:${value.format};base64,${value.file}`;

    if (this.step === this.dniFileIndex) {
      this.document.backDocument = value;
      this.sendXHR = this.urlImageFront !== null ? 'primaryDark' : 'primaryDark-disabled';
    }
    if (this.step === this.nieFileIndex) {
      this.document.backNieDocument = value;
    }
    this.documentEvent.emit(this.document);
    this.chdRef.detectChanges();
  }

  errorFile() {
    this.sendXHR = 'primaryDark-disabled';
    this.hasFrontalDocument = false;
    this.urlImageFront = null;
    this.chdRef.detectChanges();
  }

  errorBackDoc() {
    this.sendXHR = 'primaryDark-disabled';
    this.urlImageBack = null;
    this.chdRef.detectChanges();
  }

  next() {
    this.backBtn = 'secondary';
    if (this.step === this.dniFileIndex || this.step === this.nieFileIndex || this.step === this.passportFileIndex) {
      this.updateDocumentEvent.emit(this.document);
      this.document = {};
    } else {
      this.updateNumberEvent.emit(this.docNumberModel);
    }
    this.step++;
    this.sendXHR = 'primaryDark-disabled';
    this.hasFrontalDocument = false;
    this.getBtnId();
    this.chdRef.detectChanges();
  }

  prev() {
    if (this.canGoBack) {
      this.backBtn = 'secondary';
      if (this.step === 0) {
        this.prevStepEvent.emit();
      }
    } else {
      this.backBtn = 'secondary-disabled';
    }
    this.step--;
    this.getBtnId();
    this.chdRef.detectChanges();
  }

  openModalMessage() {
    const msg = this.translate.instant('passport.txt');
    const back = this.translate.instant('passport.btn');
    const modalParams = {
      text: msg,
      image: 1,
      btnList: [
        {
          type: 'primaryDark',
          index: 1,
          title: back,
        },
      ],
    };
    this.alertService.showModal(OveralMessageComponent, modalParams, '', '', true).subscribe((modal: any) => {
      modal.getChildComponent().subscribe((componentRef: OveralMessageComponent) => {
        componentRef.btnEvent.subscribe((resp) => {
          this.alertService.removeModal();
        });
      });
      modal.onClose().subscribe();
    });
    this.chdRef.detectChanges();
  }

  getDniInfo(value) {
   switch (value.docType) {
    case '1':
      this.docNumberModel = {identityDocumentDni: value.dni};
      break;
    case '2':
      this.docNumberModel = {identityDocumentNie: value.dni};
      break;
    case '3':
      this.docNumberModel = {identityDocumentPassport: value.dni};
      break;
    }
    this.sendXHR = 'primaryDark';
    this.info.docType = value.docType;
    this.info.docNumber = value.dni;
    this.chdRef.detectChanges();
  }

  getError() {
    this.sendXHR = 'primaryDark-disabled';
    this.chdRef.detectChanges();
  }

  isLoading(value, error?: boolean) {
    if (value === false) {
      if (error === true) {
        this.prev();
        this.loading = value;
      } else {
        if (this.step === this.stepsL.length) {
          this.lastStepEvent.emit();
        } else {
          this.loading = value;
        }
      }
    } else {
      this.loading = value;
    }
    this.chdRef.detectChanges();
  }

  getBtnId() {
    switch (this.step) {
      case this.dniFileIndex:
        this.btnId = 'dni_photo';
        break;
      case this.nieFileIndex:
        this.btnId = 'nie_photo';
        break;
      case this.passportFileIndex:
        this.btnId = 'passport_photo';
        break;
      case this.passportDocIndex:
        this.btnId = 'id_number';
        break;
      case this.nieDocIndex:
        this.btnId = 'id_number';
        break;
      case this.dniDocIndex:
        this.btnId = 'id_number';
        break;
    }
    this.chdRef.detectChanges();
  }

  checkBothDoc() {
    this.bothDocumentsInSameFile = !this.bothDocumentsInSameFile;
    if (this.hasFrontalDocument && this.bothDocumentsInSameFile) {
      this.sendXHR = 'primaryDark';
    } else {
      this.sendXHR = 'primaryDark-disabled';
    }
    this.chdRef.detectChanges();
  }

  finishRegister() {
    this.finishRegisterEvent.emit();
    this.chdRef.detectChanges();
  }

  async openCamera(picture) {
    this.currentPicture = picture;
    this.cameraEnable = true;

    const cameraPreviewOpts: CameraPreviewOptions = {
      x: 0,
      y: 0,
      width: window.screen.width,
      height: 500,
      camera: 'rear',
    }
    this.cameraPreview.startCamera(cameraPreviewOpts).then(
      (res) => {
        //console.log(res)
      },
      (err) => {
        console.log(err)
    });
    this.chdRef.detectChanges();
  }

  takePicture() {
    if (this.currentPicture === 'front') {
      this.takeFrontPicture();
    } else {
      this.takeBackPicture();
    }
  }

  async takeFrontPicture() {
    this.hasFrontalDocument = true;
    const pictureOpts: CameraPreviewPictureOptions = {
      width: 1024,
      height: 1024,
      quality: 90
    }
    let b64Data;
    let docName;
    const contentType = 'image/jpeg';
    // take a picture
    this.cameraPreview.takePicture(pictureOpts).then((imageData) => {
      if (Capacitor.getPlatform() !== 'web') {
        this.urlImageFront = 'data:image/jpeg;base64,' + imageData;
        b64Data = ('data:image/jpeg;base64,' + imageData).split(',', 2)[1];
        this.cameraPreview.stopCamera();
        try {
          //this.loading = true;
          const fileToUpload = {
            field: 'ID_CARD_FRONT', // ID_CARD_BACK
            file: b64Data,
            username: 'usuario', //this.username,
            format: contentType
          };

          if (this.step === this.dniFileIndex) {
            this.document.frontDocument = fileToUpload;
            this.sendXHR = this.urlImageBack !== null ? 'primaryDark' : 'primaryDark-disabled';
          }
          if (this.step === this.nieFileIndex) {
            this.document.frontNieDocument = fileToUpload;
          }
          if (this.step === this.passportFileIndex) {
            this.document.frontPassportDocument = fileToUpload;
          }
          if (this.info.docType !== '1' && this.info.docType !== 1) {
            this.sendXHR = 'primaryDark';
          } else if ((this.info.docType === '1' || this.info.docType === 1) && this.bothDocumentsInSameFile) {
            this.sendXHR = 'primaryDark';
          }

          this.documentEvent.emit(this.document);
          //this.uploadFile(fileToUpload, docName);
          this.cameraEnable = false;
        } catch (error) {
          console.error(error);
        }
      }
      this.chdRef.detectChanges();
    }, (err) => {
      console.log(err);
    });
    this.chdRef.detectChanges();
  }

  async takeBackPicture() {
    const pictureOpts: CameraPreviewPictureOptions = {
      width: 1024,
      height: 1024,
      quality: 90
    }
    let b64Data;
    const contentType = 'image/jpeg';
    // take a picture
    this.cameraPreview.takePicture(pictureOpts).then((imageData) => {
      if (Capacitor.getPlatform() !== 'web') {
        this.urlImageBack = 'data:image/jpeg;base64,' + imageData;
        b64Data = ('data:image/jpeg;base64,' + imageData).split(',', 2)[1];
        this.cameraPreview.stopCamera();
        try {
          //this.loading = true;
          const fileToUpload = {
            field: 'ID_CARD_BACK', // ID_CARD_BACK
            file: b64Data,
            username: 'this.username',
            format: contentType
          };

          if (this.step === this.dniFileIndex) {
            this.document.backDocument = fileToUpload;
            this.sendXHR = this.urlImageFront !== null ? 'primaryDark' : 'primaryDark-disabled';
          }
          if (this.step === this.nieFileIndex) {
            this.document.backNieDocument = fileToUpload;
          }
          this.documentEvent.emit(this.document);
          this.cameraEnable = false;
        } catch (error) {
          console.error(error);
        }
      }
      this.chdRef.detectChanges();
    }, (err) => {
      console.log(err);
    });
    this.chdRef.detectChanges();
  }

  private listenOnHardwareBackButton() {
    if (this.platformSoft.is('android')) {
      this.platformSoft.backButton.subscribeWithPriority(0, () => {
        this.cameraEnable = false;
        this.chdRef.detectChanges();
      });
    }
  }

}
