<img class="info" src="../../assets/images/img/info.svg" alt="info" (click)="openHelp('first', 0)">
<div class="container-header">
    <div class="container">
        <ul [class]="selectIsOpen ? 'open' : ''">
            <div (click)="selectOpen()" class="header-open" *ngIf="selectIsOpen">
                <h3>{{ 'offer-status.title' | translate }}</h3>
            </div>
            <li class="project-title font-sans text-eggplant-100" (click)="selectOpen()">
                <span class="text-m text-eggplant-100/[0.55]" *ngIf="!selectIsOpen">{{ 'offer-status.project' | translate }}</span>
                <span class="text-l">
                {{ offerData.project_name }}</span>
                <span class="hidden-lg text-l">{{ offerData.amount }} {{ 'offer-status.shares' | translate }}</span>
            </li>
            <li class="font-sans text-eggplant-100">
                <span class="text-m text-eggplant-100/[0.55]">{{ 'offer-status.seller' | translate }}</span>
                <span class="avt-name text-l" *ngIf="contactData && !storageSellerName">
                    <span class="avatar text-l text-white">{{ contactData?.sellerName + ' ' + contactData?.sellerSurname | nameProfile }}</span>
                    {{ contactData?.sellerName  + ' ' +  contactData?.sellerSurname }}
                </span>
                <span class="text-l" *ngIf="!contactData && !storageSellerName">
                    {{ 'offer-status.loading' | translate }}...
                </span>
                <span class="avt-name text-l" *ngIf="!contactData && storageSellerName">
                    {{ sName }}
                </span>
            </li>
            <li class="font-sans text-eggplant-100">
                <span class="text-m text-eggplant-100/[0.55]">{{ 'offer-status.shares' | translate }}</span>
                <span class="text-l">{{ offerData.amount }}</span>
            </li>
            <li class="font-sans text-eggplant-100">
                <span class="text-m text-eggplant-100/[0.55]">{{ 'offer-status.best-bid' | translate }}</span>
                <span class="text-l">{{ offerData.best_bid | localeNumber }} €</span>
            </li>
            <li class="font-sans text-eggplant-100">
                <span class="text-m text-eggplant-100/[0.55]">{{ 'offer-status.value' | translate }}</span>
                <span>{{ offerData.adquisition_price | localeNumber }} €</span>
            </li>
            <li class="font-sans text-eggplant-100">
                <span class="text-m text-eggplant-100/[0.55]">{{ 'offer-status.formalization' | translate }}</span>
                <span class="text-l" *ngIf="offerData.formalization_type == 'internal'">{{ 'offer-status.internal' | translate }}</span>
                <span class="text-l" *ngIf="offerData.formalization_type == 'external'">{{ 'offer-status.external' | translate }}</span>
            </li>
            <li class="font-sans text-eggplant-100">
                <span class="text-m text-eggplant-100/[0.55]">{{ 'offer-status.time-remaining' | translate }}</span>
                <span class="text-l" *ngIf="offerData.status == 'sold' || offerData.status == 'not_sold'">-</span>
                <span class="text-l" *ngIf="offerData.status == 'active' || offerData.status == 'pending_acceptation'">{{ offerData.decision_due_date | timeLeft }}</span>
            </li>
        </ul>
        <div class="overlay" (click)="selectOpen()"></div>
    </div>
</div>

<!--<div #skeleton></div>-->

<ng-container *ngFor="let stateTmpl of statesTmpl">
    <ng-container *ngIf="stateTmpl.state === currentState">
        <ng-container [ngTemplateOutlet]="this[stateTmpl.state]"></ng-container>
    </ng-container>
</ng-container>

<ng-template #activeActive>
    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.bid-receipt' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li>
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li>
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.resolution' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.pending-decision' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal">
                <section class="content-boxed row">
                    <div class="col-6 space">
                        <div class="big-bid">
                            <span>{{ 'offer-status.highest-bid' | translate }}</span>
                            <span>{{ offerData.best_bid | localeNumber }} €</span>
                        </div>
                        <div class="button-content">
                            <fun-new-button [buttonType]="btnCancel"
                            title="{{ 'offer-status.cancel-offer' | translate }}"
                            (eventClick)="onEventRejectBidClick(offerData)"></fun-new-button>
                        </div>
                    </div>
                    <div class="col-6 space">
                        <ul class="list-check children:font-sans children:text-l children:text-eggplant-100/[0.55]">
                            <li>{{ 'offer-status.bids-receipt-text-1' | translate }}</li>
                            <li>{{ 'offer-status.bids-receipt-text-2' | translate }}</li>
                            <li>{{ 'offer-status.bids-receipt-text-3' | translate }}</li>
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #activePendingAcceptation>
    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li [ngClass]="{'done': substatus === 'pending_acceptation', 'selected':  substatus === 'active'}">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.bid-receipt' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li [ngClass]="{'selected': substatus === 'pending_acceptation'}">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.seller-decision' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li>
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.pending-decision' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal bidL">
                <section *ngIf="!loadigPostAccept" class="content-boxed row bid-list-space">
                    <div class="col-12 bid-list-container desktop-list">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'offer-status.bid-list' | translate }} - {{ 'offer-status.pending-seller-decision' | translate }}</h3>
                        <div class="my-offers-proccess-table">
                            <div class="offers-table-header">
                                <div class="cell buyerN"><p>{{ 'offer-status.buyer' | translate }}</p></div>
                                <div class="cell bidN"><p>{{ 'offer-status.bid' | translate }}</p></div>
                                <div class="cell valueN"><p>{{ 'offer-status.value' | translate }}</p></div>
                                <div class="cell dayN"><p>{{ 'offer-status.bid_day' | translate }}</p></div>
                                <div class="cell actionN"></div>
                            </div>
                            <div class="offers-table-body">
                                <div *ngFor="let bid of bidList">
                                    <div (click)="seletcBid(bid.id)" class="buyerRow" [ngClass]="{'selectedBid': bid.id === bidSelectedId}">
                                        <div class="cell buyerN"><p>{{ bid.name }}</p></div>
                                        <div class="cell bidN"><p>{{ bid.price | localeNumber }} €</p></div>
                                        <div class="cell valueN"><p>{{ offerData.adquisition_price | localeNumber }} €</p></div>
                                        <div class="cell dayN"><p>{{ bid.date | date:'dd/MM/yyyy' }}</p></div>
                                        <div class="cell actionN">
                                            <div class="tick">
                                                <img *ngIf="bid.id !== bidSelectedId" src="../../assets/images/img/circle-chek.svg" alt="">
                                                <img *ngIf="bid.id === bidSelectedId" src="../../assets/images/img/circle-check-selected.svg" alt="">
                                            </div>
                                            <div class="points">
                                                <img id="dotsD" src="../../assets/images/img/dots.svg" alt="">
                                                <div (click)="onEventRejectBidClick(offerData, bid.id)"
                                                *ngIf="bid.id === bidSelectedId && dotsOpened" class="delete-container">
                                                    <p>{{ 'offer-status.reject_bid' | translate }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="btn-container">
                            <div class="btn">
                                <fun-new-button (eventClick)="onEventAcceptBidClick(offerData, bidSelectedId)" [buttonType]="btnAcceptBid"
                                title="{{ 'offer-status.button-accept-bid' | translate }}"></fun-new-button>
                            </div>
                            <div class="btn">
                                <fun-new-button (eventClick)="onEventRejectBidClick(offerData)"
                                [buttonType]="btnrejectBid" title="{{ 'offer-status.cancel-offer' | translate }}"></fun-new-button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 bid-list-container mobile-list">
                        <h3>{{ 'offer-status.bid-list' | translate }} - {{ 'offer-status.pending-seller-decision' | translate }}</h3>
                        <div div="offer-process-table">
                            <div *ngFor="let bid of bidList">
                                <div class="row-table">
                                    <div class="main-data">
                                        <p><span class="avatar">{{ bid.name | nameProfile }}</span>
                                            {{ bid.name | titlecase }}
                                        </p>
                                        <p>{{ bid.price | localeNumber }} €</p>
                                        <img class="drop" [ngClass]="{'drop-up': !bid.openMob}" (click)="openMobInfo(bid.id)" src="../../assets/images/img/up.svg" alt="">
                                    </div>
                                    <div *ngIf="bid.openMob" class="secondary-data">
                                        <article>
                                            <p class="titleN">{{ 'offer-status.value' | translate }}</p>
                                            <p class="resultN">{{ offerData.adquisition_price | localeNumber }} €</p>
                                        </article>
                                        <article>
                                            <p class="titleN">{{ 'offer-status.bid_value' | translate }}</p>
                                            <p class="resultN">{{ bid.price | localeNumber }} €</p>
                                        </article>
                                        <article>
                                            <p class="titleN">{{ 'offer-status.date' | translate }}</p>
                                            <p class="resultN">{{ bid.date | date:'dd/MM/yyyy' }}</p>
                                        </article>
                                        <div class="btn-container">
                                            <div class="btn">
                                                <fun-new-button [buttonType]="btnAcceptBidMob" title="{{ 'offer-status.button-accept-bid' | translate }}"></fun-new-button>
                                            </div>
                                            <div class="btn">
                                                <fun-new-button (eventClick)="onEventRejectBidClick(offerData, bid.id)"
                                                [buttonType]="btnrejectBid" title="{{ 'offer-status.cancel-offer' | translate }}"></fun-new-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section *ngIf="loadigPostAccept" class="row bid-list-space">
                    <fun-skeleton-list></fun-skeleton-list>
                </section>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #notSold>
    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.bid-receipt' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.cancelled_offer' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal">
                <section class="content-boxed row">
                    <div class="col-6 relance">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'offer-status.offer-resolution' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'offer-status.not-sold-text' | translate }}</p>
                        <fun-new-button
                        [buttonType]="btnAccept"
                        title="{{ 'offer-status.button-go-details-project' | translate }}"
                        (eventClick)="onEventClickRoute('detail-project')"></fun-new-button>
                    </div>
                    <div class="col-6"></div>
                </section>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #notSoldNoBids>
    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.bid-receipt' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ lostResolutionName }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal">
                <section class="content-boxed row">
                    <div class="col-6 relance">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'offer-status.offer-resolution' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24" *ngIf="offerData.sold_status !== 'internal_waiting_payment'">{{ 'offer-status.not-sold-no-bids-text' | translate }}</p>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24" *ngIf="offerData.sold_status === 'internal_waiting_payment'">{{ 'offer-status.not-sold-no-bids-text-sold' | translate }}</p>
                        <fun-new-button
                        [buttonType]="btnAccept"
                        title="{{ 'offer-status.button-relaunch-offer' | translate }}"
                        (eventClick)="onEventRelaunchOfferClick(offerData)"></fun-new-button>
                    </div>
                    <div class="col-6"></div>
                </section>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #externalSoldWaitingContractsContact>
    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.bid-receipt' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.assigned-offer' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="selected item-clickable" (click)="onClickChangeState('externalSoldWaitingContractsContact')">
                                <span class="title"><strong>{{ 'offer-status.contact-buyer' | translate }}</strong></span>
                                <span class="mark mark-null">{{ 'offer-status.after-acceptance-bid' | translate }}</span>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'offer-status.contact-buyer-text' | translate }}</p>
                                    <div *ngIf="contactData" class="avatar-box">
                                        <span class="avatar profile">{{ contactData?.buyerName  + ' ' +  contactData?.buyerSurname | nameProfile}}</span>
                                        <div class="avatar-text">
                                            <p>{{ contactData?.buyerName  + ' ' +  contactData?.buyerSurname }}</p>
                                            <p>{{ contactData?.buyerPhone }}</p>
                                            <p class="buyerMail">{{ contactData?.buyerMail }}</p>
                                        </div>
                                    </div>

                                </div>
                            </li>
                            <li class="item-clickable" (click)="onClickChangeState('externalSoldWaitingContracts')">
                                <span class="title"><strong>{{ 'offer-status.contract-upload' | translate }}</strong></span>
                                <span class="mark mark-null">{{ 'offer-status.before' | translate }} {{ offerData.external_waiting_documentation_due_date | date:'dd/MM/yyyy' }}</span>
                                <fun-new-button [size]="'l'"  buttonType="primaryDark" classExtra="mt-[12px]" title="{{ 'bid-create.continue' | translate }}"></fun-new-button>
                            </li>
                            <li>
                                <span class="title"><strong>{{ 'offer-status.change-ownership' | translate }}</strong></span>
                                <span class="mark mark-null">{{ 'offer-status.after-validity-check' | translate }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'offer-status.contact-buyer' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'offer-status.contact-buyer-text' | translate }}</p>
                        <div *ngIf="contactData" class="avatar-box">
                            <span *ngIf="contactData" class="avatar profile">{{ contactData?.buyerName  + ' ' +  contactData?.buyerSurname | nameProfile}}</span>
                            <div *ngIf="contactData" class="avatar-text">
                                <p>{{ contactData?.buyerName  + ' ' +  contactData?.buyerSurname }}</p>
                                <p>{{ contactData?.buyerPhone }}</p>
                                <p>{{ contactData?.buyerMail }}</p>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #externalSoldWaitingContracts>
    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.bid-receipt' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.assigned-offer' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="done item-clickable" (click)="onClickChangeState('externalSoldWaitingContractsContact')">
                                <span class="title"><strong>{{ 'offer-status.contact-buyer' | translate }}</strong></span>
                                <span class="mark mark-null">{{ 'offer-status.after-acceptance-bid' | translate }}</span>
                            </li>
                            <li class="selected item-clickable" (click)="onClickChangeState('externalSoldWaitingContracts')">
                                <span class="title"><strong>{{ 'offer-status.contract-upload' | translate }}</strong></span>
                                <span class="mark mark-null">{{ 'offer-status.before' | translate }} {{ offerData.external_waiting_documentation_due_date | date:'dd/MM/yyyy' }}</span>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'offer-status.contract-upload-text' | translate }}</p>
                                    <fun-file
                                    [buttonType]="fileButtonData.buttonType"
                                    [buttonTitle]="'offer-status.upload-file' | translate"
                                    [buttonAccept]="fileButtonData.buttonAccept"
                                    [urlFile]="fileButtonData.urlFile"
                                    [paramsFile]="fileButtonData.paramsFile"
                                    [showProgress]="fileButtonData.showProgress"
                                    [token]="fileButtonData.token"
                                    (eventUploadResponse)="onEventUploadResponse($event)"></fun-file>
                                </div>
                            </li>
                            <li>
                                <span class="title"><strong>{{ 'offer-status.change-ownership' | translate }}</strong></span>
                                <span class="mark mark-null">{{ 'offer-status.after-validity-check' | translate }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'offer-status.contract-upload' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'offer-status.contract-upload-text' | translate }}</p>
                        <fun-file
                        [buttonType]="fileButtonData.buttonType"
                        [buttonTitle]="'offer-status.upload-file' | translate"
                        [buttonAccept]="fileButtonData.buttonAccept"
                        [urlFile]="fileButtonData.urlFile"
                        [paramsFile]="fileButtonData.paramsFile"
                        [showProgress]="fileButtonData.showProgress"
                        [token]="fileButtonData.token"
                        (eventUploadResponse)="onEventUploadResponse($event)"></fun-file>
                    </div>
                </section>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #externalSoldWaitingContractsKo>
    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.bid-receipt' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ lostResolutionName }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="done">
                                <span class="title"><strong>{{ 'offer-status.contact-buyer' | translate }}</strong></span>
                                <span class="mark mark-null">{{ 'offer-status.after-acceptance-bid' | translate }}</span>
                            </li>
                            <li class="selected" >
                                <span class="title"><strong>{{ 'offer-status.contract-upload' | translate }}</strong></span>
                                <span class="mark mark-null">{{ 'offer-status.before' | translate }} {{ offerData.external_waiting_documentation_due_date | date:'dd/MM/yyyy' }}</span>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'offer-status.contract-upload-ko-text' | translate }}</p>
                                </div>
                            </li>
                            <li>
                                <span class="title"><strong>{{ 'offer-status.change-ownership' | translate }}</strong></span>
                                <span class="mark mark-null">{{ 'offer-status.after-validity-check' | translate }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'offer-status.contract-upload' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'offer-status.contract-upload-ko-text' | translate }}</p>
                    </div>
                </section>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #externalSoldValidating>
    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.bid-receipt' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.assigned-offer' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="done">
                                <span class="title"><strong>{{ 'offer-status.contact-buyer' | translate }}</strong></span>
                                <span class="mark mark-null">{{ 'offer-status.after-acceptance-bid' | translate }}</span>
                            </li>
                            <li class="done item-clickable" (click)="changeToResendDocumentStatus()">
                                <span class="title"><strong>{{ 'offer-status.contract-upload' | translate }}</strong></span>
                                <span class="mark mark-null">{{ 'offer-status.uploaded' | translate }} {{ offerData.contract_seller_date | date:'dd/MM/yyyy' }}</span>
                                <p class="text-orange-100 item-clickable">{{ 'offer-status.resend-documents' | translate }}</p>
                            </li>
                            <li class="selected">
                                <span class="title"><strong>{{ 'offer-status.change-ownership' | translate }}</strong></span>
                                <span class="mark mark-null">{{ 'offer-status.after-validity-check' | translate }}</span>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'offer-status.contract-validating-text' | translate }}</p>
                                    <fun-new-button [buttonType]="btnSecondary" title="{{ 'offer-status.back-my-offers' | translate }}"
                                    (eventClick)="onEventClickRoute('my-offers')"></fun-new-button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'offer-status.change-ownership' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'offer-status.contract-validating-text' | translate }}</p>
                        <fun-new-button [buttonType]="btnSecondary" title="{{ 'WALLET_STATUS.PENDING.BTN' | translate }}" (eventClick)="contactByMail()"></fun-new-button>
                    </div>
                </section>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #externalSoldOk>
    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.bid-receipt' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.assigned-offer' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="done">
                                <span class="title"><strong>{{ 'offer-status.contact-buyer' | translate }}</strong></span>
                                <span class="mark mark-null">{{ 'offer-status.after-acceptance-bid' | translate }}</span>
                            </li>
                            <li class="done">
                                <span class="title"><strong>{{ 'offer-status.contract-upload' | translate }}</strong></span>
                                <span class="mark mark-null">{{ 'offer-status.uploaded' | translate }} {{ offerData.contract_seller_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title"><strong>{{ 'offer-status.change-ownership' | translate }}</strong></span>
                                <span class="mark mark-null">{{ 'offer-status.made-on' | translate }} {{ offerData.ok_date | date:'dd/MM/yyyy' }}</span>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'offer-status.ok-external-text' | translate:{'amount':offerData.amount, 'project_name':offerData.project_name} }}</p>
                                    <fun-new-button [buttonType]="btnAccept" title="{{ 'offer-status.view-my-projects' | translate }}"
                                    (eventClick)="onEventClickRoute('list-projects')"></fun-new-button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'offer-status.change-ownership' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'offer-status.ok-external-text' | translate:{'amount':offerData.amount, 'project_name':offerData.project_name} }}</p>
                        <fun-new-button [buttonType]="btnAccept" title="{{ 'offer-status.view-my-projects' | translate }}"
                        (eventClick)="onEventClickRoute('list-projects')"></fun-new-button>
                    </div>
                </section>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #externalSoldKo>
    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.bid-receipt' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ lostResolutionName }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="done">
                                <span class="title"><strong>{{ 'offer-status.contact-buyer' | translate }}</strong></span>
                                <span class="mark mark-null">{{ 'offer-status.after-acceptance-bid' | translate }}</span>
                            </li>
                            <li class="done">
                                <span class="title"><strong>{{ 'offer-status.contract-upload' | translate }}</strong></span>
                                <span class="mark mark-null">{{ 'offer-status.uploaded' | translate }} {{ offerData.contract_seller_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title"><strong>{{ 'offer-status.change-ownership' | translate }}</strong></span>
                                <span class="mark mark-null">{{ 'offer-status.cancel-on' | translate }} {{ offerData.ko_date | date:'dd/MM/yyyy' }}</span>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'offer-status.ko-external-text' | translate }}</p>
                                    <fun-new-button [buttonType]="btnAccept" title="{{ 'offer-status.contact-us' | translate }}"
                                    (eventClick)="onEventClickRoute('contact-us')"></fun-new-button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'offer-status.change-ownership' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'offer-status.ko-external-text' | translate }}</p>
                        <fun-new-button [buttonType]="btnAccept" title="{{ 'offer-status.contact-us' | translate }}"
                        (eventClick)="onEventClickRoute('contact-us')"></fun-new-button>
                    </div>
                </section>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #internalSoldWaitingBuyerSign>
    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.bid-receipt' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.assigned-offer' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list digital-way">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li [ngClass]="{'selected': offerData.signed_buyer === false && offerData.signed_seller === false,
                                'done': offerData.signed_buyer === true || offerData.signed_seller === true }">
                                <!--<span class="title">{{ 'offer-status.buyer-sign' | translate }}</span>-->
                                <span class="title">{{ 'offer-status.seller-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.before' | translate }} {{ offerData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'offer-status.sign-text-buyer' | translate }}</p>
                                    <fun-new-button [buttonType]="btnSecondary" title="{{ 'offer-status.back-my-offers' | translate }}"
                                     (eventClick)="onEventClickRoute('my-offers')"></fun-new-button>
                                </div>
                            </li>
                            <li [ngClass]="{'selected': offerData.signed_buyer === false && offerData.signed_seller === true,
                            'done': offerData.signed_buyer === true && offerData.signed_seller === true }">
                                <span class="title">{{ 'offer-status.buyer-sign' | translate }}</span>
                                <!--<span class="title">{{ 'offer-status.seller-sign' | translate }}</span>-->
                                <span class="mark mark-null">{{ 'offer-status.before' | translate }} {{ offerData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li [ngClass]="{'selected': offerData.signed_buyer === true && offerData.signed_seller === true }">
                                <span class="title">{{ 'offer-status.administrator-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.before' | translate }} {{ offerData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li>
                                <span class="title">{{ 'offer-status.payment' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.after' | translate }} {{ offerData.internal_waiting_payment_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li>
                                <span class="title">{{ 'offer-status.change-ownership' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.after-check-payment' | translate }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100" *ngIf="offerData.signed_buyer === false && offerData.signed_seller === true">{{ 'offer-status.buyer-sign' | translate }}</h3>
                        <h3 class="font-sans text-xl text-eggplant-100" *ngIf="offerData.signed_buyer === false && offerData.signed_seller === false">{{ 'offer-status.seller-sign' | translate }}</h3>
                        <h3 class="font-sans text-xl text-eggplant-100" *ngIf="offerData.signed_buyer === true && offerData.signed_seller === true">{{ 'offer-status.administrator-sign' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24" *ngIf="offerData.signed_buyer === false && offerData.signed_seller === true">{{ 'offer-status.sign-text-buyer' | translate }}</p>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24" *ngIf="offerData.signed_buyer === false && offerData.signed_seller === false">{{ 'offer-status.sign-text-seller' | translate }}</p>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24" *ngIf="offerData.signed_buyer === true && offerData.signed_seller === true">{{ 'offer-status.sign-text-administrator' | translate }}</p>
                        <fun-new-button [buttonType]="btnSecondary" title="{{ 'offer-status.back-my-offers' | translate }}"
                         (eventClick)="onEventClickRoute('my-offers')"></fun-new-button>
                    </div>
                </section>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #internalSoldWaitingSellerSign>
    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.bid-receipt' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.assigned-offer' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="done">
                                <span class="title">{{ 'offer-status.buyer-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.before' | translate }} {{ offerData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="selected">
                                <span class="title">{{ 'offer-status.seller-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.before' | translate }} {{ offerData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'offer-status.sign-text-seller' | translate }}</p>
                                </div>
                            </li>
                            <li>
                                <span class="title">{{ 'offer-status.payment' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.after' | translate }} {{ offerData.internal_waiting_payment_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li>
                                <span class="title">{{ 'offer-status.change-ownership' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.after-check-payment' | translate }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'offer-status.seller-sign' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'offer-status.sign-text-seller' | translate }}</p>
                    </div>
                </section>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #internalNotSoldWaitingSignKo>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.bid-receipt' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.offer-expired' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal">
                <section class="content-boxed row">
                    <div class="col-6 relance">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'offer-status.offer-resolution' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'offer-status.ko-sign-seller-text' | translate }}</p>
                        <fun-new-button [buttonType]="btnAccept" title="{{ 'offer-status.view-projects' | translate }}"
                        (eventClick)="onEventClickRoute('list-projects')"></fun-new-button>
                    </div>
                    <div class="col-6" *ngIf="offerData.signed_buyer === true && offerData.signed_seller === false">
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'offer-status.ko-sign-buyer-text' | translate }}</p>
                    </div>
                    <div class="col-6"></div>
                </section>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #internalSoldWaitingPayment>
    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.bid-receipt' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.assigned-offer' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list digital-way">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="done">
                                <span class="title">{{ 'offer-status.buyer-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.before' | translate }} {{ offerData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'offer-status.seller-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.before' | translate }} {{ offerData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'offer-status.administrator-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.before' | translate }} {{ offerData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="selected">
                                <span class="title">{{ 'offer-status.payment' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.after' | translate }} {{ offerData.internal_waiting_payment_due_date | date:'dd/MM/yyyy' }}</span>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'offer-status.payment-text' | translate }}</p>
                                    <fun-new-button [buttonType]="btnSecondary" title="{{ 'offer-status.back-my-offers' | translate }}"
                                    (eventClick)="onEventClickRoute('my-offers')"></fun-new-button>
                                </div>
                            </li>
                            <li>
                                <span class="title">{{ 'offer-status.change-ownership' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.after-check-payment' | translate }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'offer-status.payment' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'offer-status.payment-text' | translate }}</p>
                        <fun-new-button [buttonType]="btnSecondary" title="{{ 'offer-status.back-my-offers' | translate }}"
                         (eventClick)="onEventClickRoute('my-offers')"></fun-new-button>
                    </div>
                </section>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #internalSoldOk>
    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.bid-receipt' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.assigned-offer' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list digital-way">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="done">
                                <span class="title">{{ 'offer-status.buyer-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.before' | translate }} {{ offerData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'offer-status.seller-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.before' | translate }} {{ offerData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'offer-status.administrator-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.before' | translate }} {{ offerData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'offer-status.payment' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.after' | translate }} {{ offerData.internal_waiting_payment_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'offer-status.change-ownership' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.after-check-payment' | translate }}</span>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'offer-status.change-ownership-text' | translate }}</p>
                                    <fun-new-button [buttonType]="btnAccept" title="{{ 'offer-status.view-my-projects' | translate }}"
                                    (eventClick)="onEventClickRoute('list-projects')"></fun-new-button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'offer-status.change-ownership' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'offer-status.change-ownership-text' | translate:{'amount':offerData.amount, 'project_name':offerData.project_name} }}</p>
                        <fun-new-button [buttonType]="btnAccept" title="{{ 'offer-status.view-my-projects' | translate }}"
                        (eventClick)="onEventClickRoute('list-projects')"></fun-new-button>
                    </div>
                </section>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #internalSoldKo>
    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li>
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.bid-receipt' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li>
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'offer-status.until' | translate }} {{ offerData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-status.assigned-offer' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="done">
                                <span class="title">{{ 'offer-status.buyer-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.before' | translate }} {{ offerData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'offer-status.seller-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.before' | translate }} {{ offerData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'offer-status.payment' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.after' | translate }} {{ offerData.internal_waiting_payment_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'offer-status.change-ownership' | translate }}</span>
                                <span class="mark mark-null">{{ 'offer-status.after-check-payment' | translate }}</span>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'offer-status.change-ownership-ko-text' | translate }}</p>
                                    <fun-new-button [buttonType]="btnAccept" title="{{ 'offer-status.view-my-projects' | translate }}"
                                     (eventClick)="onEventClickRoute('list-projects')"></fun-new-button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'offer-status.change-ownership' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'offer-status.change-ownership-ko-text' | translate }}</p>
                        <fun-new-button [buttonType]="btnAccept" title="{{ 'offer-status.view-my-projects' | translate }}"
                         (eventClick)="onEventClickRoute('list-projects')"></fun-new-button>
                    </div>
                </section>
            </div>
        </div>
    </div>
</ng-template>

<div class="space-bottom"></div>