import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewContainerRef,
  ComponentRef,
} from '@angular/core';
import * as momentNs from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { MarketPlaceMyBidsModel } from 'src/app/shared/models/marketplace.models';
import { ToastService } from '../../toast/toast.service';
import { DateService } from 'src/app/services/date.service';
const moment = momentNs;

@Component({
  selector: 'fun-bid',
  templateUrl: './bid.component.html',
  styleUrls: ['./bid.component.scss']
})
export class BidComponent implements OnInit {

  @Output() private emitBidDetails = new EventEmitter<MarketPlaceMyBidsModel>();
  @Output() private eventClickViewProjects = new EventEmitter<void>();
  @Input() dataList$: Observable<MarketPlaceMyBidsModel[]>;

  dataList: MarketPlaceMyBidsModel[];
  dataListFiltered: MarketPlaceMyBidsModel[];
  isShow = false;
  idSelected: number;
  filterType: string;

  totalNomberGroup: number;
  totalN: number;
  activeN: number;
  wonN: number;
  lostN: number;
  soldwonN: number;
  activependingN: number;

  isEmpty = false;
  showMsg = false;
  notificationsNumber: number;

  projectList = [];
  projectListOpen = false;
  filterByP = '';

  orderBestShare = false;
  orderBestPrice = false;

  statusList = [{name: this.translate.instant('movements.all'), id: 'all'}];
  statusOpen = false;
  filterByS = '';

  loaded = false;

  constructor(
    private chdRef: ChangeDetectorRef,
    private toastService: ToastService,
    private translate: TranslateService,
    private dateService: DateService
    ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.getData();
  }

  public getData() {
    this.notificationsNumber = 0;
    const numberGroup = [0, 0, 0];

    this.dataList$.subscribe({
      next: data => {
        this.loaded = true;
        this.dataList = this.getInteractiveBids(data);
        this.getProjectList();
        //this.sortListByDate('desc', this.dataList);
        this.dataListFiltered = [...this.dataList];
        this.dataListFiltered.forEach(element => {
          if (element.decision_due_date !== undefined) {
            element.decision_due_date_msg = this.dateService.getDateMs(element.decision_due_date);
          }
        });
        this.totalN = this.dataList.length;
        this.activeN = this.dataList.filter(item => item.status === 'active').length;
        this.wonN = this.dataList.filter(item => item.status === 'won' && item.offer_status === 'sold').length;
        this.lostN = this.dataList.filter(item => item.status === 'lost' ||
        (item.status === 'won' && item.offer_status === 'not_sold')).length;
        this.soldwonN = this.dataList.filter(item => item.status === 'won' && item.offer_status === 'sold').length;
        this.activependingN = this.dataList.filter(item => item.status === 'active' && item.offer_status === 'pending_acceptation').length;

        if (this.notificationsNumber > 0 && !this.showMsg) {
          let toastText: string;
          if (this.notificationsNumber > 1) {
            toastText = this.translate.instant('toast-messages.attention-bids');
          } else {
            toastText = this.translate.instant('toast-messages.attention-bid');
          }
          this.showMsg = true;
          this.toastService.showToast(toastText, 'info', true);
        }

        if (this.activeN > 0) {
          numberGroup[0] = 1;
          this.statusList.push({name: this.translate.instant('movements.active'), id: 'active'});
        }
        if (this.wonN > 0) {
          numberGroup[1] = 1;
          this.statusList.push({name: this.translate.instant('movements.won'), id: 'won'});
        }
        if (this.lostN > 0) {
          numberGroup[2] = 1;
          this.statusList.push({name: this.translate.instant('movements.lost'), id: 'lost'});
        }
        this.totalNomberGroup = numberGroup.reduce((a, b) => a + b, 0);

        if (this.activeN > 0) {
          this.filterType = 'active';
        } else {
          if (this.totalNomberGroup > 1) {
            this.filterType = 'all';
          } else if (this.wonN > 0) {
            this.filterType = 'won';
          } else if (this.lostN > 0) {
            this.filterType = 'lost';
          }
        }

        this.dataListFiltered = this.orderList(this.dataListFiltered);
        this.dataList = this.dataListFiltered;
        //this.filterList(this.filterType);
        this.chdRef.detectChanges();
      },
      error: error => {
        this.loaded = true;
        this.isEmpty = true;
        this.totalN = 0;
        this.activeN = 0;
        this.wonN = 0;
        this.soldwonN = 0;
        this.lostN = 0;
        //this.filterList('active');
        this.chdRef.detectChanges();
      }
    });
  }

  toggleDisplay(id: number) {
    this.isShow = !this.isShow;
    this.idSelected = id;
  }

  filterList(type: string) {
    this.filterType = type;
    this.statusOpen = false;
    this.filterByP = '';

    if (type === 'all') {
      this.dataListFiltered = [...this.dataList];
      this.filterByS = '';
    } else {
      this.filterByS = type;
    }

    if (type === 'active') {
      this.dataListFiltered = this.dataList.filter(item => item.status === 'active');
    }

    if (type === 'won') {
      this.dataListFiltered = this.dataList.filter(item => item.status === 'won' && item.offer_status === 'sold');
    }

    if (type === 'lost') {
      this.dataListFiltered = this.dataList.filter(item => item.status === 'lost' ||
      (item.status === 'won' && item.offer_status === 'not_sold'));
    }
  }

  viewBidDetails(dataItem: MarketPlaceMyBidsModel) {
    this.emitBidDetails.emit(dataItem);
  }

  sortListByDate(type: string, arr) {
    arr.sort(function (a, b) {
      if (type === 'asc') {
        return a.id - b.id;
      }
      if (type === 'desc') {
        return b.id - a.id;
      }
    });
    return arr;
  }

  getInteractiveBids(data) {
    data.forEach(element => {
      if (((
          (element.offer_sold_status === 'internal_waiting_sign' && element.signed_buyer === false && element.signed_seller === true)
          || element.offer_sold_status === 'internal_waiting_payment')
          && element.offer_status === 'sold'
          && element.formalization_type === 'internal'
          && element.status === 'won') || (
          element.formalization_type === 'external'
          && element.offer_sold_status === 'external_waiting_contracts'
          && element.offer_status === 'sold'
          && element.status === 'won'
          && element.contract_buyer === false
        )) {
        element.notification = true;
        this.notificationsNumber++;
      } else {
        element.notification = false;
      }
    });
    return data;
  }

  onEventClick() {
    this.eventClickViewProjects.emit();
  }

  orderList(data) {
    const orderedData = [];
    const lastBids = [];
    data.forEach(element => {
      if (element.status === 'active') {
        orderedData.push(element);
      }
    });
    data.forEach(element => {
      if ((element.status !== 'active')  && ((
        (element.offer_sold_status === 'internal_waiting_sign' && element.signed_buyer === false && element.signed_seller === true)
        || element.offer_sold_status === 'internal_waiting_payment')
        && element.offer_status === 'sold'
        && element.formalization_type === 'internal'
        && element.status === 'won') || (
        element.formalization_type === 'external'
        && element.offer_sold_status === 'external_waiting_contracts'
        && element.offer_status === 'sold'
        && element.status === 'won'
        && element.contract_buyer === false
      )) {
        orderedData.push(element);
      } else {
        if (element.status !== 'active') {
          lastBids.push(element);
        }
      }
    });
    lastBids.forEach(element => {
      orderedData.push(element);
    });
    return orderedData;
  }

  getProjectList() {
    this.dataList.forEach(element => {
      const isInArray = this.projectList.includes(element.project_name);
      if (isInArray) {
        return;
      } else {
        this.projectList.push(element.project_name);
      }
    });
  }

  filterByProjects(value) {
    this.filterByS = '';
    this.projectListOpen = false;
    this.filterByP = value;
    let filterList = this.dataList;
    filterList = filterList.filter((item) => item.project_name === value);
    this.dataListFiltered = filterList;
  }

  toggleSelectProject() {
    this.statusOpen = false;
    this.projectListOpen = !this.projectListOpen;
  }

  toggleStatusProject() {
    this.projectListOpen = false;
    this.statusOpen = !this.statusOpen;
  }

  orderByShare() {
    this.orderBestShare = !this.orderBestShare;
    this.orderBestPrice  = false;
    if (!this.orderBestShare) {
      this.sortListByShare('asc', this.dataListFiltered);
    } else {
      this.sortListByShare('desc', this.dataListFiltered);
    }
  }

  orderByPrice() {
    this.orderBestPrice = !this.orderBestPrice;
    this.orderBestShare = false;
    if (!this.orderBestPrice) {
      this.sortListByPrice('asc', this.dataListFiltered);
    } else {
      this.sortListByPrice('desc', this.dataListFiltered);
    }
  }

  sortListByShare(type: string, arr) {
    arr.sort(function (a, b) {
      if (type === 'asc') {
        return a.offer_amount - b.offer_amount;
      }
      if (type === 'desc') {
        return b.offer_amount - a.offer_amount;
      }
    });
    return arr;
  }

  sortListByPrice(type: string, arr) {
    arr.sort(function (a, b) {
      if (type === 'asc') {
        return a.price - b.price;
      }
      if (type === 'desc') {
        return b.price - a.price;
      }
    });
    return arr;
  }

  reset() {
    this.filterByP = '';
    this.filterByS = '';
    this.orderBestShare = false;
    this.dataListFiltered = this.orderList(this.dataList);
  }
}
