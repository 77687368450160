import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  @Output() public scrollEvent = new EventEmitter<any>();

  constructor() { }

  transferScrollEvent(e) {
    this.scrollEvent.emit(e);
  }
}
