import { Component, OnInit, ViewChild, ElementRef, HostListener, ChangeDetectorRef, Input, EventEmitter, Output } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationExtras } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { File } from '@ionic-native/file/ngx';
import { ModalController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { DetailProjectService } from 'src/app/services/detail-project.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ListOfProjectsService } from 'src/app/services/list-of-projects.service';
import { OverviewProjectService } from 'src/app/services/overview-project.service';
import { AppConfigurationService } from 'src/app/services/app.configuration.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { DataLayerService } from 'src/app/services/data-layer.service';
import { HelpService } from 'src/app/services/help.service';
import { InvestCardService } from 'src/app/services/invest-card.service';
import { OffersService } from 'src/app/services/offers.service';
import { ProfileService } from 'src/app/services/profile.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Benefit, BenefitProject, BenefitsByMonth } from 'src/app/shared/models/benefits.models';
import { MarketPlaceOfferCreateModel, MarketPlaceProjectModel } from 'src/app/shared/models/marketplace.models';
import { environment } from 'src/environments/environment';
import { AlertService } from '../../alert/alert.service';
import { DocumentationListComponent } from '../../documentation-list/documentation-list.component';
import { ProjectDetailComponent } from '../../marketplace/project-detail/project-detail.component';
import { ModalService } from '../../modal/modal.service';
import { ToastService } from '../../toast/toast.service';
import { UpdateListComponent } from '../../update-list/update-list.component';
import { TranslateService } from '@ngx-translate/core';
import { SkeletonListComponent } from '../../skeleton/skeleton-list.component';
import { OveralMessageComponent } from '../../overal-message/overal-message.component';
import { InvestComponent } from '../../invest/invest.component';
import { WarningComponent } from '../../warning/warning.component';
import { InvestReservedComponent } from '../../invest/invest-reserved/invest-reserved.component';
import { InvestTestConvenienceComponent } from '../../invest/invest-test-convenience/invest-test-convenience.component';
import { BidCreateComponent } from '../../marketplace/bid-create/bid-create.component';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import * as momentNs from 'moment';
import { AffiliationLocalInvestComponent } from '../../affiliation-local-invest/affiliation-local-invest.component';
import { PageSubmenuComponent } from '../../page-submenu/page-submenu.component';
import { SubscribeProjectNewsComponent } from '../../subscribe-project-news/subscribe-project-news.component';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { HubspotService } from 'src/app/services/hubspot.service';
import { RenewSessionComponent } from '../../renew-session/renew-session.component';
import { BiometricService } from 'src/app/services/biometric.service';

const moment = momentNs;

@Component({
  selector: 'fun-new-detail-overview',
  templateUrl: './new-detail-overview.component.html',
  styleUrls: ['./new-detail-overview.component.scss'],
  providers: [File]
})
export class NewDetailOverviewComponent implements OnInit {

  //@ViewChild('about', { static: false }) about: ElementRef;
  @ViewChild('investors', { static: false }) investors: ElementRef;
  @ViewChild('consumer', { static: false }) consumer: ElementRef;
  @ViewChild('teamB', { static: false }) teamB: ElementRef;
  @ViewChild('teamM', { static: false }) teamM: ElementRef;
  @ViewChild('doc', { static: false }) doc: ElementRef;
  @ViewChild('map', { static: false }) map: ElementRef;
  @ViewChild('update', { static: false }) update: UpdateListComponent;
  @ViewChild('marketplaceRow', { static: false }) marketplaceRow: ProjectDetailComponent;
  @ViewChild('documentation', { static: false }) documentation: DocumentationListComponent;
  @ViewChild('summary', { static: false }) summary: ElementRef;
  @ViewChild('pageSubmenu', { static: false }) pageSubmenu: PageSubmenuComponent;
  @ViewChild('calculator', { static: false }) calculatorComponet: ElementRef;
  //@ViewChild('content', { static: false }) content: ElementRef;

  @Output() private closeModalEvent = new EventEmitter<void>();
  @Input() slug: any;
  @Input() public page = 'overview';
  @Input() public openProjectInvestmentProccess = false;

  public currentPage: string;
  isUserInvestor: any;
  id: any;
  project: any;
  isWalletOk: boolean;
  lastScrollTop = 0;
  hasScroll = false;
  iosNativeApp: boolean;
  isLoaded = false;
  hasDocuments = false;
  hasStatusaside = false;
  hasConsumer = false;
  headerInfo: any;
  aboutProjectInfo: any;
  investorsInfo: any;
  consumerInfo: any;
  constructorInfo: any;
  managementInfo: any;
  documents: any;
  statusAside: any;
  headerOffsetMenu: string;
  priceCapital: number;
  isInvestor = true;
  changeHeader = true;
  filter;
  typePay;
  nameUser;
  cuopon;

  elementList = [];

  lat: any;
  lon: any;
  mapText: any;
  zoom: any;

  projectMarketp: MarketPlaceProjectModel;
  hasMarketplace: boolean;
  public urlsRoute = ['list-of-projects', 'dashboard'];
  public urlService = 'apiservices/marketplace/v1/marketplace';

  loadedUpdates = false;
  updates: any;
  hasupdates: boolean;
  realUpdates: any;
  space: number;

  aboutSection: boolean;
  promoterSection: boolean;
  managerSection: boolean;

  profile$: any;
  detailProject: any;
  profile: any;
  available: boolean;
  alreadyInvested: any;
  investId: any;
  refCode: string
  statusP: string;
  projectPosition: any;
  canceledIds = [];
  finishedInvest = false;
  transactionId: any;
  public amount: any;
  public currentAmount: any;
  public isCreated = false;
  public activateInvest = environment.activateInvest;
  public activateMarketplace = environment.activateMarketplace;

  public positionIds = [];

  listOfFilter = [];
  defaultItemSelected = this.translate.instant('UPDATES.DATE');

  infoData: any;
  investPostalCodeStepsInfo: any;
  loadingMkp = false;
  pruchaseCardTimes = 0;
  pendingPromos = [];
  cancelableInvests = [];

  investProjectDocuments: any;
  benefitsByMonth: BenefitsByMonth[] = [];

  moneyToday: any;
  moneyEstimated: any;
  productionToday: any;
  productionEstimated: any;
  moneyAnnual: any;
  moneyAnnualEstimated: any;
  productionHistoric: any;
  moneyHistoric: any;
  lastUpdates: any;
  lastUpdate: any;
  contractSummary: string;
  hasContractSummary: boolean;

  isCnmUser = false;
  openedFromReadMore = false;
  walletStatus = -1;

  pageList = [
    {
      icon: 'briefcase',
      title: this.translate.instant('OVERVIEW.SUMMARY'),
      subtitle: this.translate.instant('OVERVIEW.SUMMARY_SUBTITLE'),
      id: 'overview',
      border: true
    },
  ];

  contributed_intention: number;
  user: any;
  checkedNoHasMonitoring = false;
  refreshModalOpened = false;

  secondaryDocsLits = [];
  hasHubspotProducts = false;
  projectDeal: any;
  updatingDeal = false;

  isFlexibleCompensation = false;

  constructor(
    private overviewService: OverviewProjectService,
    public translate: TranslateService,
    private localize: LocalizeRouterService,
    private navCtrl: NavController,
    private communicationService: CommunicationService,
    private modalService: ModalService,
    private utilsService: UtilsService,
    private offersService: OffersService,
    public location: Location,
    private storage: Storage,
    private appConfigurationService: AppConfigurationService,
    private authService: AuthService,
    private dataLayerService: DataLayerService,
    private toastService: ToastService,
    private listProjectsService: ListOfProjectsService,
    private title: Title,
    private meta: Meta,
    private profileService: ProfileService,
    private investCardService: InvestCardService,
    private utils: UtilsService,
    private alertService: AlertService,
    public chdRef: ChangeDetectorRef,
    private portfolioService: PortfolioService,
    private detailProjectService: DetailProjectService,
    private helpService: HelpService,
    private modalController: ModalController,
    private amplitudeService: AmplitudeService,
    private HubspotService: HubspotService,
    private biometricService: BiometricService
  ) { }

  ngOnInit() {
    this.getUserdata();
    this.startToLoadProject();
    this.iosNativeApp = Capacitor.getPlatform() === 'ios' ? true : false;
    this.storage.get('USERNAME').then((val) => {
      if (val === 'fundeen.cnmv@gmail.com') {
        this.isCnmUser = true;
      } else {
        this.storage.get('HS_CONTACT_ID').then((idhbp) => {
          if(idhbp === '' || idhbp === null) {
            // Buscamos en Hubspot el contacto asociado
            this.HubspotService.searchContact(val).subscribe((resHusbpot: any) => {
              if(resHusbpot.success) {
                const contacts = resHusbpot.data.results;
                if(contacts) {
                  this.storage.set('HS_CONTACT_ID', contacts[0].id);
                }
              }
            });
          }
        });
      }
    });

    this.translate.onLangChange.subscribe(event => {
      this.parseContractSummary();
      this.elementList = [];
      this.chdRef.detectChanges();
    });
  }

  async getUserdata() {
    await this.profileService.getUserData().subscribe(user => {
      this.user = user;
    });
  }

  startToLoadProject() {
    this.listOfFilter = [];
    this.elementList = [];
    this.isLoaded = false;
    if (isNaN(Number(this.slug))) {
      this.overviewService.getProjectDetailBySlug(this.slug).subscribe(proj => {
        this.operate(proj);
      });
    } else {
      this.overviewService.getProjectDetail(this.slug).subscribe(proj => {
        if (proj.status < 3) {
          this.navCtrl.navigateRoot([this.localize.translateRoute('/list-of-projects')]);
        } else {
          this.operate(proj);
        }
      });
    }
    this.storage.get('USERDATA').then((val) => {
      if (val.mailVerified === false) {
        this.profileService.verificationMailAdvice();
      }
    });
    this.overviewService.getPendingPromos().subscribe(resp => {
      this.pendingPromos = resp;
      this.chdRef.detectChanges;
    });
  }

  operate(project) {
    this.id = project.id;
    const flexibleCompensationIdStrings = this.translate.instant('RIC_ENERGY.IDS');
    const flexibleCompensationIdsArray = flexibleCompensationIdStrings.split(',').map(Number);
    this.isFlexibleCompensation = flexibleCompensationIdsArray.includes(this.id) && environment.enableRicenergyPortfolio ? true : false;
    this.detailProject = project;
    const status = project.status;
    if (status === 12 || status === 4 || status === 14 || status === 15) {
      this.statusP = 'En Financiación';
    } else if (status === 2 || status === 3) {
      this.statusP = 'Futuros';
    } else {
      this.statusP = 'Financiados';
    }
    if (this.detailProject.status === 3
      || this.detailProject.status === 4 || this.detailProject.status === 12|| this.detailProject.status === 14 || this.detailProject.status === 15) {
      this.setAvailable();
    }
    this.seeSpace();
    this.getProfile();
    this.getWalletType();
    this.projectPosition = '1';
    this.filter = 'open';
    this.currentPage = this.page; // 'overview'
    this.amplitudeService.trackEvent('project_detail', {
      project_name: this.detailProject.name,
      project_id: this.detailProject.id,
      status: this.detailProject.status
    });
    this.overviewService.currentPage = 'overview';
    this.storage.set('OVERVIEWPAGE', this.currentPage);
    this.getProjectMarketplace(this.id, 'overview');

    this.storage.get('ISINVESTOR').then((val) => {
      this.isUserInvestor = val;
    });
    this.listProjectsService.getProjectsList().subscribe(resp => {
      resp.forEach(element => {
        this.positionIds.push(element.id);
      });
    });

    // Obtenemos nombre y apellidos del profile
    this.storage.get('HS_SURNAME_USER').then((name) => {
      if(name === '' || name === null) {
        this.profile$.subscribe((resp) => {
          this.profile = resp;
          this.storage.set('HS_SURNAME_USER', `${this.profile.name} ${this.profile.surname}`);
        });
      }
    });

    // Obtenemos id del producto de Husbpot
    if (!this.hasHubspotProducts) {
      this.HubspotService.searchProduct(this.id).subscribe((resHusbpot: any) => {
        if(resHusbpot.success) {
          const products = resHusbpot.data.results;
          if(products) {
            this.storage.set('HS_PRODUCT_ID', products[0].id);
          }
          this.hasHubspotProducts = true;
        }
      });
    }
  }

  getWalletType() {
    this.storage.get('WALLET').then((val) => {
      if (val && val !== null && val !== undefined) {
        if (val.refCode && val.refCode !== null && val.refCode !== undefined) {
          this.refCode = val.refCode;
        }
      }
      if (val !== null && val !== undefined && val.walletstatus === 0) {
        this.isWalletOk = true;
      } else {
        this.overviewService.getWalletStatus().subscribe(
          (resp) => {
            this.walletStatus = resp.walletStatus;
            this.chdRef.detectChanges();
            if (resp.walletstatus === 0) {
              this.isWalletOk = true;
            } else {
              this.isWalletOk = false;
            }
          },
          (error) => {
            this.chdRef.detectChanges();
            this.isWalletOk = false;
            this.walletStatus = null;
          }
        );
      }
    });
  }
  @HostListener('window:resize', ['$event']) onResize(event) {
    this.seeSpace();
  }
  seeSpace() {
    if (window.innerWidth > 992) {
      this.space = 60;
    } else {
      this.space = 10;
    }
  }
  async setAvailable() {
    if (this.detailProject.status === 15) {
      this.overviewService.getReservationAvailable(this.id).subscribe(
        (resp) => {
          this.available = true;
          this.chdRef.detectChanges();
        },
        (error) => {
          this.available = false;
          this.chdRef.detectChanges();
        }
      );
    } else {
      this.overviewService.getInvestAvailable(this.id).subscribe(
        (resp) => {
          this.available = true;
          this.chdRef.detectChanges();
        },
        (error) => {
          this.available = false;
          this.chdRef.detectChanges();
        }
      );
    }
  }
  setPriceCapital() {
    return this.priceCapital;
  }
  async getProfile() {
    this.storage.get('USERNAME').then((val) => {
      this.profile$ = this.profileService.getProfile(val);
    });
  }
  async getProjectMarketplace(id, page) {
    this.overviewService.getMarketplaceInfo().subscribe(
      (resp) => {
        let i = 0;
        resp.forEach((element) => {
          const idP = parseInt(this.id);
          if (element.id === idP) {
            this.hasMarketplace = true;
            this.projectMarketp = element;
          } else {
            i++;
          }
        });
        if (i === resp.length) {
          this.priceCapital = undefined;
          this.setPriceCapital();
          this.hasMarketplace = false;
        } else {
          this.priceCapital = this.projectMarketp.price_capital;
          this.setPriceCapital();
          this.hasMarketplace = true;
        }
        this.getFullProject(id, page, this.hasMarketplace);
        this.chdRef.detectChanges();
      },
      (error) => {
        this.hasMarketplace = false;
        this.getFullProject(id, page, this.hasMarketplace);
        this.chdRef.detectChanges();
      }
    );
  }
  getFullProject(id, page, hasMp) {
    const projectLan = (this.detailProject.locale !== null && this.detailProject.locale !== undefined) ? this.getInfoTextLan(this.detailProject.locale) : undefined;
    if (projectLan) {
      this.setTitleMeta(projectLan.title, projectLan.metatitle);
    }
    if (this.detailProject.status === 12 || this.detailProject.status === 4 || this.detailProject.status === 14 || this.detailProject.status === 15) {
      this.statusP = 'En Financiación';
      this.getPostCodeAvailable();
    } else if (this.detailProject.status === 2 || this.detailProject.status === 3) {
      this.statusP = 'En Estudio';
      this.getPostCodeAvailable();
    } else {
      this.statusP = 'Financiado';
    }
    this.getBodyBproject(this.detailProject, id, page, hasMp);
    const projectInfoDataLayer = {
      name: 'view_item',
      params: {
        item_name: this.project.name,
        item_id: this.project.id.toString(),
        price: 500,
        item_brand: this.project.company,
        item_variant: 'Energía Solar',
        item_list_name: this.getProjectStatus(this.project.status),
        location_id: 'ES',
        item_category: this.project.profitability.toString(),
        item_category2: this.project.total.toString(),
        item_category3: this.project.term.toString(),
        index: this.projectPosition,
        quantity: 1
      },
    };
    this.dataLayerService.pushOnDataLayer(projectInfoDataLayer);
  }

  getProjectStatus(status) {
    if (status < 4) {
      return 'Futuros';
    }
    if (status === 4 || status === 12) {
      return 'En Financiación';
    }
    return 'Financiados';
  }

  setTitleMeta(title, meta) {
    if (title) {
      this.title.setTitle('Fundeen - ' + title);
      this.meta.updateTag({ property: 'og:title', content: title });
      this.meta.updateTag({ name: 'title', content: title });
    } else {
      this.title.setTitle('Fundeen - ' + this.detailProject.name);
      this.meta.updateTag({ property: 'og:title', content: 'Fundeen' });
      this.meta.updateTag({ name: 'title', content: 'Fundeen' });
    }
    if (meta) {
      this.meta.updateTag({ property: 'og:description', content: meta });
      this.meta.updateTag({ name: 'description', content: meta });
    } else {
      this.meta.updateTag({ property: 'og:description', content: 'Fundeen' });
      this.meta.updateTag({ name: 'description', content: 'Fundeen' });
    }
  }

  getBodyBproject(project, id, page, hasMp) {
    this.project = project;
    this.getHeaderInfo(project, page, hasMp);
    this.getAboutProjectInfo(project);
    this.getInvestorsInfo(project);
    this.getConsumerInfo(project);
    this.getConstructorInfo(project);
    this.getManagementInfo(project);
    this.overviewService.getStatusProject(id).subscribe((info) => {
      this.getStatusAside(project, info);
      this.chdRef.detectChanges();
    });
    this.overviewService.getProjectFiles(id).subscribe(
      (files) => {
        this.documents = files.documents;
        this.secondaryDocsLits = this.documents.filter((item) => item['type'] === 13);
        this.hasDocuments = true;
        this.elementList.push({
          name: 'Comisiones',
          id: 'commisions'
        });
        this.elementList.push({
          name: this.translate.instant('OVERVIEW.DOC'),
          id: 'doc',
        });
        this.parseContractSummary();
        this.getMapInfo(project.localization);
        this.chdRef.detectChanges();
      },
      (error) => {
        this.parseContractSummary();
        this.getMapInfo(project.localization);
        this.chdRef.detectChanges();
      }
    );
    this.isLoaded = true;
  }

  async parseContractSummary() {
    let hasSummary = false;
    this.hasContractSummary = false;
    this.contractSummary = this.project.locale;
    for (let i = 0; i < this.project.locale.length; i++) {
      if (this.project.locale[i].contract_summary !== null && this.project.locale[i].contract_summary !== undefined) {
        this.hasContractSummary = true;
        if (!hasSummary) {
          hasSummary = true;
          this.elementList.push({
            name: this.translate.instant('OVERVIEW.DOCUMENTATION.SUMMARY'),
            id: 'summary',
          });
        }
      }
    }
    if (this.hasContractSummary) {
      this.overviewService.getWalletStatus().subscribe(resp => {
        this.walletStatus = resp.walletstatus;
      }, error => {
        this.walletStatus = null;
      })
    }
  }

  async getHeaderInfo(project, cPage, hasMp) {
    this.overviewService.getUpdateProject(this.id).subscribe(
      (resp) => {
        this.updates = resp.reverse();
        this.getItems();
        this.hasupdates = true;
        this.setHeaderInfo(project, cPage, hasMp, this.hasupdates);
        this.chdRef.detectChanges();
      },
      (error) => {
        this.hasupdates = false;
        this.setHeaderInfo(project, cPage, hasMp, this.hasupdates);
        this.chdRef.detectChanges();
      }
    );
  }

  async setHeaderInfo(project, cPage, hasMp, hasupdates) {
    let prc;
    let image;
    if (this.setPriceCapital() && this.setPriceCapital() !== undefined) {
      prc = this.setPriceCapital();
    } else {
      prc = undefined;
    }
    if (project.imageMain) {
      image = project.imageMain;
    } else {
      image = undefined;
    }

    this.headerInfo = {
      hasMarketplace: hasMp,
      hasUpdates: hasupdates
    };
    if (hasupdates) {
      const el = {
        icon: 'clock',
        title: this.translate.instant('UPDATES.UPDATES'),
        subtitle: this.translate.instant('LIST_OF_PROJECTS.VIEW_NEWS'),
        id: 'news',
        border: true
      }
      this.pageList.push(el);
      this.chdRef.detectChanges();
    }
    if (hasMp) {
      const el = {
        icon: 'file',
        title: this.translate.instant('MOVEMENTS.NOTICEBOARD'),
        subtitle: this.translate.instant('LIST_OF_PROJECTS.MKP_LIST'),
        id: 'marketplace',
        border: true
      }
      this.pageList.push(el);
      this.chdRef.detectChanges();
    }
    this.loadedUpdates = true;
    this.checkDocumentation();
    this.checkPaymentCalendar();
  }

  getAboutProjectInfo(project) {
    let image: any;
    let txt: string;
    if (project.images && project.images !== undefined && project.images[0]) {
      image = project.images;
    } else if (project.imageCard) {
      image = [project.imageCard];
    } else if (project.imageMain) {
      image = [project.imageMain];
    }

    if (project.locale && project.locale !== undefined) {
      if (this.getInfoTextLan(project.locale)) {
        txt = this.getInfoTextLan(project.locale).description_long !== undefined ?  this.getInfoTextLan(project.locale).description_long :
        this.getInfoTextLan(project.locale).description !== undefined ? `<p>${this.getInfoTextLan(project.locale).description}</p>` : '';
      } else {
        txt = '';
      }
    } else {
      txt = '';
    }

    this.aboutSection = true;
    if (txt === null || txt === undefined || txt === '') {
      this.aboutProjectInfo = {
        id: project.id,
        text: '<p>Proximamente...</p>',
        imageUrl: image,
        status: this.detailProject.status
      };
    } else {
      this.aboutProjectInfo = {
        id: project.id,
        text: txt,
        imageUrl: image,
        status: this.detailProject.status
      };
    }

    this.elementList.push({
      name: this.translate.instant('OVERVIEW.ABOUT'),
      id: 'about',
    });

    if (this.aboutSection) {
      this.headerOffsetMenu = 'about';
    } else {
      this.headerOffsetMenu = 'investors';
    }
  }

  getInvestorsInfo(project) {
    let consumerMoney;
    let constructorMoney;
    let managerMoney;
    if (project.consumerPercentaje) {
      consumerMoney = project.consumerPercentaje;
    } else {
      consumerMoney = 0;
    }
    if (project.constructorPercentage) {
      constructorMoney = project.constructorPercentage;
    } else {
      constructorMoney = 0;
    }
    if (project.managerPercentage) {
      managerMoney = project.managerPercentage;
    } else {
      managerMoney = 0;
    }
    this.investorsInfo = {
      status: project.status,
      fundeenMoney: project.fundeen_percentage,
      consumer: consumerMoney,
      constructor: constructorMoney,
      manager: managerMoney,
      investorLevel: {
        first: project.inversors_profile.range_0.total,
        second: project.inversors_profile.range_1.total,
        third: project.inversors_profile.range_2.total,
        fourth: project.inversors_profile.range_3.total,
      },
    };
    this.elementList.push({
      name: this.translate.instant('OVERVIEW.INVESTORS'),
      id: 'investors',
    });
  }

  getConsumerInfo(project) {
    // De momento no existen datos de back para esto Aqui trataria el consumerInfo de la siguiente manera
    if (project.consumer) {
      this.hasConsumer = true;
      this.elementList.push({
        name: 'Consumidor',
        id: 'consumer',
      });
    }
    /*
      consumerInfo = {
    // Esta es la del promotor
    description: 'Espinosa Golf Design es una firma especializada en la realización de diseños y ' +
    'proyectos de campos de golf, con una experiencia de más de 45 años en la industria del golf y más de 40 campos de golf diseñados y/o proyectados. ',
    name: 'Espinosa Golf',
    location: 'Avila, España',
    image: 'https://fundeen-front-images.s3-eu-west-1.amazonaws.com/pro/projects/20/.jpg',
    document: {
      size: 125,
      name: 'información financiera',
      type: 4,
      url: 'https://service.fundeen.com/apiservices/project/v1/projects/20/files/11f41efa-4a9f-45a8-9872-1ef2e8325093'
    }
  };
    */
  }

  getConstructorInfo(project) {
    if (project.promoter && project.promoter !== undefined) {
      let webPage;
      if (project.promoter.web && project.promoter.web !== undefined) {
        webPage = project.promoter.web;
      } else {
        webPage = undefined;
      }
      this.promoterSection = true;
      this.constructorInfo = {
        description: project.promoter.description,
        name: project.promoter.name,
        location: project.promoter.location,
        web: webPage,
        responsible: {
          team: project.promoter.team,
          other_projects: project.promoter.other_projects,
        },
        image: project.promoter.image
      };
      this.elementList.push({
        name: this.translate.instant('OVERVIEW.TEAM_C'),
        id: 'teamB',
      });
    } else {
      this.promoterSection = false;
    }
  }

  getManagementInfo(project) {
    if (project.manager_team && project.manager_team !== undefined) {
      let webPage;
      if (project.manager_team.web && project.manager_team.web !== undefined) {
        webPage = project.manager_team.web;
      } else {
        webPage = undefined;
      }
      this.managerSection = true;
      this.managementInfo = {
        description: project.manager_team.description,
        location: project.manager_team.location,
        name: project.manager_team.name,
        web: webPage,
        responsible: {
          team: project.manager_team.team,
          other_projects: project.manager_team.other_projects,
        },
      };
      this.elementList.push({
        name: this.translate.instant('OVERVIEW.TEAM_M'),
        id: 'teamM',
      });
    } else {
      this.managerSection = false;
    }
  }

  getStatusAside(project, info) {
    if (project.status === 15) {
      this.overviewService.getTotalProjectreservedByUser(this.id).subscribe(userRes => {
        if (userRes.length > 0) {
          userRes.forEach(element => {
            element['invested'] = element.amount;
            element['date'] = element.creation_date;
          });
          this.getAsideData(project, info, userRes);
        } else {
          this.getAsideData(project, info);
        }
        this.chdRef.detectChanges();
      }, error => {
        this.getAsideData(project, info);
      });
    } else {
      this.overviewService.getHasInvest(this.id).subscribe(
        (resp) => {
          this.isInvestor = true;
          this.checkProduction();
          if (project.status !== 4 && project.status !== 12 && project.status !== 5) {
            this.getAsideData(project, info, resp);
          } else {
            this.overviewService.getPreviousInvestList(this.id).subscribe(response => {
              this.cancelableInvests = response.filter((item) => item['isCancelable'] === true);
              const canCancel = this.cancelableInvests.length > 0 ? true : false;
              this.getAsideData(project, info, resp, canCancel);
            }, error => {
              this.getAsideData(project, info, resp);
            });
          }
          this.chdRef.detectChanges();
        },
        (error) => {
          this.getAsideData(project, info);
          this.isInvestor = false;
          this.chdRef.detectChanges();
        }
      );
    }
  }

  getAsideData(project, info, invested?, showCancelinvestBtn?) {
    this.alreadyInvested = invested;
    this.statusAside = {
      total: info.objective,
      power: project.power,
      status: project.status,
      contributed: info.contributed,
      id: project.id,
      end_date: project.end_date,
      start_date: project.start_date,
      investors: project.inversors_profile.range_0.total + project.inversors_profile.range_1.total + project.inversors_profile.range_2.total + project.inversors_profile.range_3.total,
      totalProfit: project.totalProfit,
      revenueCalculatorMultiplier: project.revenueCalculatorMultiplier,
      co2: project.co2,
      term: project.term,
      activateInvest: this.activateInvest,
      ticketM: info.averageInvestment,
      prevInvest: invested,
      end_build_date: project.end_build_date,
      hasMarketplace: this.hasMarketplace,
      available: this.available,
      start_build_date: project.start_build_date,
      start_util_date: project.start_util_date,
      end_util_date: project.end_util_date,
      co2kwh: project.co2kwh,
      postalCodeToInvest: project.investment_by_postal_code_enabled,
      postalCodeDate: project.investment_unlock_date_by_user_postal_code,
      postalCodeCanInvest: project.user_can_invest_by_postal_code_now,
      canCancel: showCancelinvestBtn,
      max_allowed_amount_to_invest_for_user: project.max_allowed_amount_to_invest_for_user,
      capital_increase_amount: project.capital_increase_amount,
      capital_increase_private_end_date: project.capital_increase_private_end_date,
      profitability: project.profitability,
      hasDocuments: this.hasDocuments,
      type: this.project.type,
      slug: this.detailProject.slug
    };
    if (this.detailProject.status === 15) {
      this.overviewService.getTotalReservedAmount(this.id).subscribe(resp => {
        this.statusAside['contributed'] = resp;
        this.hasStatusaside = true;
        this.chdRef.detectChanges();
      }, error => {
        this.hasStatusaside = true;
        this.chdRef.detectChanges();
      });
    } else {
      if (this.detailProject.status === 4 || this.detailProject.status === 12) {
        this.overviewService.getTotalReservedAmount(this.id).subscribe(resp => {
          this.statusAside['contributed_intention'] = resp;
          this.contributed_intention = resp;
          this.hasStatusaside = true;
        }, error => {
          this.statusAside['contributed_intention'] = 0;
          this.contributed_intention = 0;
          this.hasStatusaside = true;
        });
      } else {
        this.hasStatusaside = true;
      }
    }
    this.chdRef.detectChanges();
  }

  getMapInfo(project) {
    this.zoom = 16;
    this.mapText = project.label;
    this.lat = project.lat;
    this.lon = project.lng;
    this.elementList.push({
      name: this.translate.instant('OVERVIEW.LOCATION'),
      id: 'map',
    });
  }

  getItems() {
    const financing = this.updates.filter((item) => item.category === 1);
    const construction = this.updates.filter((item) => item.category === 2);
    const operation = this.updates.filter((item) => item.category === 3);
    const dividend = this.updates.filter((item) => item.category === 4);
    const others = this.updates.filter((item) => (item.category === 5 || item.category === 0));

    this.listOfFilter.push(this.translate.instant('UPDATES.DATE'));
    if (financing.length > 0) {
      this.listOfFilter.push(this.translate.instant('UPDATES.FINANCING'));
    }
    if (construction.length > 0) {
      this.listOfFilter.push(this.translate.instant('UPDATES.CONSTRUCTION'));
    }
    if (operation.length > 0) {
      this.listOfFilter.push(this.translate.instant('UPDATES.OPERATION'));
    }
    if (dividend.length > 0) {
      this.listOfFilter.push(this.translate.instant('UPDATES.DIVIDEND'));
    }
    if (others.length > 0) {
      this.listOfFilter.push(this.translate.instant('UPDATES.OTHERS'));
    }
    this.realUpdates = this.updates;
  }

  getInfoTextLan(locale) {
    return locale.find((o) => o.language === this.translate.currentLang);
  }

  controlAsideStyle(evt) {
    const elementList = [ this.investors, this.consumer, this.teamB, this.teamM, this.doc, this.summary, this.map];
    const elList = [];
    elementList.forEach((element) => {
      if (element.nativeElement.offsetTop <= evt.detail.scrollTop + element.nativeElement.clientHeight / 4) {
        elList.unshift(element);
      }
    });
    const el = elList[0];
    if (el && el.nativeElement) {
      this.headerOffsetMenu = el.nativeElement.id;
    }
  }

  completeProfile(modalParams, profile, operation) {
    let i = 0;
    this.modalService.removeModal();
    this.authService.completeProfile(profile, modalParams, operation);
    this.authService.readyToInvestEvent.subscribe(() => {
      if (i < 1) {
        this.openInvestModal();
        i++;
      }
    });
  }

  openInvestModal() {
    this.amount = '3000';
    this.checkoutDataLayer(this.detailProject, this.amount, 1);
    if (this.available) {
      this.storage.get('WALLET').then((val) => {
        if ((val !== null && val !== undefined && val.walletstatus === 0) || this.isWalletOk) {
          this.getInvestInfo();
        } else {
          this.modalService.showModal(SkeletonListComponent, {}, 'Invertir').subscribe((modal: any) => {
            modal.getChildComponent().subscribe((componentRef: SkeletonListComponent) => {
              this.profile$.subscribe((resp) => {
                const params = this.profileService.profileStepsToInvest(resp, this.detailProject.type);
                const modalParams = {
                  profile: resp,
                  list: params['stepList'],
                  towns: {list: undefined, call: this.profileService},
                  updateSpecificDocument: true
                };
                if (params['addNie']) modalParams['addSpecificNie'] = true;
                this.completeProfile(modalParams, resp, 'invest');
              }, error => {
                this.profileService.getUserData().subscribe(response => {
                  const prof = {
                    mailVerified: response.enabled,
                    mobileVerified: response.mobileVerified,
                    username: response.username,
                    mobile: response.mobile,
                    profileType: response.profileType,
                  }
                  this.profile = prof;

                  const params = this.profileService.profileStepsToInvest(prof, this.detailProject.type);
                  const modalParams = {
                    profile: prof,
                    list: params['stepList'],
                    towns: {list: undefined, call: this.profileService},
                    updateSpecificDocument: true
                  };
                  if (params['addNie']) modalParams['addSpecificNie'] = true;
                 this.completeProfile(modalParams, prof, 'invest');
                });
              });
            });
          });
        }
      });
    } else {
      let modalParams;
      if (this.project.status === 12 || this.project.status === 14) {
        modalParams = {
          text: this.project.status === 12 ? this.translate.instant('MESSAGES.RECURRENT_INVESTORS') : this.translate.instant('MESSAGES.CAPITAL_INCREASE'),
          image: 2
        };
      } else {
        modalParams = {
          text: this.translate.instant('MESSAGES.CLOSED'),
          image: 1
        };
      }
      this.modalService.showModal(OveralMessageComponent, modalParams, 'Invertir').subscribe((modal: any) => {
        modal.onClose().subscribe();
      });
    }
  }

  getInvestInfo() {
    this.modalService.showModal(SkeletonListComponent, {}, 'Invertir').subscribe((modal: any) => {
      modal.getChildComponent().subscribe((componentRef: SkeletonListComponent) => {
        this.profile$.subscribe((resp) => {
          this.profile = resp;
          const params = this.profileService.profileStepsToInvest(resp, this.detailProject.type);
          if (params['stepList'].length === 0) {
            if (!this.profile.mailVerified) {
              this.modalService.removeModal();
              this.verificationMailAdvice(this.profile.username);
            } else {
              let questions;
              let balance;
              if (
                (this.project.type === 1 && !this.profile.convenienceTestShares && this.detailProject.status !== 15) ||
                (this.project.type === 2 && !this.profile.convenienceTestLoan && this.detailProject.status !== 15)
              ) {
                this.getQuestions(this.project.type).subscribe((response) => {
                  questions = response;
                  this.overviewService.getBalance().subscribe((blc) => {
                    balance = blc;
                    this.seeInvestAvailable(questions, this.profile, balance);
                  });
                });
              } else {
                questions = undefined;
                this.overviewService.getBalance().subscribe((blc) => {
                  balance = blc;
                  this.seeInvestAvailable(questions, this.profile, balance);
                });
              }
            }
          } else {
            const modalParams = {
              profile: resp,
              list: params['stepList'],
              towns: {list: undefined, call: this.profileService},
              updateSpecificDocument: true
            };
            if (params['addNie']) modalParams['addSpecificNie'] = true;
            this.completeProfile(modalParams, resp, 'invest');
          }
        }, error => {});
      });
      modal.onClose().subscribe();
    });
  }
  getQuestions(type) {
    if (type === 1) {
      return this.overviewService.getQuestionsShares();
    }
    return this.overviewService.getQuestionsLoan();
  }
  getCurrentValue(evt) {
    this.currentAmount = evt;
    this.amount = evt;
  }

  seeInvestAvailable(questions, profile, balance) {
    if (this.detailProject.user_can_invest_by_postal_code_now !== false) {
      this.overviewService.getProjectsInvest(this.id).subscribe((resp) => {
        const missing = this.project.total - resp.contributed - resp.reserved;
        if (missing === 0 && resp.reserved === 0) {
          this.modalService.removeModal();
        } else {
          let reserved = [];
          // Mirar el internal id
          this.overviewService.getQueue(this.id).subscribe(response => {
            const reserved_investments = response.reserved_investments;
            if (reserved_investments.length > 0) {
              reserved = reserved_investments.filter((item) => item.response_id === this.user.internalId);
            }
            if (missing === 0 && resp.reserved > 0 && reserved.length === 0) {
              this.noInvestReserved();
            } else {
              this.profileService.getPromoCodeInfo().subscribe(resp => {
                this.investProcess(questions, profile, balance, resp, reserved);
              }, error => {
                const refInfo = {
                  isFirstInvestment: false,
                  promotionalCode: ''
                }
                this.investProcess(questions, profile, balance, refInfo, reserved);
              });
            }
          }, error => {
            if (missing === 0 && resp.reserved > 0 && reserved.length === 0) {
              this.noInvestReserved();
            } else {
              this.profileService.getPromoCodeInfo().subscribe(resp => {
                this.investProcess(questions, profile, balance, resp, reserved);
              }, error => {
                const refInfo = {
                  isFirstInvestment: false,
                  promotionalCode: ''
                }
                this.investProcess(questions, profile, balance, refInfo, reserved);
              });
            }
          });
        }
      });
    } else {
      this.modalService.removeModal();
      this.openInfoModal();
    }
  }

  investProcess(questions, profile, balance, refInfo, reserved) {
    const allowedAmount = (this.detailProject.allowedAmountModules !== undefined) ? this.detailProject.allowedAmountModules : '';
    const economicR = (this.detailProject.participations_with_economic_rights !== null && this.detailProject.participations_with_economic_rights !== undefined && this.detailProject.status !== 14) ? this.detailProject.participations_with_economic_rights : undefined;
    this.modalService.removeModal();
    this.documents = this.documents !== null && this.documents !== undefined ? this.documents : [];
    const image = this.detailProject.imageMain !== null && this.detailProject.imageMain !== undefined ? this.detailProject.imageMain : this.detailProject.imageCard;
    const info = {
      headerInfo: {
        profitability: this.detailProject.profitability,
        name: this.detailProject.name,
        end_date: this.detailProject.end_date,
        term: this.detailProject.term,
        objective: this.detailProject.total,
        contributed: this.statusAside.contributed,
        invested: this.alreadyInvested,
        capital_increase_amount: this.detailProject.capital_increase_amount,
        capital_increase_private_end_date: this.detailProject.capital_increase_private_end_date,
        status: this.detailProject.status,
        projectImg: image,
        contributed_intention: this.contributed_intention
      },
      bodyInfo: {
        hasWallet: true,
        convenienceTestShares: profile.convenienceTestShares,
        convenienceTestLoan: profile.convenienceTestLoan,
        prjectType: this.project.type,
        investmentMaximumLoan: profile.investmentMaximumLoan,
        investmentMinimum: profile.investmentMinimum,
        balance: balance.balance,
        moneyOutBalance: balance.moneyOutBalance,
        identityDocumentType: profile.identityDocumentType,
        term: this.detailProject.term,
        co2: this.detailProject.co2,
        total: this.detailProject.total,
        contributed: this.statusAside.contributed,
        power: this.project.power,
        co2kwh: this.project.co2kwh,
        allowedAmountModules: allowedAmount,
        isFirstInvestment: refInfo.isFirstInvestment,
        promotionalCode: refInfo.promotionalCode,
        economicRights: economicR,
        pendingP: this.pendingPromos,
        investCommitment: false,
        max_allowed_amount_to_invest_for_user: this.detailProject.max_allowed_amount_to_invest_for_user,
        status: this.detailProject.status,
        reserved_list: reserved,
        mangopayUserId: profile.mangopayUserId || undefined,
        postalCodes: this.detailProject.allowedPostalCodes || undefined,
        slug: this.detailProject.slug,
        isFlexibleCompensation: this.isFlexibleCompensation
      },
      investInfo: {
        username: profile.username,
        wallet: profile.walletid,
        id: this.id,
        refCode: this.refCode
      },
      spanInfo: {
        company: this.detailProject.company,
        location: this.detailProject.location,
        position: this.projectPosition
      },
      signDocuments: this.documents.filter((item) => item.type === 10 || item.type === 11),
      projectDeal: this.projectDeal
    };
    this.amount = this.amount === 0 ? '' : this.amount;
    const localInvest = this.detailProject.status !== 14 && this.detailProject.participations_with_economic_rights !== null && this.detailProject.participations_with_economic_rights !== undefined ? true : false;
    const modalParams = { data: info, test: questions, amount: this.amount, localInvestProject: localInvest, profile: profile };
    const invest = this.detailProject.status !== 15 ? this.detailProject.status === 14 ? this.translate.instant('OVERVIEW.CAPITAL_INCREASE.TITLE') : this.translate.instant('MESSAGES.INVEST') : this.translate.instant('FORMALITIES.RESERVE_PROCESS.RESERVE');
    let hasInvested = false;
    this.modalService.showModal(InvestComponent, modalParams, invest).subscribe((modal: any) => {
      this.modalService.setPageTitle('Fundeen - Invertir');
      this.checkoutDataLayer(this.detailProject, this.amount, 2);
      modal.notCloseEvent.subscribe((x) => {
        const modalParams = {
          place: 'invest',
        };
        this.alertService.showModal(WarningComponent, modalParams, '', '', true).subscribe((modal: any) => {
          const cancel = this.translate.instant('MESSAGES.CANCEL');
          modal.setTitle(cancel);
          modal.getChildComponent().subscribe((componentRef: WarningComponent) => {
            componentRef.continueClick.subscribe((res) => {
              this.overviewService.getCancelInvest(this.investId).subscribe((x) => {
                this.canceledIds.push(this.investId);
                this.amplitudeService.trackEvent('invest_cancelled', { msg: 'Inversion cancelada' });
                const model = {
                  project_name: this.detailProject.name,
                  project_id: this.detailProject.id
                };
                this.amplitudeService.trackEvent('download_project_documentation', model);
                this.changeHuspotStage('cart_abandoned');
              });
              this.alertService.removeModal();
              this.modalService.removeModal();
              if (this.currentAmount === '' || this.currentAmount === null) {
                this.amount = '';
              }
            });
          });
          modal.onClose().subscribe();
        });
      });
      modal.getChildComponent().subscribe((componentRef: InvestComponent) => {
        componentRef.cancelInvestEvent.subscribe((resp) => {
          this.canceledIds.push(this.investId);
          modal.changeOpenStatus(false);
        });
        componentRef.keepOpenEvent.subscribe((x) => {
          modal.changeOpenStatus(true);
        });
        componentRef.amountValueEvent.subscribe(value => {
          //this.getCurrentValue(value);
        });
        componentRef.notKeepopenEvent.subscribe((x) => {
          modal.changeOpenStatus(false);
        });
        componentRef.shareTestEvent.subscribe((resp) => {
          this.shareConvenienceTest(resp, profile);
        });
        componentRef.investEvent.subscribe((params) => {
          this.amount = params.model.amount;

          this.checkoutDataLayer(this.detailProject, this.amount, 4);
          const model = params.model;
          model.application = 'app';
          const id = params.id;
          this.overviewService.invest(model, id).subscribe(
            (resp) => {
              this.investId = resp.transaction.id;
              componentRef.setInvestId(this.investId);
              // guardar resp.id para poder cancelar la llamada
              const evironmentUrlLemonway = environment.endPointLW;
              const c = { response: resp, urlLw: evironmentUrlLemonway };
              componentRef.getInvestResponse(c);
            },
            (error) => {
              const errors = [];
              if (error.error.errors) {
                error.error.errors.forEach((error) => {
                  errors.push(error);
                });
              }
              if (error.status === 400) {
                errors.push(400);
              }
              componentRef.getErrors(errors);
            }
          );
        });

        componentRef.investByWalletEvent.subscribe((resp) => {
          this.finishedInvest = true;
          this.transactionId = resp.id;
          let timeOut = false;
          let isSuccessInvest;
          let idCanceled = false;

          this.canceledIds.forEach(element => {
            if (element === resp.id) {
              idCanceled = true;
            }
          });

          if (!idCanceled) {
            this.overviewService.finishInvest(resp.id).subscribe(
              (response) => {
                isSuccessInvest = true;
                timeOut = true;
                hasInvested = true;
                componentRef.confirmInvest(true);
              },
              (error) => {
                isSuccessInvest = false;
              }
            );
            setTimeout(() => {
              if (!timeOut && (!isSuccessInvest || isSuccessInvest === undefined)) {
                this.investCardService.getInfoMovement(resp.id).subscribe(() => {
                  hasInvested = true;
                  componentRef.confirmInvest(true);
                }, error => {
                  componentRef.confirmInvest(false);
                });
              }
            }, 180000);
          }
        });
        componentRef.signaturitIssueEvent.subscribe(resp => {
          this.checkoutDataLayer(this.detailProject, this.amount, 6);
        });
        componentRef.goToHomeEvent.subscribe(() => {
          this.navCtrl.navigateRoot([this.localize.translateRoute('/portfolio')]);
          this.modalService.removeModal();
        });
        componentRef.newInvestEvent.subscribe(() => {
          this.startToLoadProject();
          this.modalService.removeModal();
        });
        componentRef.investmentQueueEvent.subscribe((resp) => {
          this.noInvestReserved();
        });
        componentRef.getPayTypeEvent.subscribe((resp) => {
          this.typePay = resp.methos;
          this.checkoutDataLayerPayType(this.detailProject, resp.cashForm.value.cash, 3, resp.methos);
        });
        componentRef.getCodePromotionEvent.subscribe((resp) => {
          this.cuopon = resp;
          this.storage.remove('PROMO');
          this.storage.set('PROMO', resp);
        });
        componentRef.startSignEvent.subscribe((resp) => {
          this.checkoutDataLayerStartSign(this.detailProject, this.amount, 5, this.typePay);
        });
        componentRef.confirmInvestWalletEvent.subscribe((resp) => {
          this.storage.get('NAME').then((val) => {
            this.nameUser = val;
          });
          this.checkoutDataLayerInvestWalletOK(this.detailProject, this.amount, resp, this.cuopon, this.nameUser, this.typePay);
        });
        componentRef.purchaseEvent.subscribe((resp) => {
          this.checkoutDataLayerInvestCardOK(resp);
        })
      });
      modal.closeEvent.subscribe(() => {
        if (this.currentAmount === '' || this.currentAmount === null) {
          this.amount = '';
        }
        this.startToLoadProject();
      });
      modal.onClose().subscribe();
    });
  }

  noInvestReserved() {
    this.modalService.removeModal();
    const data$ = this.overviewService.getQueue(this.id);
    const modalParams = {
      data: data$
    };
    this.alertService.showModal(InvestReservedComponent, modalParams, 'Invertir').subscribe((modal: any) => {
      this.modalService.setPageTitle('Fundeen - Invertir');
      modal.getChildComponent().subscribe((componentRef: InvestReservedComponent) => {
        componentRef.cancelEvent.subscribe((x) => {
          this.alertService.removeModal();
        });
        componentRef.continueEvent.subscribe((x) => {
          this.getInvestInfo();
          this.alertService.removeModal();
        });
      });
      modal.onClose().subscribe();
    });
  }

  viewProjects(value) {
    const navigationExtras: NavigationExtras = {
      state: {
        id: value.id,
        page: 'overview',
      },
    };
    this.storage.set('LASTPAGE', navigationExtras);
    const slugOrId = value.slug && value.slug !== undefined && value.slug !== undefined ? value.slug : value.id;
    this.navCtrl.navigateRoot([this.localize.translateRoute(`/overview-project/${slugOrId}`)], navigationExtras);
  }

  viewNavclick(event) {
    this.currentPage = event;
    if (event === 'monitoring' && (this.lastUpdate > 2 || (this.lastUpdate === undefined && this.checkedNoHasMonitoring))) {
      const modalParams = {
        text: this.translate.instant('OVERVIEW.NO_PROD_DATA'),
        btnList: [
          {
            type: 'primaryDark',
            index: 1,
            title: this.translate.instant('MESSAGES.BACK'),
          }
        ]
      };
      this.alertService.showModal(OveralMessageComponent, modalParams, '', '', true).subscribe((modal: any) => {
        modal.getChildComponent().subscribe((componentRef: OveralMessageComponent) => {
          componentRef.btnEvent.subscribe((resp) => {
            this.alertService.removeModal();
            this.viewNavclick('overview');
          });
        });
        modal.onClose().subscribe();
      });
    }
    this.amplitudeService.trackEvent('project_detail', {
      project_name: this.detailProject.name,
      project_id: this.detailProject.id,
      status: this.detailProject.status
    });
  }

  controlAsideBtn(evt) {
    switch (evt) {
      case 'offer':
        this.createOffer();
        break;
      case 'updates':
        this.currentPage = 'news';
        this.amplitudeService.trackEvent('project_detail', {
          project_name: this.detailProject.name,
          project_id: this.detailProject.id,
          status: this.detailProject.status
        })
        break;
      case 'marketplace':
        this.currentPage = 'marketplace';
        this.amplitudeService.trackEvent('project_detail', {
          project_name: this.detailProject.name,
          project_id: this.detailProject.id,
          status: this.detailProject.status
        })
        break;
      case 'invest':
        if (!this.authService.isRefreshExpired()) {
          this.openInvestModal();
          this.changeHuspotStage('add_to_cart');
        } else if(!this.authService.isMobileExpired()) {
          // Abro el alert y luego le mando al open de arriba
          this.renewSession('invest');
          // this.authService.refreshLoginevent.subscribe((action) => {
          //   if (action === 'invest') {
          //     //this.openInvestModal();
          //   }
          // });
        }
        break;
      case 'call':
        this.stayInformed();
        break;
    }
  }

  returntNav() {
    this.navCtrl.navigateRoot([this.localize.translateRoute('/list-of-projects')]);
  }

  public createBid(createBidToOfferData: MarketPlaceOfferCreateModel) {
    this.modalService.showModal(SkeletonListComponent, {}, 'Invertir').subscribe((modal: any) => {
      modal.getChildComponent().subscribe((componentRef: SkeletonListComponent) => {
        this.profile$.subscribe((resp) => {
          const params = this.profileService.profileStepsToInvest(resp, this.detailProject.type);
          if (params['stepList'].length === 0) {
            this.modalService.removeModal();
            if (!this.authService.isRefreshExpired()) {
              this.actionBid(createBidToOfferData, resp);
            } else if(!this.authService.isMobileExpired()) {
              this.renewSession('createBid');
              // this.authService.refreshLoginevent.subscribe((action) => {
              //   if (action === 'createBid') {
              //     this.actionBid(createBidToOfferData, resp);
              //   }
              // });
            }
          } else {
            const modalParams = {
              profile: resp,
              list: params['stepList'],
              towns: {list: undefined, call: this.profileService},
              updateSpecificDocument: true
            };
            if (params['addNie']) modalParams['addSpecificNie'] = true;
            this.completeProfile(modalParams, resp, 'bid');
            this.authService.readyToBid.subscribe(() => {
              if (!this.authService.isRefreshExpired()) {
                this.actionBid(createBidToOfferData, resp);
              } else if(!this.authService.isMobileExpired()) {
                this.renewSession('createBid');
                // this.authService.refreshLoginevent.subscribe((action) => {
                //   if (action === 'createBid') {
                //     this.actionBid(createBidToOfferData, resp);
                //   }
                // });
              }
            });
          }
        }, error => {
          this.profileService.getUserData().subscribe(response => {
            const prof = {
              mailVerified: response.enabled,
              mobileVerified: response.mobileVerified,
              username: response.username,
              mobile: response.mobile,
              profileType: response.profileType,
            }
            this.profile = prof;

            const params = this.profileService.profileStepsToInvest(prof, this.detailProject.type);
            const modalParams = {
              profile: prof,
              list: params['stepList'],
              towns: {list: undefined, call: this.profileService},
              updateSpecificDocument: true
            };
            if (params['addNie']) modalParams['addSpecificNie'] = true;
           this.completeProfile(modalParams, prof, 'bid');
           this.authService.readyToBid.subscribe(() => {
            if (!this.authService.isRefreshExpired()) {
              this.actionBid(createBidToOfferData, prof);
              } else if(!this.authService.isMobileExpired()) {
                this.renewSession('createBid');
                // this.authService.refreshLoginevent.subscribe((action) => {
                //   if (action === 'createBid') {
                //     this.actionBid(createBidToOfferData, prof);
                //   }
                // });
              }
            });
          });
        });
      });
    });
  }

  actionBid(createBidToOfferData: MarketPlaceOfferCreateModel, profile) {
    if ((this.project.type === 1 && !profile.convenienceTestShares) || (this.project.type === 2 && !profile.convenienceTestLoan)) {
      this.modalService.showModal(SkeletonListComponent, {}, 'Invertir').subscribe(() => {
        this.getQuestions(this.project.type).subscribe((response) => {
          this.modalService.removeModal();
          this.modalService.showModal(SkeletonListComponent, {}, 'Invertir').subscribe(() => {
            this.getQuestions(this.project.type).subscribe((response) => {
              const modalParams = { testInfo: response, profile: profile };
              this.modalService.removeModal();
              this.modalService.showModal(InvestTestConvenienceComponent, modalParams, '', '', true).subscribe(modal => {
                modal.getChildComponent().subscribe((componentRef: InvestTestConvenienceComponent) => {
                  componentRef.emitSuccess.subscribe(resp => {
                    this.modalService.removeModal();
                    this.createNewBid(createBidToOfferData);
                    this.shareConvenienceTest(resp, profile);
                  });
                  componentRef.testFail.subscribe(resp => {
                    this.modalService.removeModal();
                    this.createNewBid(createBidToOfferData);
                    this.shareConvenienceTest(resp, profile);
                  });
                });
              });
            });
          });
        });
      });
    } else {
      this.createNewBid(createBidToOfferData);
    }
  }

  createNewBid(createBidToOfferData) {
    const modalParams = { createBidOffer: createBidToOfferData };

    this.modalService.showModal(BidCreateComponent, modalParams, '', '', true).subscribe((modal: any) => {
      this.modalService.setPageTitle('Fundeen - Crear puja');
      modal.setTitle('Crear puja');
      modal.getChildComponent().subscribe((componentRef: BidCreateComponent) => {
        componentRef.eventConfirmBid.subscribe((resDataBid: any) => {
          this.postBid(resDataBid).subscribe(
            (resPostBid: any) => {
              componentRef.isBidCreated = resPostBid;
              this.getMarketPlaceOffers();
              this.amplitudeService.trackEvent('marketplace_bid_created', {});
            },
            (error: Error) => {
              componentRef.isBidCreated = false;
            }
          );
        });
        componentRef.eventButtonsRoute.subscribe((resRoute: string) => {
          this.utilsService.navigateTo(`/${resRoute}`);
          // TODO: Eliminar el setTimeout y añadir un loading
          setTimeout(() => modal.close(), 600);
        });
      });
      modal.onClose().subscribe();
    });
  }

  postBid(bid: any): Observable<any> {
    return new Observable((observer) => {
      this.communicationService.post(`${this.urlService}/bid`, bid).subscribe(
        (resPost: string) => {
          if (resPost.toLowerCase() !== 'ok') {
            return observer.error(false);
          }
          observer.next(true);
          return observer.complete();
        },
        () => {
          return observer.error(false);
        }
      );
    });
  }

  async shareConvenienceTest(resp, profile) {
    const fail = resp.sucess;
    const test = resp.test;
    const obj = {
      convenienceTestShares: this.project.type === 1 && !fail ? false : true,
      convenienceTestLoan: this.project.type === 2 && !fail ? false : true,
      username: profile.username,
      log: test,
    };
    // this.overviewService.convenienceTest(obj).subscribe((prof) => {
    //   this.profile = prof;
    // });
  }

  downloadFile(document) {
    this.utils.viewdFile(document);
  }

  downloadDoc(data) {
    let getRes = false;
    setTimeout(() => {
      if (!getRes) {
        this.toastService.showToast(this.translate.instant('MESSAGES.DOCUMENT_DOWNLOAD_WAIT'), 'info', true);
      }
    }, 1500);
    this.overviewService.getUpdateFile(this.id, data.id)
    .subscribe(
      (response) => {
        this.update.downloadedDoc(data.idUp);
        getRes = true;
        const file = new Blob([response], { type: 'application/pdf' });
        const fileURL = window.URL.createObjectURL(file);
        // window.open(fileURL, 'pdf', '_blank');
        const downloadLink = document.createElement('a');
        const fileName = data.fileName + '.pdf';
        downloadLink.href = fileURL;
        downloadLink.download = fileName;
        downloadLink.click();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  async downloadAllDocuments() {
    const domain = this.appConfigurationService.get('domain') + 'apiservices/project/v1/projects/' + this.id + '/fileszip';
    window.open(domain);
    const model = {
      project_name: this.detailProject.name,
      project_id: this.detailProject.id
    };
    await this.amplitudeService.trackEvent('download_project_documentation', model);
    this.changeHuspotStage('download');
  }

  createOffer() {
    if (!this.authService.isRefreshExpired()) {
      this.offersService.createOffer(this.id);
      this.offersService.createdOf.subscribe(resp => {
        this.loadingMkp = true;
        this.getMarketPlaceOffers();
      });
    } else if(!this.authService.isMobileExpired()) {
      this.renewSession('createOfOverview');
      // this.authService.refreshLoginevent.subscribe((action) => {
      //   if (action === 'createOfOverview') {
      //     /*this.offersService.createOffer(this.id);
      //     this.offersService.createdOf.subscribe(resp => {
      //       this.getMarketPlaceOffers();
      //     });*/
      //   }
      // });
    }
  }

  public getMarketPlaceOffers() {
    this.overviewService.getMarketplaceInfo().subscribe(
      (resp) => {
        let i = 0;
        resp.forEach((element) => {
          const idP = parseInt(this.id);
          if (element.id === idP) {
            this.projectMarketp = element;
            this.loadingMkp = false;
            this.marketplaceRow.getData();
          } else {
            i++;
          }
        });
      }
    );
  }

  goBack() {
    this.storage.get('LASTPAGE').then((val) => {
      const page = val.state.page;
      if (page === 'overview') {
        this.navCtrl.navigateRoot([this.localize.translateRoute('/list-of-projects')]);
      } else {
        this.location.back();
      }
    });
  }

  checkoutDataLayer(project, amount, step) {
    let objClickDL;
    const obj = {
      item_name: project.name,
      item_id: project.id.toString(),
      item_brand: project.company,
      item_variant: 'Energía Solar',
      item_list_name: this.getProjectStatus(project.status),
      location_id: 'ES',
      item_category: project.profitability.toString(),
      item_category2: project.total.toString(),
      item_category3: project.term.toString(),
      index: this.projectPosition,
      quantity: 1,
      price: amount
    };
    if (step !== 4) {
      const page = step === 1 ? 'begin_checkout' : 'checkout_page';
      objClickDL = {
        name: page,
        params: obj,
      };
    } else {
      objClickDL = {
        name: 'proceed_with_checkout',
        params: obj,
      };
    }
    this.dataLayerService.pushOnDataLayer(objClickDL);
  }

  checkoutDataLayerPayType(project, amount, step, payType) {
    let payTypeSt = '';
    switch (payType) {
      case 1:
        payTypeSt = 'TDC';
        break;
      case 2:
        payTypeSt = 'Wallet';
        break;
    }
    const obj = {
      iten_name: project.name,
      item_id: project.id.toString(),
      price: amount,
      item_brand: this.project.company,
      item_variant: 'Energía Solar',
      item_list_name: 'En financiación',
      location_id: 'ES',
      item_category: project.profitability.toString(),
      item_category2: project.total.toString(),
      item_category3: project.term.toString(),
      index: this.projectPosition,
      quantity: 1,
      payment_type: payTypeSt,
      currency: 'EUR',
    }

    const objClickDL3 = {
      name: 'add_payment_info',
      params: obj,
    };
    this.dataLayerService.pushOnDataLayer(objClickDL3);
  }

  checkoutDataLayerStartSign(project, amount, step, payType) {
    const nameItem = step === 5 ? 'read_documents' : 'sign_documents'
    let payTypeSt = '';
    switch (payType) {
      case 1:
        payTypeSt = 'TDC';
        break;
      case 2:
        payTypeSt = 'Wallet';
        break;
    }
    const obj = {
      iten_name: project.name,
      item_id: project.id.toString(),
      price: amount,
      item_brand: this.project.company,
      item_variant: 'Energía Solar',
      item_list_name: 'En financiación',
      location_id: 'ES',
      item_category: project.profitability.toString(),
      item_category2: project.total.toString(),
      item_category3: project.term.toString(),
      index: this.projectPosition,
      quantity: 1,
      payment_type: payTypeSt,
      currency: 'EUR'
    }

    const objClickDL4 = {
      name: nameItem,
      params: obj
    };
    this.dataLayerService.pushOnDataLayer(objClickDL4);
  }

  checkoutDataLayerInvestWalletOK(project, amount, transaction, promo, name, payType) {
    let payTypeSt = '';
    switch (payType) {
      case 1:
        payTypeSt = 'TDC';
        break;
      case 2:
        payTypeSt = 'Wallet';
        break;
    }

    const objClickDL6 = {
      name: 'purchase',
      params: {
        afiliation: Capacitor.platform,
        price: amount,
        item_name: project.name,
        item_id: project.id.toString(),
        item_brand: project.company,
        item_variant: 'Energía Solar',
        item_list_name: this.getProjectStatus(project.status),
        location_id: 'ES',
        item_category: project.profitability.toString(),
        item_category2: project.total.toString(),
        item_category3: project.term.toString(),
        quantity: 1,
        currencyCode: 'EUR',
        payment_type: payTypeSt,
    }
    };
    if (this.pruchaseCardTimes === 0) {
      this.pruchaseCardTimes++;
      this.dataLayerService.pushOnDataLayer(objClickDL6);
    }

  }

  checkoutDataLayerInvestCardOK(value) {
    const objClickDL6 = {
      name: 'purchase',
      params: {
        currencyCode: 'EUR',
        afiliation: Capacitor.platform,
        payment_type: 'TDC',
        price: value.amount,
        item_name: value.projectName,
        item_id: value.projectId.toString(),
        item_variant: 'Energía Solar',
        item_list_name: 'En Financiación',
        location_id: 'ES',
        quantity: 1,
        item_category: this.project.profitability.toString(),
        item_category2: this.project.total.toString(),
        item_category3: this.project.term.toString(),
    },
    };
    if (this.pruchaseCardTimes === 0) {
      this.pruchaseCardTimes++;
      this.dataLayerService.pushOnDataLayer(objClickDL6);
    }
  }

  getPositionProject() {
    for (let i = 0; i < this.positionIds.length; i++) {
      if (this.positionIds[i] === this.id) {
        return i;
      }
    }
  }

  getFilter(item) {
    this.realUpdates = this.updates;
    let newUpdates = this.realUpdates;
    switch (item) {
      case this.translate.instant('UPDATES.FINANCING'):
        newUpdates = newUpdates.filter((item) => item.category === 1);
        break;
      case this.translate.instant('UPDATES.CONSTRUCTION'):
        newUpdates = newUpdates.filter((item) => item.category === 2);
        break;
      case this.translate.instant('UPDATES.OPERATION'):
        newUpdates = newUpdates.filter((item) => item.category === 3);
        break;
      case this.translate.instant('UPDATES.DIVIDEND'):
        newUpdates = newUpdates.filter((item) => item.category === 4);
        break;
      case this.translate.instant('UPDATES.OTHERS'):
        newUpdates = newUpdates.filter((item) => (item.category === 5 || item.category === 0));
        break;
      default:
        newUpdates = newUpdates;
        break;
    }
    this.realUpdates = newUpdates;
  }

  getPostCodeAvailable() {
    if (this.detailProject.investment_by_postal_code_enabled
      && (this.detailProject.status === 4 || this.detailProject.status === 12 || this.detailProject.status === 3 || this.detailProject.status === 15)) {
      this.investPostalCodeStepsInfo = {
        unlock: this.detailProject.investment_unlock_date_by_user_postal_code,
        steps: this.detailProject.postal_code_investment_stages,
        canInvest: this.detailProject.user_can_invest_by_postal_code_now,
        status: this.detailProject.status
      };
      if (
        this.detailProject.allowedPostalCodesDescription
        && this.detailProject.allowedPostalCodesDescription !== null
        && this.detailProject.allowedPostalCodesDescription !== undefined
        ) {
          this.infoData = {
            txt: this.detailProject.allowedPostalCodesDescription,
            btnTitle: this.translate.instant('INFO.BTN_MSG_BALEARES')
          }
        } else {
          this.infoData = {
            txt: this.translate.instant('INFO.TXT_MSG_BALEARES'),
            btnTitle: this.translate.instant('INFO.BTN_MSG_BALEARES')
          }
        }
      } else if (this.detailProject.status === 14) {
        this.infoData = {
          txt: this.translate.instant('OVERVIEW.CAPITAL_INCREASE.INCREASE_DATE') + this.getEndPrivateDay(),
          btnTitle: this.translate.instant('OVERVIEW.CAPITAL_INCREASE.READ_MORE')
        }
      }
    }

  getEndPrivateDay() {
    const check = moment(this.detailProject.capital_increase_private_end_date, 'YYYY/MM/DD');
    const day = Number(check.format('DD'));
    const month = this.utilsService.parseMonth(Number(check.format('MM')));
    const year = Number(check.format('YYYY'));
    return this.translate.currentLang === 'en' ?  `${month} ${day}th, ${year}` : `${day} de ${month} de ${year}`;
  }

  openInfoModal() {
    if (this.project.status === 14) {
      this.overviewService.openCapitalIncreaseMoreInfo(this.available);
      this.overviewService.openInvestEvent.subscribe(() => {
        if (!this.openedFromReadMore) {
          this.openedFromReadMore = true;
          setTimeout(() => {
            this.openedFromReadMore = false;
          }, 300);
        }
      });
    } else {
      this.storage.get('USERDATA').then((prof) => {
        const profTxt = prof.profileType === 1 ? this.translate.instant('INFO.MODAL_TXT_P') : this.translate.instant('INFO.MODAL_TXT_J');
        let txt;
        let img;
        let btnTitle;
        if (this.detailProject.postal_code_verification_pending !== true) {
          this.profile$.subscribe((resp) => {
            this.profile = resp;
            this.storage.set('HS_SURNAME_USER', `${this.profile.name} ${this.profile.surname}`);
            if (this.profile.extraPostalCodes !== null) {
              if (this.detailProject.status === 3) {
                txt = this.translate.instant('LOCAL_INVEST.CANT_INVEST_2', {codes: this.profile.extraPostalCodes});
                img = 6;
                btnTitle = this.translate.instant('INFO.MODAL_BTN');
              } else {
                txt = this.translate.instant('LOCAL_INVEST.CANT_INVEST', {codes: this.profile.extraPostalCodes});
                img = 4
                btnTitle = this.translate.instant('INFO.MODAL_BTN');
              }
            } else {
              txt = this.translate.instant('INFO.MODAL_TITLE') + '<p>' + this.translate.instant('INFO.MODAL_TXT_1') + this.detailProject.name + '.' + profTxt + '</p>';
              img = 4;
              btnTitle = this.translate.instant('INFO.MODAL_BTN');
            }
            const modalParams = {
              text: txt,
              image: img,
              btnList: [
                {
                  type: 'primaryDark',
                  index: 1,
                  title: btnTitle,
                }
              ],
            };
            //if (this.detailProject.status !== 15) {
              this.modalService.showModal(OveralMessageComponent, modalParams, '').subscribe((modal: any) => {
                modal.getChildComponent().subscribe((componentRef: OveralMessageComponent) => {
                  componentRef.btnEvent.subscribe(() => {
                    this.modalService.removeModal();
                    if (this.detailProject.postal_code_verification_pending !== true) {
                      this.validateAffiliation();
                    }
                  });
                });
                modal.onClose().subscribe();
              });
          });


        } else {
          img = 14;
          btnTitle = this.translate.instant('LOCAL_INNVEST.DYNAMIC.FINISH');
          txt = this.translate.instant('LOCAL_INNVEST.DYNAMIC.VALIDATING');
          const modalParams = {
            text: txt,
            image: img,
            btnList: [
              {
                type: 'primaryDark',
                index: 1,
                title: btnTitle,
              }
            ],
          };
          //if (this.detailProject.status !== 15) {
            this.modalService.showModal(OveralMessageComponent, modalParams, '').subscribe((modal: any) => {
              modal.getChildComponent().subscribe((componentRef: OveralMessageComponent) => {
                componentRef.btnEvent.subscribe(() => {
                  this.modalService.removeModal();
                  if (this.detailProject.postal_code_verification_pending !== true) {
                    this.validateAffiliation();
                  }
                });
              });
              modal.onClose().subscribe();
            });
        }

        //} else {
          //this.validateAffiliation();
        //}
      });
    }
  }

  verificationMailAdvice(username) {
    const modalParams = {
      text: this.translate.instant('REGISTER.MY_PROFILE.VERIFY_MAIL'),
      btnList: [
        {
          type: 'primaryDark',
          index: 1,
          title: this.translate.instant('REGISTER.PASSPORT.BTN'),
        },
        {
          type: 'secondary',
          index: 2,
          title: this.translate.instant('REGISTER.MY_PROFILE.SKIP'),
        }
      ],
    };

    this.alertService.showModal(OveralMessageComponent, modalParams, '', '', true).subscribe((modal: any) => {
      modal.getChildComponent().subscribe((componentRef: OveralMessageComponent) => {
        componentRef.btnEvent.subscribe((resp) => {
          if (resp === 1) {
            this.profileService.getMailCode(username).subscribe(() => {
              this.toastService.showToast(this.translate.instant('REGISTER.MY_PROFILE.VERIFY_MAIL_2'), 'info', true);
            });
          }
          this.alertService.removeModal();
        });
      });
      modal.onClose().subscribe();
    });
  }

  validateAffiliation() {
    const modalParams = {
      postalCodeInvestmentStages: this.detailProject.postal_code_investment_stages,
      investmentUnlock: this.detailProject.investment_unlock_date_by_user_postal_code,
      canInvest: this.detailProject.user_can_invest_by_postal_code_now,
      projectName: this.detailProject.name,
      status: this.detailProject.status
    };
    this.modalService.showModal(AffiliationLocalInvestComponent, modalParams, '').subscribe((modal: any) => {
      this.modalService.setPageTitle('Fundeen - Inversión local');
      modal.getChildComponent().subscribe((componentRef: AffiliationLocalInvestComponent) => {
        componentRef.closeModalEvent.subscribe(() => {
          this.modalService.removeModal();
        });
        componentRef.reloadPageEvent.subscribe(() => {
          this.elementList = [];
          this.isLoaded = false;
          if (isNaN(Number(this.slug))) {
            this.overviewService.getProjectDetailBySlug(this.slug).subscribe(proj => {
              this.operate(proj);
              this.chdRef.detectChanges();
            });
          } else {
            this.overviewService.getProjectDetail(this.slug).subscribe(proj => {
              this.operate(proj);
              this.chdRef.detectChanges();
            });
          }
        });
        componentRef.updateDealEvent.subscribe(resp => {
          this.changeHuspotStage(resp);
        });
      });
      modal.onClose().subscribe();
    });
  }

  editInvest() {
    this.overviewService.cancelInvestment(this.cancelableInvests);
    this.overviewService.investrejectedEvent.subscribe(() => {
      this.startToLoadProject();
      this.changeHuspotStage('cancel');
    });
  }

  checkDocumentation() {
    this.profileService.getDocumentsType('SIGNATURE').subscribe(doc => {
      const list = doc.documents.filter((item) => item.subtype === 'INVEST' && item.projectId === this.id);
      if (list.length > 0 || this.secondaryDocsLits.length > 0) {
        if (list.length > 0) {
          const fList = { documents: list };
          fList.documents.forEach(element => {
            if (element.files !== null && element.files !== undefined) {
              element.files.forEach(invDoc => {
                if (invDoc.status === 'completed') {
                  this.investProjectDocuments = { documents: [element]};
                }
              });
            }
          });
        }
        const el = {
          icon: 'paperclip',
          title: 'Docs',
          subtitle: 'Verás la documentación del proyecto',
          id: 'documentation',
          border: true
        }
        this.headerInfo.hasDocumentation = true;
        this.pageList.push(el);
        this.chdRef.detectChanges();
      }
    });
  }

  checkPaymentCalendar() {
    const today = new Date();
    this.portfolioService.getNextBenefits().subscribe((resp: BenefitProject[]) => {
      resp.forEach(element => {
        if (element.projectId === this.id) {
          for (let i = 0; i < 12; i++) {
            let dateToSave = new Date(today.getFullYear(), today.getMonth() + i, 1);
            let dateToSaveString = dateToSave.toLocaleString(this.translate.currentLang, {month: 'long', year: 'numeric'});
            this.benefitsByMonth.push(
              {
                date: [
                  dateToSave.getFullYear(),
                  dateToSave.getMonth() + 1,
                  dateToSave.getDate()
                ],
                dateName: `${dateToSaveString.charAt(0).toUpperCase() + dateToSaveString.slice(1)}`,
                benefits: []
              }
            );
          }

          const benefits: Benefit[] = [];
          if (element.benefits) {
            element.benefits.forEach(benefit => {
              benefits.push(benefit);
            });
          }

          benefits.forEach((benefit: Benefit) => {
            this.benefitsByMonth.forEach(month => {
              if (month.date[0] === benefit.date[0] && month.date[1] === benefit.date[1]) {
                month.benefits.push(
                  {
                    projectName: benefit.projectName,
                    amount: benefit.amount,
                    type: benefit.type
                  }
                );
              }
            })
          });
          this.benefitsByMonth = this.benefitsByMonth.filter((month) => month.benefits.length !== 0);
          if (this.benefitsByMonth.length > 0) {
            this.headerInfo.hasCalendar = true;
            const el = {
              icon: 'credit-card',
              title: this.translate.instant('OVERVIEW.PAY'),
              subtitle: this.translate.instant('OVERVIEW.VIEW_PAY'),
              id: 'calendar',
              border: true
            }
            this.pageList.push(el);
            this.chdRef.detectChanges();
          }
        }
      });
    });
  }

  checkProduction() {
    this.overviewService.getHasInvest(this.id).subscribe(() => {
      if (this.detailProject.status === 7) {
        if (this.detailProject.status !== 9) {
          this.headerInfo.hasMonitoring = true;
          const el = {
            icon: 'bar-char-2',
            title: this.translate.instant('OVERVIEW.METRICS'),
            subtitle: this.translate.instant('OVERVIEW.VIEW_METRICS'),
            id: 'monitoring',
            border: true
          }
          this.pageList.push(el);
        }
      }
      this.detailProjectService.getProjectPerformance(this.id).subscribe(resp => {
        this.productionHistoric = resp.production;//this.roundNumberService.roundNumber(resp.production) || 0;
        this.moneyHistoric = resp.money; //this.roundNumberService.roundNumber(resp.money) || 0;
        if (resp.lastUpdate && resp.lastUpdate !== undefined) {
          if (this.detailProject.status !== 7) {
            if (this.detailProject.status !== 9) {
              this.headerInfo.hasMonitoring = true;
              const el = {
                icon: 'bar-char-2',
                title: this.translate.instant('OVERVIEW.METRICS'),
                subtitle: this.translate.instant('OVERVIEW.VIEW_METRICS'),
                id: 'monitoring',
                border: true
              }
              this.pageList.push(el);
            }
          }
          this.lastUpdate = this.getLastUpdate(resp.lastUpdate);
          if (resp.money > 0 && resp.production > 0) {
            this.detailProjectService.getProjectTodayMoney(this.id).subscribe((response) => {
              this.moneyToday = response.money; //this.roundNumberService.roundNumber(resp.money) || 0;
              this.moneyEstimated = response.moneyEstimated; //this.roundNumberService.roundNumber(resp.moneyEstimated) || 0;
              this.chdRef.detectChanges();
            });
            this.detailProjectService.getProjectAnnual(this.id).subscribe((response) => {
              this.moneyAnnual = response.moneyAnnual;  //this.roundNumberService.roundNumber(resp.moneyAnnual) || 0;
              this.moneyAnnualEstimated = response.moneyAnnualEstimated; //this.roundNumberService.roundNumber(resp.moneyAnnualEstimated) || 0;
              this.chdRef.detectChanges();
            });
            this.detailProjectService.getProjectTodayProduction(this.id).subscribe((response) => {
              this.productionToday = response.production; //this.roundNumberService.roundNumber(resp.production) || 0;
              this.productionEstimated = response.productionEstimated; //this.roundNumberService.roundNumber(resp.productionEstimated) || 0;
              this.chdRef.detectChanges();
            });

            this.chdRef.detectChanges();
          }
        } else {
          this.checkedNoHasMonitoring = true;
        }
      });
    });
  }

  getLastUpdate(lastUpdate) {
    let endDateN;
    let todayN;
    let hour;

    endDateN = new Date(this.convertToIos(momentNs(lastUpdate).format('YYYY-MM-DD') + ' 00:00:00')).getTime();
    todayN = new Date(this.convertToIos(momentNs().format('YYYY-MM-DD') + ' 00:00:00')).getTime();

    const lessHourTxt = this.translate.instant('UPDATES.LESS_THAN_ONE_HOUR');
    const anHourTxt = this.translate.instant('UPDATES.ONE_HOUR_AGO');
    const h = (new Date().getTime() - new Date(lastUpdate).getTime()) / (1000 * 60 * 60);
    const minuts = Math.round(h * 60);

    if (h < 1) {
      hour = lessHourTxt;
    } else {
      if (h > 1 && h < 2) {
        hour = anHourTxt;
      } else {
        hour = this.translate.instant('UPDATES.HOURS_AGO', { time: Math.round(h) });
      }
    }

    if (endDateN === isNaN || endDateN === undefined || !endDateN) {
      endDateN = new Date(
        this.convertToIos(momentNs(lastUpdate.replace('/', '-').replace('/', '-')).format('YYYY-MM-DD') + ' 00:00:00')
      ).getTime();
    }

    const timeLeft = (todayN - endDateN) / (1000 * 60 * 60 * 24);
    const lastUpdateMoment = this.translate.instant('UPDATES.LAST_UPDATE');
    const lastUpdateM = this.translate.instant('UPDATES.LAST_UPDATE_MINUTS_AGO', { min: minuts });
    const lastUpdateH = this.translate.instant('UPDATES.LAST_UPDATE_HOURS', { hours: hour });
    const lastUpdateD = this.translate.instant('UPDATES.LAST_UPDATE_DAY_AGO');
    const lastUpdateT = this.translate.instant('UPDATES.LAST_UPDATE_DAYS_AGO', { days: Math.round(timeLeft) });

    switch (timeLeft) {
      case 0:
        if (minuts < 60) {
          if (minuts > 1) {
            this.lastUpdates = lastUpdateM;
          } else {
            this.lastUpdates = lastUpdateMoment;
          }
        } else {
          this.lastUpdates = lastUpdateH;
        }
        break;
      case 1:
        this.lastUpdates = lastUpdateD;
        break;
      default:
        this.lastUpdates = lastUpdateT;
        break;
    }
    return timeLeft;
  }

  openHelpGrid(level, index) {
    this.helpService.openHelp(level, index, 'DASHBOARD_DETAIL');
  }

  convertToIos(date) {
    const arr = date.split(/[- :]/);
    const r = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    return r;
  }

  closeModal() {
    this.closeModalEvent.emit();
    try {
      this.modalController.dismiss();
    } catch {}
  }

  scrollToCalculateBeneffits() {
    const el = document.getElementById("content");
    el.scrollTo({
      top: this.calculatorComponet.nativeElement.offsetTop,
      behavior: 'smooth',
    })
  }

  stayInformed() {
    const params = {
      projectName: this.detailProject.name,
      projectId: this.detailProject.id
    }
    this.modalService.showModal(SubscribeProjectNewsComponent, params, '').subscribe((modal: any) => {
      modal.getChildComponent().subscribe((componentRef: SubscribeProjectNewsComponent) => {
        componentRef.chageStageHubSpotEvent.subscribe(val => {
          this.changeHuspotStage(val);
          this.modalService.removeModal();
        });
      })
    });
  }

  changeHuspotStage(stage, params = {}) {
    this.storage.get('USERNAME').then((val) => {
      const userEmail = val;
      const idProject = this.detailProject.id;
      const nameProject = this.detailProject.name;
      let currentDeal;

      if (
        this.detailProject.status === 3
        || this.detailProject.status === 4
        || this.detailProject.status === 12
        || this.detailProject.status === 15
        ) { // Solo actualiza si está abierto a inversion, reservas o proximamente
          if (this.projectDeal === undefined && !this.updatingDeal) {
            this.updatingDeal = true;
            this.HubspotService.getDeals(userEmail, idProject).subscribe((resHusbpot: any) => {
              if(resHusbpot.success) {
                const deals = resHusbpot.data.results;
                if(deals.length) {
                  currentDeal = deals[0].id;
                  if(stage !== '' && stage !== undefined
                  && ((deals[0].properties.dealstage !== this.HubspotService.getStage('ok')
                  && deals[0].properties.dealstage !== this.HubspotService.getStage('reservation')
                  && deals[0].properties.dealstage !== this.HubspotService.getStage('validation'))
                  || stage === 'cancel')
                  ) {
                    params['estado_del_proyecto'] = this.detailProject.status;
                    this.HubspotService.updateDeal(currentDeal, stage, params).subscribe((resHusbpot: any) => {
                      this.projectDeal = resHusbpot.data;
                      this.updatingDeal = false;
                    });
                  }
                } else {
                  this.updatingDeal = true;
                  this.storage.get('HS_SURNAME_USER').then((name) => {
                    const userName = name;
                    this.storage.get('HS_CONTACT_ID').then((idhbp) => {
                      const info = `${userName} - ${nameProject}`;
                      this.HubspotService.createDeal(userEmail, idProject, idhbp, info, nameProject).subscribe((resHusbpot: any) => {
                        if(resHusbpot.success) {
                          currentDeal = resHusbpot.data.id;
                          if(stage !== '' && stage !== undefined) {
                            params['estado_del_proyecto'] = this.detailProject.status;
                            this.HubspotService.updateDeal(currentDeal, stage, params).subscribe((resHusbpot: any) => {
                              this.projectDeal = resHusbpot.data;
                              this.updatingDeal = false;
                            });
                          }
                        }
                      });
                    });
                  });
                }
              }
            });
          } else {
            if(stage !== '' && stage !== undefined
              && !this.updatingDeal
              && ((this.projectDeal.properties.dealstage !== this.HubspotService.getStage('ok')
              && this.projectDeal.properties.dealstage !== this.HubspotService.getStage('reservation'))
              || stage === 'cancel')
              ) {
                this.updatingDeal = true;
                this.HubspotService.updateDeal(this.projectDeal.id, stage, params).subscribe((resHusbpot: any) => {
                  this.projectDeal = resHusbpot.data;
                  this.updatingDeal = false;
                });
              }
          }
        }
    });
  }

  renewSession(action) {
    this.refreshModalOpened = true;
    this.biometricService.checkBiometricAvailable();
    const modalParams = {};
    this.alertService.showModal(RenewSessionComponent, modalParams, '', '', true).subscribe((modal: any) => {
      modal.getChildComponent().subscribe((componentRef: RenewSessionComponent) => {
        componentRef.cancelEvent.subscribe((res) => {
          this.alertService.removeModal();
          this.refreshModalOpened = false;
        });
        componentRef.aceptEvent.subscribe((res) => {
          this.storage.get('USERNAME').then(async (val) => {
            const params = {
              email: val,
              password: res,
            };
            const isLoginCorrect = await this.authService.login(params);
            if (isLoginCorrect) {
              this.alertService.removeModal();
              this.refreshModalOpened = false;
              //this.isRefreshLoginOk(action);
            } else {
              componentRef.passwordError();
            }
           });
          });
          this.biometricService.getCredentialsEvent.subscribe(credentials => {
            if (credentials !== null) {
              this.storage.get('USERNAME').then(async (val) => {
                const params = {
                  email: credentials.username,
                  password: credentials.password,
                };
                const isLoginCorrect = await this.authService.login(params);
                if (isLoginCorrect) {
                  this.alertService.removeModal();
                  this.refreshModalOpened = false;
                  //this.isRefreshLoginOk(action);
                } else {
                  componentRef.passwordError();
                }
               });
            }
          });
        });
         modal.onClose().subscribe();
      });
  }
}
