<div class="general-container appear">
  <div class="update" *ngFor="let update of updates">
      <div class="update-container border border-eggplant-100/[0.1] bg-white rounded-2xl"
      [ngClass]="{'blur': (isInvestor === false && !update.public) }">
          <div class="files rounded-2xl" *ngIf="update.hasFiles && (isInvestor || update.public) && update.imgList.length > 0">
            <app-update-list-slider [imageList]="update.imgList"></app-update-list-slider>
          </div>
          <div *ngIf="update.youtubeUrlS !== undefined">
              <div class="youtubeVideo rounded-2xl">
                  <iframe class="rounded-2xl"
                    width="560" height="315"
                    [src]="update.youtubeUrlS"
                    frameborder="0"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
          </div>
          <div class="text" [ngClass]="{'open': isMoreOpen[update.frontId], 'closed': !isMoreOpen[update.frontId], 'rounded-3xl': isDetail}">
              <h4 class="text-l font-sans text-eggplant-80 uppercase" *ngIf="update.type !== 3 && (isInvestor || update.public)">{{ update.fullDate }}</h4>
              <h4 class="text-l font-sans text-eggplant-80 uppercase" *ngIf="update.type === 3 && goalReserched !== undefined && (isInvestor || update.public)">{{ goalReserched }}</h4>
              <h4 class="text-l font-sans text-eggplant-80 uppercase" *ngIf="!isInvestor && !update.public">01/12/01</h4>
              <h2 class="font-sans text-2xl text-eggplant-100 pt-12"> {{ update.info.title }}</h2>
              <h3 class="text-m text-eggplant-100/[0.55] font-sans">{{ update.projectName }}</h3>
              <h5 class="text-m uppercase font-sans text-eggplant-100/[0.4] absolute border border-eggplant-100/[0.4]" *ngIf="update.typeName !== undefined && (isInvestor || update.public)">{{ update.typeName }}</h5>
              <h5 class="text-m uppercase font-sans text-eggplant-100/[0.4] absolute border border-eggplant-100/[0.4]" *ngIf="!isInvestor && !update.public">Lorem</h5>
              <p class="text-l text-eggplant-80 font-sans" *ngIf="((!update.info.description_long || update.info.description_long === undefined) && update.info.description
              && update.info.description !== undefined) && (isInvestor || update.public)" [title]="update.info.description_long" [innerHtml]="update.info.description">
              </p>
              <p class="text-l text-eggplant-80 font-sans" *ngIf="(update.info.description_long && update.info.description_long !== undefined) && (isInvestor || update.public)" [innerHtml]="update.info.description_long"></p>
              <p class="text-l text-eggplant-80 font-sans" *ngIf="update.type === 4 && update.amount && (isInvestor || update.public)">
                  {{ 'updates.distributed' | translate }} {{ update.amount | localeNumber }} € {{ 'updates.proffit' | translate }}
              </p>
              <p class="text-l text-eggplant-80 font-sans" *ngIf="!isInvestor && !update.public">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
              <div *ngIf="update.hasFiles && (isInvestor || update.public)">
                  <div *ngFor="let file of update.files">
                      <div class="document" *ngIf="file.tipo === 2">
                          <img (click)="clickDock(file.uuid, file.nombre, file.id, update.projectId)"
                          alt="file" class="file-img" src="../../../../assets/icons/file-green.svg">
                          <p class="text-l text-eggplant-80 font-sans" (click)="clickDock(file.uuid, file.nombre, file.id, update.projectId)">{{ file.nombre }}</p>
                          <img *ngIf="!downloadingDoc[file.id]" (click)="clickDock(file.uuid, file.nombre, file.id, update.projectId)"
                          alt="download-img" class="download" src="../../../../assets/icons/download.svg">
                          <img *ngIf="downloadingDoc[file.id] && okElement[file.id]" src="../../../../assets/icons/download-ok.svg"
                          class="download ok-icon" alt="tick">
                          <div *ngIf="downloadingDoc[file.id] && !okElement[file.id]" class="lds-spinner download">
                              <div></div><div></div><div></div><div></div>
                              <div></div><div></div><div></div><div></div>
                              <div></div><div></div><div></div><div></div>
                          </div>
                      </div>
                  </div>
              </div>
              <!---->
          </div>
      </div>
  </div>
</div>