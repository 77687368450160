import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Component({
  selector: 'fundeen-activation-wallet-message',
  templateUrl: './activation-wallet-message.component.html',
  styleUrls: ['./activation-wallet-message.component.scss']
})
export class ActivationWalletMessageComponent implements OnInit {

  @Output() public transferEvent = new EventEmitter<any>();
  @Output() public clickedProjectEvent = new EventEmitter<any>();

  loading = true;
  info: any;

  constructor(private chdRef: ChangeDetectorRef, private amplitudeService: AmplitudeService) { }

  ngOnInit() {
    registerLocaleData(es);
    this.amplitudeService.trackEvent('register_wallet_validated', {});
  }

  public loadedPage(project) {
    this.info = project;
    this.loading = false;
    this.chdRef.detectChanges();
  }

  transfer() {
    this.transferEvent.emit();
    this.chdRef.detectChanges();
  }

  emitClickProject(event, project) {
    const model = {
      event: event,
      project: project
    };
    this.clickedProjectEvent.emit(model);
  }

}
