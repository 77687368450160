
<svg *ngIf="name === 'arrow-right'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9 24H39M39 24L25 10M39 24L25 38" stroke-linecap="round" stroke-linejoin="round" />
</svg>
<svg *ngIf="name === 'arrow-up-right'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14 13H34M34 13V33M34 13L12 35" stroke-linecap="round" stroke-linejoin="round" />
</svg>
<svg *ngIf="name === 'arrow-left'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M39 24H9M9 24L23 38M9 24L23 10" stroke-linecap="round" stroke-linejoin="round" />
</svg>
<svg *ngIf="name === 'arrow-down'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M24 9V39M24 39L38 25M24 39L10 25" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'arrow-down-circle'" [class]="style" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13 21L21 29M21 29L29 21M21 29V13M41 21C41 32.0457 32.0457 41 21 41C9.9543 41 1 32.0457 1 21C1 9.9543 9.9543 1 21 1C32.0457 1 41 9.9543 41 21Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'minus-circle'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16 24H32M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'plus-circle'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M24 16V32M16 24H32M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'globe'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M44 24C44 35.0457 35.0457 44 24 44M44 24C44 12.9543 35.0457 4 24 4M44 24H4M24 44C12.9543 44 4 35.0457 4 24M24 44C29.0026 38.5233 31.8455 31.4159 32 24C31.8455 16.5841 29.0026 9.47671 24 4M24 44C18.9974 38.5233 16.1545 31.4159 16 24C16.1545 16.5841 18.9974 9.47671 24 4M4 24C4 12.9543 12.9543 4 24 4" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'shield'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M24 44C24 44 40 36 40 24V10L24 4L8 10V24C8 36 24 44 24 44Z" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'message-circle'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M42 23.0001C42.0069 25.6398 41.3901 28.2438 40.2 30.6001C38.7889 33.4235 36.6195 35.7984 33.9349 37.4586C31.2503 39.1188 28.1565 39.9988 25 40.0001C22.3603 40.0069 19.7562 39.3902 17.4 38.2001L6 42.0001L9.8 30.6001C8.60986 28.2438 7.99312 25.6398 8 23.0001C8.00122 19.8436 8.88122 16.7498 10.5414 14.0652C12.2017 11.3806 14.5765 9.21119 17.4 7.80006C19.7562 6.60992 22.3603 5.99317 25 6.00006H26C30.1687 6.23004 34.1061 7.98958 37.0583 10.9418C40.0105 13.894 41.77 17.8314 42 22.0001V23.0001Z" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'unlock'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14 21.9998V13.9998C13.9975 11.5199 14.9166 9.12755 16.5787 7.28715C18.2409 5.44675 20.5277 4.28962 22.995 4.0404C25.4624 3.79117 27.9343 4.46763 29.9309 5.93845C31.9276 7.40926 33.3065 9.56951 33.8 11.9998M10 21.9998H38C40.2091 21.9998 42 23.7907 42 25.9998V39.9998C42 42.209 40.2091 43.9998 38 43.9998H10C7.79086 43.9998 6 42.209 6 39.9998V25.9998C6 23.7907 7.79086 21.9998 10 21.9998Z" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'users'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M34 42V38C34 35.8783 33.1571 33.8434 31.6569 32.3431C30.1566 30.8429 28.1217 30 26 30H10C7.87827 30 5.84344 30.8429 4.34315 32.3431C2.84285 33.8434 2 35.8783 2 38V42M46 42V38C45.9987 36.2275 45.4087 34.5055 44.3227 33.1046C43.2368 31.7037 41.7163 30.7031 40 30.26M32 6.26C33.7208 6.7006 35.2461 7.7014 36.3353 9.10462C37.4245 10.5078 38.0157 12.2337 38.0157 14.01C38.0157 15.7863 37.4245 17.5122 36.3353 18.9154C35.2461 20.3186 33.7208 21.3194 32 21.76M26 14C26 18.4183 22.4183 22 18 22C13.5817 22 10 18.4183 10 14C10 9.58172 13.5817 6 18 6C22.4183 6 26 9.58172 26 14Z" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'user-check'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M32 42V38C32 35.8783 31.1571 33.8434 29.6569 32.3431C28.1566 30.8429 26.1217 30 24 30H10C7.87827 30 5.84344 30.8429 4.34315 32.3431C2.84285 33.8434 2 35.8783 2 38V42M34 22L38 26L46 18M25 14C25 18.4183 21.4183 22 17 22C12.5817 22 9 18.4183 9 14C9 9.58172 12.5817 6 17 6C21.4183 6 25 9.58172 25 14Z" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'percent'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M38 10L10 38M18 13C18 15.7614 15.7614 18 13 18C10.2386 18 8 15.7614 8 13C8 10.2386 10.2386 8 13 8C15.7614 8 18 10.2386 18 13ZM40 35C40 37.7614 37.7614 40 35 40C32.2386 40 30 37.7614 30 35C30 32.2386 32.2386 30 35 30C37.7614 30 40 32.2386 40 35Z" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'maximize-2'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M25 1H37M37 1V13M37 1L23 15M13 37H1M1 37V25M1 37L15 23" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'eye'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2 24C2 24 10 8 24 8C38 8 46 24 46 24C46 24 38 40 24 40C10 40 2 24 2 24Z" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M24 30C27.3137 30 30 27.3137 30 24C30 20.6863 27.3137 18 24 18C20.6863 18 18 20.6863 18 24C18 27.3137 20.6863 30 24 30Z" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'check-style'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M35.0749 12.6404L18.3683 28.7637L13.9349 24.027C13.1183 23.257 11.8349 23.2104 10.9016 23.8637C9.99161 24.5404 9.73495 25.7304 10.2949 26.687L15.5449 35.227C16.0583 36.0204 16.9449 36.5104 17.9483 36.5104C18.9049 36.5104 19.8149 36.0204 20.3283 35.227C21.1683 34.1304 37.1983 15.0204 37.1983 15.0204C39.2983 12.8737 36.7549 10.9837 35.0749 12.617V12.6404Z" fillRule="evenodd" clipRule="evenodd" />
</svg>
<svg *ngIf="name === 'file'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M26 4H12C10.9391 4 9.92172 4.42143 9.17157 5.17157C8.42143 5.92172 8 6.93913 8 8V40C8 41.0609 8.42143 42.0783 9.17157 42.8284C9.92172 43.5786 10.9391 44 12 44H36C37.0609 44 38.0783 43.5786 38.8284 42.8284C39.5786 42.0783 40 41.0609 40 40V18M26 4L40 18M26 4V18H40" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'file-text'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M28 4H12C10.9391 4 9.92172 4.42143 9.17157 5.17157C8.42143 5.92172 8 6.93913 8 8V40C8 41.0609 8.42143 42.0783 9.17157 42.8284C9.92172 43.5786 10.9391 44 12 44H36C37.0609 44 38.0783 43.5786 38.8284 42.8284C39.5786 42.0783 40 41.0609 40 40V16M28 4L40 16M28 4V16H40M32 26H16M32 34H16M20 18H16" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'linkedin'" [class]="style" viewBox="-20 0 80 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M29 13C32.1826 13 35.2348 14.2643 37.4853 16.5147C39.7357 18.7652 41 21.8174 41 25V39H33V25C33 23.9391 32.5786 22.9217 31.8284 22.1716C31.0783 21.4214 30.0609 21 29 21C27.9391 21 26.9217 21.4214 26.1716 22.1716C25.4214 22.9217 25 23.9391 25 25V39H17V25C17 21.8174 18.2643 18.7652 20.5147 16.5147C22.7652 14.2643 25.8174 13 29 13Z" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M9 15H1V39H9V15Z" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'facebook'" [class]="style" viewBox="-20 0 95 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M36 4H30C27.3478 4 24.8043 5.05357 22.9289 6.92893C21.0536 8.8043 20 11.3478 20 14V20H14V28H20V44H28V28H34L36 20H28V14C28 13.4696 28.2107 12.9609 28.5858 12.5858C28.9609 12.2107 29.4696 12 30 12H36V4Z" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'twitter'" [class]="style" viewBox="-20 0 90 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M46 6C44.0848 7.35096 41.9642 8.38422 39.72 9.06C38.5155 7.67502 36.9147 6.69338 35.1341 6.24785C33.3535 5.80232 31.479 5.91439 29.7642 6.56891C28.0493 7.22342 26.5769 8.3888 25.546 9.90743C24.515 11.4261 23.9754 13.2247 24 15.06V17.06C20.4853 17.1511 17.0025 16.3716 13.862 14.7909C10.7215 13.2102 8.02063 10.8773 6 8C6 8 -2 26 16 34C11.8811 36.7959 6.97431 38.1979 2 38C20 48 42 38 42 15C41.9982 14.4429 41.9446 13.8872 41.84 13.34C43.8812 11.327 45.3217 8.78543 46 6Z" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'whatsapp'" [class]="style" viewBox="-15 0 65 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M33.3 6.4625C29.7375 2.9 24.9875 1 20 1C9.55 1 1 9.55 1 20C1 23.325 1.95001 26.65 3.61251 29.5L1 39L10.975 36.3875C13.825 37.8125 16.9125 38.7625 20 38.7625C30.45 38.7625 39 30.2125 39 19.7625C39 14.775 36.8625 10.025 33.3 6.4625ZM28.55 23.8C28.075 23.5625 25.7 22.375 25.225 22.375C24.75 22.1375 24.5125 22.1375 24.275 22.6125C24.0375 23.0875 23.0875 24.0375 22.85 24.5125C22.6125 24.75 22.375 24.75 21.9 24.75C21.425 24.5125 20 24.0375 18.1 22.375C16.675 21.1875 15.725 19.525 15.4875 19.05C15.25 18.575 15.4875 18.3375 15.725 18.1C15.9625 17.8625 16.2 17.625 16.4375 17.3875C16.675 17.15 16.675 16.9125 16.9125 16.675C17.15 16.4375 16.9125 16.2 16.9125 15.9625C16.9125 15.725 15.9625 13.35 15.4875 12.4C15.25 11.6875 14.775 11.6875 14.5375 11.6875C14.3 11.6875 14.0625 11.6875 13.5875 11.6875C13.35 11.6875 12.875 11.6875 12.4 12.1625C11.925 12.6375 10.7375 13.825 10.7375 16.2C10.7375 18.575 12.4 20.7125 12.6375 21.1875C12.875 21.425 15.9625 26.4125 20.7125 28.3125C24.75 29.975 25.4625 29.5 26.4125 29.5C27.3625 29.5 29.2625 28.3125 29.5 27.3625C29.975 26.175 29.975 25.225 29.7375 25.225C29.5 24.0375 29.025 24.0375 28.55 23.8Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'instagram'" [class]="style" viewBox="-20 0 90 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M35 13H35.02M14 4H34C39.5228 4 44 8.47715 44 14V34C44 39.5228 39.5228 44 34 44H14C8.47715 44 4 39.5228 4 34V14C4 8.47715 8.47715 4 14 4ZM32 22.74C32.2468 24.4045 31.9625 26.1044 31.1875 27.598C30.4125 29.0916 29.1863 30.3028 27.6833 31.0593C26.1802 31.8159 24.4769 32.0792 22.8156 31.8119C21.1543 31.5445 19.6195 30.7602 18.4297 29.5703C17.2398 28.3805 16.4555 26.8457 16.1881 25.1844C15.9208 23.5231 16.1841 21.8198 16.9407 20.3167C17.6972 18.8137 18.9084 17.5875 20.402 16.8125C21.8956 16.0375 23.5955 15.7532 25.26 16C26.9578 16.2518 28.5297 17.0429 29.7434 18.2566C30.9571 19.4703 31.7482 21.0422 32 22.74Z" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'share'" [class]="style" viewBox="-17 0 80 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8 24V40C8 41.0609 8.42143 42.0783 9.17157 42.8284C9.92172 43.5786 10.9391 44 12 44H36C37.0609 44 38.0783 43.5786 38.8284 42.8284C39.5786 42.0783 40 41.0609 40 40V24M32 12L24 4M24 4L16 12M24 4V30" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'download'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M42 30V38C42 39.0609 41.5786 40.0783 40.8284 40.8284C40.0783 41.5786 39.0609 42 38 42H10C8.93913 42 7.92172 41.5786 7.17157 40.8284C6.42143 40.0783 6 39.0609 6 38V30M14 20L24 30M24 30L34 20M24 30V6" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'circle'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'help-circle'" [class]="style" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15.18 15C15.6502 13.6633 16.5783 12.5362 17.7999 11.8183C19.0215 11.1003 20.4578 10.8379 21.8544 11.0774C23.2509 11.317 24.5176 12.043 25.4302 13.1271C26.3427 14.2111 26.8421 15.583 26.84 17C26.84 21 20.84 23 20.84 23M21 31H21.02M41 21C41 32.0457 32.0457 41 21 41C9.9543 41 1 32.0457 1 21C1 9.9543 9.9543 1 21 1C32.0457 1 41 9.9543 41 21Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'help-circle-filled'" [class]="style" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0ZM19.1266 12.6804C20.1446 12.0821 21.3415 11.8634 22.5053 12.063C23.6691 12.2626 24.7247 12.8677 25.4852 13.771C26.2456 14.6744 26.6618 15.8177 26.66 16.9985L26.66 17C26.66 18.4691 25.5449 19.7082 24.1053 20.6679C23.416 21.1275 22.7195 21.4765 22.1914 21.7112C21.9288 21.8279 21.7113 21.9148 21.5618 21.9717C21.4872 22.0002 21.4298 22.021 21.3924 22.0343L21.3519 22.0485L21.3429 22.0516C20.8195 22.2266 20.5368 22.7926 20.7113 23.3162C20.886 23.8402 21.4523 24.1233 21.9763 23.9487C21.8003 23.4208 21.7416 23.2448 21.7416 23.2448C21.7416 23.2448 21.9764 23.9486 21.9776 23.9482L21.9796 23.9475L21.9851 23.9457L22.0026 23.9397C22.0171 23.9347 22.0371 23.9277 22.0624 23.9188C22.113 23.9008 22.1844 23.8748 22.2738 23.8407C22.4525 23.7727 22.7038 23.6721 23.0037 23.5388C23.6006 23.2735 24.4041 22.8725 25.2147 22.332C26.7749 21.2919 28.6596 19.5313 28.66 17.0009C28.6623 15.348 28.0797 13.7476 27.0152 12.483C25.9506 11.2184 24.4728 10.3713 22.8434 10.0918C21.2141 9.81233 19.5385 10.1185 18.1133 10.9561C16.688 11.7937 15.6053 13.1087 15.0567 14.6681C14.8734 15.1891 15.1472 15.76 15.6682 15.9433C16.1892 16.1266 16.7601 15.8528 16.9434 15.3318C17.3352 14.2179 18.1086 13.2787 19.1266 12.6804ZM21.82 30C21.2677 30 20.82 30.4477 20.82 31C20.82 31.5523 21.2677 32 21.82 32H21.84C22.3923 32 22.84 31.5523 22.84 31C22.84 30.4477 22.3923 30 21.84 30H21.82Z"/>
</svg>
<svg *ngIf="name === 'external-link'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M36 26V38C36 39.0609 35.5786 40.0783 34.8284 40.8284C34.0783 41.5786 33.0609 42 32 42H10C8.93913 42 7.92172 41.5786 7.17157 40.8284C6.42143 40.0783 6 39.0609 6 38V16C6 14.9391 6.42143 13.9217 7.17157 13.1716C7.92172 12.4214 8.93913 12 10 12H22M30 6H42M42 6V18M42 6L20 28" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'menu'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6 24H42M6 12H42M6 36H42" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'x'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M36 12L12 36M12 12L36 36" strokeLinecap="round" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'chevron-up'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 29L23.4347 17.2411C23.5089 17.1646 23.597 17.104 23.694 17.0627C23.791 17.0213 23.895 17 24 17C24.105 17 24.209 17.0213 24.306 17.0627C24.403 17.104 24.4911 17.1646 24.5653 17.2411L36 29" strokeLinecap="square" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'chevron-down'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M36 19L24.5653 30.7589C24.4911 30.8353 24.403 30.896 24.306 30.9373C24.209 30.9787 24.105 31 24 31C23.895 31 23.791 30.9787 23.694 30.9373C23.597 30.896 23.5089 30.8353 23.4347 30.7589L12 19" strokeLinecap="square" strokeLinejoin="round" />
</svg>
<svg *ngIf="name === 'chevron-left'" [class]="style" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.5 14L1.62054 8.28267C1.58232 8.24557 1.55201 8.2015 1.53133 8.153C1.51064 8.1045 1.5 8.05251 1.5 8C1.5 7.94749 1.51064 7.8955 1.53133 7.847C1.55201 7.7985 1.58232 7.75443 1.62054 7.71733L7.5 2" stroke-linecap="square" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'briefcase'" [class]="style" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M32 14V10C32 8.93913 31.5786 7.92172 30.8284 7.17157C30.0783 6.42143 29.0609 6 28 6H20C18.9391 6 17.9217 6.42143 17.1716 7.17157C16.4214 7.92172 16 8.93913 16 10V14M24 23V31M9.86 28.86L15.52 34.52M32.48 34.52L38.14 28.86M8 14H40C42.2091 14 44 15.7909 44 18V38C44 40.2091 42.2091 42 40 42H8C5.79086 42 4 40.2091 4 38V18C4 15.7909 5.79086 14 8 14Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'clock'" [class]="style" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M21 9V21L29 25M41 21C41 32.0457 32.0457 41 21 41C9.9543 41 1 32.0457 1 21C1 9.9543 9.9543 1 21 1C32.0457 1 41 9.9543 41 21Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'bar-char-2'" [class]="style" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M25 33V13M13 33V1M1 33V21" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'paperclip'" [class]="style" viewBox="0 0 41 44" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M39.88 21.1L21.5 39.48C19.2483 41.7317 16.1944 42.9967 13.01 42.9967C9.82563 42.9967 6.77169 41.7317 4.52 39.48C2.26831 37.2283 1.00333 34.1744 1.00333 30.99C1.00333 27.8056 2.26831 24.7517 4.52 22.5L22.9 4.11999C24.4011 2.61887 26.4371 1.77554 28.56 1.77554C30.6829 1.77554 32.7189 2.61887 34.22 4.11999C35.7211 5.62112 36.5644 7.65708 36.5644 9.77999C36.5644 11.9029 35.7211 13.9389 34.22 15.44L15.82 33.82C15.0694 34.5706 14.0515 34.9922 12.99 34.9922C11.9285 34.9922 10.9106 34.5706 10.16 33.82C9.40944 33.0694 8.98778 32.0514 8.98778 30.99C8.98778 29.9285 9.40944 28.9106 10.16 28.16L27.14 11.2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'credit-card'" [class]="style" viewBox="0 0 46 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 13H45M5 1H41C43.2091 1 45 2.79086 45 5V29C45 31.2091 43.2091 33 41 33H5C2.79086 33 1 31.2091 1 29V5C1 2.79086 2.79086 1 5 1Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'info-circle'" [class]="style" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11 15V11M11 7H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'chevron-right'" [class]="style" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.91699 1.875L6.81655 6.63944C6.84839 6.67036 6.87365 6.70708 6.89089 6.7475C6.90812 6.78792 6.91699 6.83124 6.91699 6.875C6.91699 6.91876 6.90812 6.96208 6.89089 7.0025C6.87365 7.04292 6.84839 7.07964 6.81655 7.11056L1.91699 11.875" stroke-linecap="square" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'upload'" [class]="style" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M37 25V33C37 34.0609 36.5786 35.0783 35.8284 35.8284C35.0783 36.5786 34.0609 37 33 37H5C3.93913 37 2.92172 36.5786 2.17157 35.8284C1.42143 35.0783 1 34.0609 1 33V25M29 11L19 1M19 1L9 11M19 1V25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'arrow-up'" [class]="style" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.0007 23.625L14.0007 6.125M14.0007 6.125L5.83398 14.2917M14.0007 6.125L22.1673 14.2917" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'coin-stack'" [class]="style" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M23.351 5.44449C23.3533 2.98891 18.3461 1 12.1766 1C6.00713 1 1.00447 2.99113 1 5.44449M1 5.44449C1 7.90007 6.00266 9.88898 12.1766 9.88898C18.3506 9.88898 23.3533 7.90007 23.3533 5.44449L23.3533 22.689M1 5.44449V32.1113C1.00224 34.5669 6.0049 36.5558 12.1766 36.5558C15.1697 36.5558 17.8722 36.0824 19.8773 35.3202M1.00224 14.3334C1.00224 16.789 6.0049 18.7779 12.1789 18.7779C18.3529 18.7779 23.3555 16.789 23.3555 14.3334M20.0115 26.3892C17.9952 27.1781 15.2301 27.667 12.1766 27.667C6.0049 27.667 1.00224 25.6781 1.00224 23.2225M36.0544 23.9293C39.9819 27.8338 39.9819 34.1672 36.0544 38.0716C32.1269 41.9761 25.7562 41.9761 21.8288 38.0716C17.9013 34.1672 17.9013 27.8338 21.8288 23.9293C25.7562 20.0248 32.1269 20.0248 36.0544 23.9293Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'investments'" [class]="style" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M39.3611 9.32064C39.7602 9.58297 40.1037 9.92134 40.3719 10.3164C40.6402 10.7115 40.8281 11.1556 40.9247 11.6233C41.0214 12.0909 41.025 12.5731 40.9353 13.0422L36.1625 38.0465C35.9814 38.9934 35.4317 39.8297 34.6342 40.3715C33.8367 40.9133 32.8568 41.1162 31.9097 40.9356L16 37.899M1 26.4552H33.7279M4.63643 1H30.0914C32.0998 1 33.7279 2.6281 33.7279 4.63646V30.0917C33.7279 32.1001 32.0998 33.7282 30.0914 33.7282H4.63643C2.62809 33.7282 1 32.1001 1 30.0917V4.63646C1 2.6281 2.62809 1 4.63643 1Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'message-square'" [class]="style" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M37 25C37 26.0609 36.5786 27.0783 35.8284 27.8284C35.0783 28.5786 34.0609 29 33 29H9L1 37V5C1 3.93913 1.42143 2.92172 2.17157 2.17157C2.92172 1.42143 3.93913 1 5 1H33C34.0609 1 35.0783 1.42143 35.8284 2.17157C36.5786 2.92172 37 3.93913 37 5V25Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'promoter-checked'" [class]="style" viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10 18L17 25L33 9M5 1H37C38.0609 1 39.0783 1.42143 39.8284 2.17157C40.5786 2.92172 41 3.93913 41 5V17C41 22.3043 38.8929 27.3914 35.1421 31.1421C31.3914 34.8929 26.3043 37 21 37C18.3736 37 15.7728 36.4827 13.3463 35.4776C10.9198 34.4725 8.71504 32.9993 6.85786 31.1421C3.10714 27.3914 1 22.3043 1 17V5C1 3.93913 1.42143 2.92172 2.17157 2.17157C2.92172 1.42143 3.93913 1 5 1Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'coins'" [class]="style" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15.844 5.31604C21.5972 -0.439792 30.9281 -0.43757 36.6835 5.31604C42.4388 11.0697 42.4388 20.4012 36.6835 26.1571M9.71168 28.1C10.5991 30.94 13.2388 33 16.3571 33C18.1408 33 19.7679 32.326 21 31.2176M9.71168 28.1C9.50453 27.4371 9.39286 26.7316 9.39286 26C9.39286 25.2684 9.50453 24.5629 9.71168 23.9M9.71168 28.1H8M9.71168 28.1H14.9643M9.71168 23.9C10.5991 21.06 13.2388 19 16.3571 19C18.1408 19 19.7679 19.674 21 20.7824M9.71168 23.9H8M9.71168 23.9H14.9643M30.4747 26.2615C30.4747 34.4014 23.8766 41 15.7374 41C7.59814 41 1 34.4014 1 26.2615C1 18.1217 7.59814 11.523 15.7374 11.523C23.8766 11.523 30.4747 18.1217 30.4747 26.2615Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'coins-filled'" [class]="style" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M33.5255 30.1233C38.5684 27.4306 42 22.1161 42 16C42 7.16344 34.8366 0 26 0C19.8839 0 14.5694 3.43162 11.8767 8.47447C13.2008 8.16414 14.5813 8 16 8C25.9411 8 34 16.0589 34 26C34 27.4188 33.8359 28.7992 33.5255 30.1233Z" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 26.2615C0 17.5694 7.04579 10.523 15.7374 10.523C24.4289 10.523 31.4747 17.5694 31.4747 26.2615C31.4747 34.9536 24.4289 42 15.7374 42C7.04579 42 0 34.9536 0 26.2615ZM10.3929 26C10.3929 25.6242 10.4272 25.2565 10.4928 24.9H14.9643C15.5166 24.9 15.9643 24.4523 15.9643 23.9C15.9643 23.3477 15.5166 22.9 14.9643 22.9H11.2494C12.2948 21.1608 14.1931 20 16.3571 20C17.8838 20 19.2751 20.5758 20.3312 21.5259C20.7418 21.8952 21.3741 21.8618 21.7434 21.4512C22.1128 21.0406 22.0794 20.4084 21.6688 20.039C20.2607 18.7722 18.3978 18 16.3571 18C13.05 18 10.2181 20.023 9.01305 22.9H8C7.44772 22.9 7 23.3477 7 23.9C7 24.4523 7.44772 24.9 8 24.9H8.46757C8.41831 25.2596 8.39286 25.6269 8.39286 26C8.39286 26.3731 8.41831 26.7404 8.46757 27.1H8C7.44772 27.1 7 27.5477 7 28.1C7 28.6523 7.44772 29.1 8 29.1H9.01305C10.2181 31.977 13.05 34 16.3571 34C18.3978 34 20.2607 33.2278 21.6688 31.961C22.0794 31.5916 22.1128 30.9594 21.7434 30.5488C21.3741 30.1382 20.7418 30.1048 20.3312 30.4741C19.2751 31.4242 17.8838 32 16.3571 32C14.1931 32 12.2948 30.8392 11.2494 29.1H14.9643C15.5166 29.1 15.9643 28.6523 15.9643 28.1C15.9643 27.5477 15.5166 27.1 14.9643 27.1H10.4928C10.4272 26.7435 10.3929 26.3758 10.3929 26Z" />
</svg>
<svg *ngIf="name === 'briefcase-filled'" [class]="style" viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17 0C15.6739 0 14.4021 0.526784 13.4645 1.46447C12.5268 2.40215 12 3.67392 12 5V8H5C2.23858 8 0 10.2386 0 13V33C0 35.7614 2.23858 38 5 38H37C39.7614 38 42 35.7614 42 33V13C42 10.2386 39.7614 8 37 8H30V5C30 3.67392 29.4732 2.40215 28.5355 1.46447C27.5979 0.526784 26.3261 0 25 0H17ZM14 8H28V5C28 4.20435 27.6839 3.44129 27.1213 2.87868C26.5587 2.31607 25.7956 2 25 2H17C16.2043 2 15.4413 2.31607 14.8787 2.87868C14.3161 3.44129 14 4.20435 14 5V8ZM21 17C21.5523 17 22 17.4477 22 18V26C22 26.5523 21.5523 27 21 27C20.4477 27 20 26.5523 20 26V18C20 17.4477 20.4477 17 21 17ZM6.15288 23.1529C6.5434 22.7624 7.17657 22.7624 7.56709 23.1529L13.2271 28.8129C13.6176 29.2034 13.6176 29.8366 13.2271 30.2271C12.8366 30.6176 12.2034 30.6176 11.8129 30.2271L6.15288 24.5671C5.76235 24.1766 5.76235 23.5434 6.15288 23.1529ZM35.8471 24.5671C36.2376 24.1766 36.2376 23.5434 35.8471 23.1529C35.4566 22.7624 34.8234 22.7624 34.4329 23.1529L28.7729 28.8129C28.3824 29.2034 28.3824 29.8366 28.7729 30.2271C29.1634 30.6176 29.7966 30.6176 30.1871 30.2271L35.8471 24.5671Z"/>
</svg>
<svg *ngIf="name === 'investments-filled'" [class]="style" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M34.7279 4.63646C34.7279 2.07582 32.6521 0 30.0914 0H4.63643C2.07579 0 0 2.07582 0 4.63646V30.0917C0 32.6523 2.07579 34.7282 4.63643 34.7282H30.0914C32.6521 34.7282 34.7279 32.6523 34.7279 30.0917V4.63646ZM32.7279 27.4552H2V30.0917C2 31.5478 3.18038 32.7282 4.63643 32.7282H30.0914C31.5475 32.7282 32.7279 31.5478 32.7279 30.0917V27.4552ZM38.5255 8.77132C38.8289 8.30982 39.449 8.18164 39.9104 8.48503C40.4192 8.8195 40.8572 9.25092 41.1992 9.75465C41.5413 10.2584 41.7808 10.8246 41.904 11.4208C42.0273 12.017 42.0319 12.6317 41.9176 13.2296L37.1448 38.234C36.9139 39.4413 36.213 40.5079 35.1962 41.1987C34.1794 41.8895 32.9299 42.1482 31.7224 41.9179L15.8125 38.8813C15.27 38.7777 14.9142 38.254 15.0177 37.7115C15.1213 37.169 15.645 36.8132 16.1875 36.9167L32.097 39.9533C32.7836 40.0842 33.4942 39.9371 34.0723 39.5443C34.6505 39.1516 35.049 38.5452 35.1803 37.8587L39.9531 12.8547C40.0181 12.5146 40.0155 12.1648 39.9454 11.8257C39.8753 11.4866 39.7392 11.1646 39.5447 10.8782C39.3502 10.5918 39.1011 10.3464 38.8118 10.1562C38.3503 9.85287 38.2221 9.23281 38.5255 8.77132Z" fill="#E54937"/>
</svg>
<svg *ngIf="name === 'x-close'" [class]="style" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13 1.5L1 13.5M1 1.5L13 13.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'log-in'" [class]="style" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M25 1H33C34.0609 1 35.0783 1.42143 35.8284 2.17157C36.5786 2.92172 37 3.93913 37 5V33C37 34.0609 36.5786 35.0783 35.8284 35.8284C35.0783 36.5786 34.0609 37 33 37H25M15 29L25 19M25 19L15 9M25 19H1" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'user'" [class]="style" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M33 37V33C33 30.8783 32.1571 28.8434 30.6569 27.3431C29.1566 25.8429 27.1217 25 25 25H9C6.87827 25 4.84344 25.8429 3.34315 27.3431C1.84285 28.8434 1 30.8783 1 33V37M25 9C25 13.4183 21.4183 17 17 17C12.5817 17 9 13.4183 9 9C9 4.58172 12.5817 1 17 1C21.4183 1 25 4.58172 25 9Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'user-filled'" [class]="style" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M33 37V33C33 30.8783 32.1571 28.8434 30.6569 27.3431C29.1566 25.8429 27.1217 25 25 25H9C6.87827 25 4.84344 25.8429 3.34315 27.3431C1.84285 28.8434 1 30.8783 1 33V37M25 9C25 13.4183 21.4183 17 17 17C12.5817 17 9 13.4183 9 9C9 4.58172 12.5817 1 17 1C21.4183 1 25 4.58172 25 9Z" stroke-linecap="round" stroke-linejoin="round" fill="#E54937"/>
</svg>
<svg *ngIf="name === 'eye-off'" [class]="style" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18.8 7.48C20.1767 7.15776 21.5861 6.99668 23 7C37 7 45 23 45 23C43.786 25.2712 42.3381 27.4095 40.68 29.38M27.24 27.24C26.6907 27.8295 26.0283 28.3023 25.2923 28.6302C24.5563 28.9582 23.7618 29.1345 22.9562 29.1487C22.1506 29.1629 21.3503 29.0148 20.6032 28.713C19.8561 28.4112 19.1774 27.9621 18.6077 27.3923C18.0379 26.8226 17.5888 26.1439 17.287 25.3968C16.9852 24.6497 16.8371 23.8494 16.8513 23.0438C16.8655 22.2382 17.0418 21.4437 17.3698 20.7077C17.6977 19.9717 18.1705 19.3093 18.76 18.76M1 1L45 45M34.88 34.88C31.4612 37.486 27.2982 38.9297 23 39C9 39 1 23 1 23C3.48778 18.3638 6.93827 14.3132 11.12 11.12L34.88 34.88Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'credit-card-up'" [class]="style" viewBox="0 0 46 37" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 13H45M28.5 33H5C2.79086 33 1 31.2091 1 29V5C1 2.79086 2.79086 1 5 1H41C43.2091 1 45 2.79086 45 5V24M43 29L38 24M38 24L33 29M38 24V36" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'check-circle'" [class]="style" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M41 20.16V22C40.9975 26.3128 39.601 30.5094 37.0187 33.9637C34.4363 37.418 30.8066 39.945 26.6707 41.1678C22.5349 42.3906 18.1145 42.2438 14.0689 40.7492C10.0234 39.2545 6.56931 36.4922 4.22192 32.8741C1.87453 29.2561 0.759582 24.9761 1.04335 20.6726C1.32712 16.3691 2.99441 12.2727 5.79656 8.99415C8.59871 5.71564 12.3856 3.43077 16.5924 2.4803C20.7992 1.52982 25.2005 1.96468 29.14 3.72M41 6L21 26.02L15 20.02" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'id-card'" [class]="style" viewBox="0 0 46 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M25 16.5H39M25 9.5H39M19 25C19 22.0052 18.3527 20.3333 14.4667 20.3333H12.5333C8.57208 20.3333 8 21.9402 8 25M5 1H41C43.2091 1 45 2.79086 45 5V29C45 31.2091 43.2091 33 41 33H5C2.79086 33 1 31.2091 1 29V5C1 2.79086 2.79086 1 5 1ZM18.0333 12.4444C18.0333 14.899 16.0037 16.8889 13.5 16.8889C10.9963 16.8889 8.96667 14.899 8.96667 12.4444C8.96667 9.98985 10.9963 8 13.5 8C16.0037 8 18.0333 9.98985 18.0333 12.4444Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'search'" [class]="style" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M37 37L28.3 28.3M33 17C33 25.8366 25.8366 33 17 33C8.16344 33 1 25.8366 1 17C1 8.16344 8.16344 1 17 1C25.8366 1 33 8.16344 33 17Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'telegram'" [class]="style" viewBox="-10 0 70 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4.02477 17.0955C15.8359 11.9496 23.7118 8.55708 27.6526 6.91799C38.9042 2.23805 41.2422 1.4251 42.7661 1.39797C43.1012 1.39235 43.8506 1.47541 44.3361 1.8693C44.7459 2.20189 44.8587 2.65118 44.9127 2.96651C44.9667 3.28185 45.0339 4.00019 44.9804 4.56148C44.3707 10.968 41.7324 26.5148 40.3902 33.6902C39.8223 36.7263 38.704 37.7444 37.6213 37.844C35.2685 38.0605 33.4819 36.2891 31.2031 34.7953C27.6372 32.4578 25.6227 31.0027 22.1613 28.7217C18.1612 26.0857 20.7543 24.6369 23.034 22.2691C23.6306 21.6494 33.9972 12.2202 34.1978 11.3648C34.2229 11.2579 34.2462 10.8591 34.0093 10.6485C33.7724 10.438 33.4227 10.51 33.1704 10.5672C32.8128 10.6484 27.1162 14.4136 16.0807 21.8629C14.4637 22.9732 12.9992 23.5142 11.6869 23.4859C10.2403 23.4546 7.45761 22.6679 5.38895 21.9955C2.85165 21.1707 0.835059 20.7347 1.01066 19.3339C1.10212 18.6044 2.10683 17.8582 4.02477 17.0955Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg *ngIf="name === 'user-x'" [class]="style" viewBox="0 0 46 38" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M31 37V33C31 30.8783 30.1571 28.8434 28.6569 27.3431C27.1566 25.8429 25.1217 25 23 25H9C6.87827 25 4.84344 25.8429 3.34315 27.3431C1.84285 28.8434 1 30.8783 1 33V37M35 11L45 21M45 11L35 21M24 9C24 13.4183 20.4183 17 16 17C11.5817 17 8 13.4183 8 9C8 4.58172 11.5817 1 16 1C20.4183 1 24 4.58172 24 9Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
