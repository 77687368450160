import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import * as momentNs from 'moment';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { TranslateService } from '@ngx-translate/core';
import { MarketPlaceBidCreateModel, MarketPlaceOfferModel, MarketPlaceProjectModel } from 'src/app/shared/models/marketplace.models';
import { HelpService } from 'src/app/services/help.service';
const moment = momentNs;

@Component({
  selector: 'fun-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit, AfterViewInit {

  @Input() project: MarketPlaceProjectModel;
  @Input() hasWalletOk: MarketPlaceProjectModel;
  @Input() headerInfo: any;
  @Output() private eventReturnProjects = new EventEmitter<void>();
  @Output() private emitCreateBidToOffer = new EventEmitter<MarketPlaceBidCreateModel>();
  @Output() private eventCreateOffer = new EventEmitter<any>();
  @Output() private eventNavHeader = new EventEmitter<any>();

  public years;
  public months;

  btnType: string;

  @ViewChild('marketplace', { static: false }) marketplace: ElementRef;
  @ViewChild('bid', { static: false }) bid: ElementRef;
  @ViewChild('offer', { static: false }) offer: ElementRef;
  elements: any;
  isShow = true;
  hasOffers: boolean;
  questions = [
    {
      text: this.translate.instant('HELP.MARKETPLACE_DETAILPROJ.Q1.TITLE'),
      index: 1,
      content: [
        this.translate.instant('HELP.MARKETPLACE_DETAILPROJ.Q1.TEXT_1',
        this.translate.instant('HELP.MARKETPLACE_DETAILPROJ.Q1.TEXT_2'))
      ]
    },
    {
      text: this.translate.instant('HELP.MARKETPLACE_DETAILPROJ.Q2.TITLE'),
      index: 2,
      content: [
        this.translate.instant('HELP.MARKETPLACE_DETAILPROJ.Q2.TEXT_1',
        this.translate.instant('HELP.MARKETPLACE_DETAILPROJ.Q2.TEXT_2'))
      ]
    },
    {
      text: this.translate.instant('HELP.MARKETPLACE_DETAILPROJ.Q3.TITLE'),
      index: 3,
      content: [
        this.translate.instant('HELP.MARKETPLACE_DETAILPROJ.Q3.TEXT_1',
        this.translate.instant('HELP.MARKETPLACE_DETAILPROJ.Q3.TEXT_2'))
      ]
    },
  ];

  constructor(private translate: TranslateService, private helpService: HelpService) { }


  ngOnInit() {
    registerLocaleData(es);
    this.getData();
  }

  ngAfterViewInit() {
    this.elements = [this.marketplace, this.bid, this.offer];
  }
  public getData() {
    if (this.project.offers !== undefined) {
      this.hasOffers = true;
    } else {
      this.hasOffers = false;
    }
    const startDate = moment(new Date(), 'YYYY/MM/DD');
    const endDate = moment(this.project.end_util_date, 'YYYY/MM/DD');

    this.years = endDate.diff(startDate, 'years');
    this.months = endDate.diff(startDate, 'months') - (this.years * 12);
    if (this.hasWalletOk) {
      this.btnType = 'primaryDark';
    } else {
      this.btnType = 'primaryDark-disabled';
    }
  }

  selected(el) {
    this.elements.forEach(element => {
      if (el.id === element.nativeElement.id) {
        element.nativeElement.setAttribute('class', 'selected');
      } else {
        element.nativeElement.setAttribute('class', 'link');
      }
    });
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  returnProjects() {
    this.eventReturnProjects.emit();
  }

  onEmitCreateBid(offer: MarketPlaceOfferModel) {
    const createBidObj: MarketPlaceBidCreateModel = {
      offer_id: offer.id,
      project_id: this.project.id,
      name_project: this.project.name,
      technology_project: this.project.technology,
      name_seller: offer.name,
      amount: offer.amount,
      participation_id: offer.participation_id,
      status: offer.status,
      formalization_type: offer.formalization_type,
      adquisition_price: offer.adquisition_price,
      current_bids: offer.current_bids,
      date: offer.date,
      closing_date: offer.closing_date,
    };
    this.emitCreateBidToOffer.emit(createBidObj);
  }
  createOffer() {
    this.eventCreateOffer.emit();
  }
  projectNav(evt) {
    this.eventNavHeader.emit(evt);
  }
  questionClicked(index) {
    this.helpService.openHelp('second', index, 'MARKETPLACE_DETAILPROJ');
  }
  openHelp(level, index) {
    this.helpService.openHelp(level, index, 'BID_STATUS');
  }
}
