<img class="info" src="../../assets/images/img/info.svg" alt="info" (click)="openHelp('first', 0)">
<div class="container-header">
    <div class="container">
        <ul [class]="selectIsOpen ? 'open' : ''">
            <div (click)="selectOpen()" class="header-open" *ngIf="selectIsOpen">
                <h3>Estado de la puja</h3>
            </div>
            <li class="project-title font-sans text-eggplant-100" (click)="selectOpen()">
                <span class="text-m text-eggplant-100/[0.55]">{{ 'bid-status.project' | translate }}</span>
                <span class="text-l">
                {{ bidData.project_name }}</span>
                <span class="hidden-lg text-l">{{ bidData.offer_amount }} {{ 'bid-status.shares' | translate }}</span>
            </li>
            <li class="font-sans text-eggplant-100">
                <span class="text-m text-eggplant-100/[0.55]">{{ 'bid-status.seller' | translate }}</span>
                <span class="avt-content" *ngIf="nameSeller && nameSeller !== undefined">
                    <span class="avatar text-l text-white">{{ nameSeller | nameProfile }}</span>
                    <span class="avt-name text-l">{{ nameSeller }}</span>
                </span>
                <span class="avt-name text-l" *ngIf="!nameSeller || nameSeller === undefined">
                    {{ 'bid-status.loading' | translate }}...
                </span>
            </li>
            <li class="font-sans text-eggplant-100">
                <span class="text-m text-eggplant-100/[0.55]">{{ 'bid-status.shares' | translate }}</span>
                <span class="text-l">{{ bidData.offer_amount }}</span>
            </li>
            <li class="font-sans text-eggplant-100">
                <span class="text-m text-eggplant-100/[0.55]">{{ 'bid-status.your-bid' | translate }}</span>
                <span class="text-l">{{ bidData.price | localeNumber }} €</span>
            </li>
            <li class="font-sans text-eggplant-100">
                <span class="text-m text-eggplant-100/[0.55]">{{ 'bid-status.value' | translate }}</span>
                <span class="text-l">{{ bidData.adquisition_price | localeNumber }} €</span>
            </li>
            <li class="font-sans text-eggplant-100">
                <span class="text-m text-eggplant-100/[0.55]">{{ 'bid-status.formalization' | translate }}</span>
                <span class="text-l" *ngIf="bidData.formalization_type == 'internal'">{{ 'bid-status.internal' | translate }}</span>
                <span class="text-l" *ngIf="bidData.formalization_type == 'external'">{{ 'bid-status.external' | translate }}</span>
            </li>
            <li class="font-sans text-eggplant-100">
                <span class="text-m text-eggplant-100/[0.55]">{{ 'bid-status.time-remaining' | translate }}</span>
                <span class="text-l" *ngIf="bidData.status == 'won' || bidData.offer_status == 'sold' || bidData.status == 'lost'">-</span>
                <span class="text-l" *ngIf="bidData.status == 'active'">{{ bidData.decision_due_date | timeLeft }}</span>
            </li>
        </ul>
        <div class="overlay" (click)="selectOpen()"></div>
    </div>
</div>


<ng-container *ngFor="let stateTmpl of statesTmpl">
    <ng-container *ngIf="stateTmpl.state === currentState">
        <ng-container [ngTemplateOutlet]="this[stateTmpl.state]"></ng-container>
    </ng-container>
</ng-container>


<ng-template #activeActive>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li>
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li>
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.pending-decision' | translate }}</span>
                </li>
            </ul>
        </div>

        <div class="row">
            <div class="content-background-modal">
                <section class="content-boxed row">
                    <div class="col-6 space">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'bid-status.bid-sent' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'bid-status.bid-sent-text' | translate }}</p>
                        <fun-new-button [buttonType]="cancelBtn" title="{{ 'bid-status.cancel-bid' | translate }}" (eventClick)="onEventClickCancelBid(bidData.id)"></fun-new-button>
                    </div>
                    <div class="col-6 space">
                        <ul class="list-check [&>li]:font-sans [&>li]:text-l [&>li]:text-eggplant-100/[0.55]" [innerHTML]="'bid-status.bid-sent-list-text' | translate">
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #activePendingAcceptation>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li>
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.pending-decision' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal">
                <section class="content-boxed row">
                    <div class="col-6 space">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'bid-status.bid-decision-title' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'bid-status.bid-decision-text' | translate }}</p>
                        <fun-new-button [buttonType]="btnAccept" title="{{ 'bid-status.view-projects' | translate }}" (eventClick)="onEventClickRoute('list-of-projects')"></fun-new-button>
                    </div>
                    <div class="col-6 space">
                        <ul class="list-check [&>li]:font-sans [&>li]:text-l [&>li]:text-eggplant-100/[0.55]" [innerHTML]="'bid-status.bid-decision-list-text' | translate">
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #lostNotSold>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.lost-bid' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal">
                <section class="content-boxed row">
                    <div class="col-6 space">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'bid-status.bid-resolution-title' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'bid-status.bid-resolution-text' | translate }}</p>
                        <fun-new-button [buttonType]="btnAccept" title="{{ 'bid-status.view-projects' | translate }}" (eventClick)="onEventClickRoute('marketplace')"></fun-new-button>
                    </div>
                    <div class="col-6"></div>
                </section>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #soldExternalWaitingContractsOne>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.won-bid' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="selected item-clickable" (click)="onClickChangeState('soldExternalWaitingContractsOne')">
                                <span class="title">{{ 'bid-status.seller-contact' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.after-acceptance-bid' | translate }}</span>
                                <div class="tab-content hidden-lg">
                                    <h3></h3>
                                    <p>{{ 'bid-status.details-seller-text' | translate }}</p>
                                    <div *ngIf="contactData" class="avatar-box">
                                        <span *ngIf="contactData" class="avatar profile">{{ contactData?.sellerName  + ' ' +  contactData?.sellerSurname | nameProfile}}</span>
                                        <div *ngIf="contactData" class="avatar-text">
                                            <p>{{ contactData?.sellerName  + ' ' +  contactData?.sellerSurname }}</p>
                                            <p>{{ contactData?.sellerPhone }}</p>
                                            <p class="buyerMail">{{ contactData?.sellerMail }}</p>
                                        </div>
                                    </div>
                                    <div *ngIf="!contactData" class="avatar-box">
                                        {{ 'bid-status.loading' | translate }}...
                                    </div>
                                </div>
                            </li>
                            <li class="item-clickable" (click)="onClickChangeState('soldExternalWaitingContractsTwo')">
                                <span class="title">{{ 'bid-status.contract-upload' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.until' | translate }} {{ bidData.external_waiting_documentation_due_date | date:'dd/MM/yyyy' }}</span>
                                <fun-new-button [size]="'l'"  buttonType="primaryDark" classExtra="mt-[12px]" title="{{ 'bid-create.continue' | translate }}"></fun-new-button>
                            </li>
                            <li>
                                <span class="title">{{ 'bid-status.change-ownership' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.after-validity-check' | translate }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'bid-status.seller-contact' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'bid-status.details-seller-text' | translate }}</p>
                        <div *ngIf="contactData" class="avatar-box">
                            <span class="avatar profile">{{ contactData?.sellerName  + ' ' +  contactData?.sellerSurname | nameProfile}}</span>
                            <div class="avatar-text">
                                <p>{{ contactData?.sellerName  + ' ' +  contactData?.sellerSurname }}</p>
                                <p>{{ contactData?.sellerPhone }}</p>
                                <p>{{ contactData?.sellerMail }}</p>
                            </div>
                        </div>
                        <div *ngIf="!contactData" class="avatar-box">
                            {{ 'bid-status.loading' | translate }}...
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #soldExternalWaitingContractsTwo>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.won-bid' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="done item-clickable" (click)="onClickChangeState('soldExternalWaitingContractsOne')">
                                <span class="title">{{ 'bid-status.seller-contact' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.after-acceptance-bid' | translate }}</span>
                            </li>
                            <li class="selected item-clickable" (click)="onClickChangeState('soldExternalWaitingContractsTwo')">
                                <span class="title">{{ 'bid-status.contract-upload' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.until' | translate }} {{ bidData.external_waiting_documentation_due_date | date:'dd/MM/yyyy' }}</span>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'bid-status.contract-upload-text' | translate }}</p>
                                    <fun-file [buttonType]="fileButtonData.buttonType" [buttonTitle]="'bid-status.upload-file' | translate" [buttonAccept]="fileButtonData.buttonAccept" [urlFile]="fileButtonData.urlFile" [paramsFile]="fileButtonData.paramsFile" [showProgress]="fileButtonData.showProgress" [token]="fileButtonData.token"
                                    (eventUploadResponse)="onEventUploadResponse($event)"></fun-file>
                                </div>
                            </li>
                            <li>
                                <span class="title">{{ 'bid-status.change-ownership' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.after-validity-check' | translate }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'bid-status.contract-upload' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'bid-status.contract-upload-text' | translate }}</p>
                        <fun-file [buttonType]="fileButtonData.buttonType" [buttonTitle]="'bid-status.upload-file' | translate" [buttonAccept]="fileButtonData.buttonAccept" [urlFile]="fileButtonData.urlFile" [paramsFile]="fileButtonData.paramsFile" [showProgress]="fileButtonData.showProgress" [token]="fileButtonData.token"
                        (eventUploadResponse)="onEventUploadResponse($event)"></fun-file>
                    </div>
                </section>
            </div>
        </div>
    </div>

</ng-template>

<!---->
<!--<ng-template #soldExternalContractsKO>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab">
                <li class="done">
                    <span class="number">1</span>
                    <span class="title">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="mark mark-null">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number">2</span>
                    <span class="title">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number">3</span>
                    <span class="title">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="mark mark-null">{{ 'bid-status.won-bid' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="selected">
                                <span class="title">{{ 'bid-status.seller-contact' | translate }}</span>
                                <div class="tab-content hidden-lg">
                                    <h3></h3>
                                    <p>{{ 'bid-status.contract-upload-ko-text' | translate }}</p>
                                </div>
                            </li>
                            <li>
                                <span class="title">{{ 'bid-status.contract-upload' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.until' | translate }} {{ bidData.external_waiting_documentation_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li>
                                <span class="title">{{ 'bid-status.change-ownership' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.after-validity-check' | translate }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3>{{ 'bid-status.contract-upload' | translate }}</h3>
                        <p>{{ 'bid-status.contract-upload-ko-text' | translate }}</p>
                    </div>
                </section>
            </div>
        </div>
    </div>

</ng-template>-->
<!---->

<ng-template #soldExternalWaitingContractsKO>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-expired' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="done">
                                <span class="title">{{ 'bid-status.seller-contact' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.after-acceptance-bid' | translate }}</span>
                            </li>
                            <li class="selected">
                                <span class="title">{{ 'bid-status.contract-upload' | translate }}</span>
                                <span class="title">{{ 'bid-status.until' | translate }} {{ bidData.external_waiting_documentation_due_date | date:'dd/MM/yyyy' }}</span>
                                <div class="tab-content hidden-lg">
                                    <h3></h3>
                                    <p>{{ 'bid-status.contract-upload-ko-text' | translate }}</p>
                                </div>
                            </li>
                            <li class="future">
                                <span class="title">{{ 'bid-status.change-ownership' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.after-validity-check' | translate }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'bid-status.contract-upload' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'bid-status.contract-upload-ko-text' | translate }}</p>
                    </div>
                </section>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #soldExternalValidating>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.won-bid' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="done">
                                <span class="title">{{ 'bid-status.seller-contact' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.after-acceptance-bid' | translate }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'bid-status.contract-upload' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.uploaded' | translate }} {{ bidData.contract_buyer_date | date:'dd/MM/yyyy' }}</span>
                                <p class="font-sans text-m text-orange-100 item-clickable" (click)="changeToResendDocumentStatus()">{{ 'offer-status.resend-documents' | translate }}</p>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'bid-status.contract-validating-text' | translate }}</p>
                                    <fun-new-button [buttonType]="btnSecondary" title="{{ 'bid-status.back-my-bids' | translate }}" (eventClick)="onEventClickRoute('my-bids')"></fun-new-button>
                                </div>
                            </li>
                            <li class="selected">
                                <span class="title">{{ 'bid-status.change-ownership' | translate }}</span>
                                <span class="title">{{ 'bid-status.after-validity-check' | translate }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'bid-status.change-ownership' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'bid-status.contract-validating-text' | translate }}</p>
                        <fun-new-button [buttonType]="btnSecondary" title="{{ 'WALLET_STATUS.PENDING.BTN' | translate }}" (eventClick)="contactByMail()"></fun-new-button>
                    </div>
                </section>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #soldExternalOK>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.won-bid' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="done">
                                <span class="title">{{ 'bid-status.seller-contact' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.after-acceptance-bid' | translate }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'bid-status.contract-upload' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.uploaded' | translate }} {{ bidData.contract_buyer_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'bid-status.change-ownership' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.made-on' | translate }} {{ bidData.ok_date | date:'dd/MM/yyyy' }}</span>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'bid-status.ok-external-text' | translate:{'offer_amount':bidData.offer_amount, 'project_name':bidData.project_name} }}</p>
                                    <fun-new-button [buttonType]="btnAccept" title="{{ 'bid-status.view-my-projects' | translate }}" (eventClick)="onEventClickRoute('list-projects')"></fun-new-button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'bid-status.change-ownership' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'bid-status.ok-external-text' | translate:{'offer_amount':bidData.offer_amount, 'project_name':bidData.project_name} }}</p>
                        <fun-new-button [buttonType]="btnAccept" title="{{ 'bid-status.view-my-projects' | translate }}" (eventClick)="onEventClickRoute('list-projects')"></fun-new-button>
                    </div>
                </section>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #soldExternalKO>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.canceled-bid' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="done">
                                <span class="title">{{ 'bid-status.seller-contact' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.after-acceptance-bid' | translate }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'bid-status.contract-upload' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.uploaded' | translate }} {{ bidData.contract_buyer_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'bid-status.change-ownership' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.cancel-on' | translate }} {{ bidData.ko_date | date:'dd/MM/yyyy' }}</span>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'bid-status.ko-external-text' | translate }}</p>
                                    <fun-new-button [buttonType]="btnAccept" title="{{ 'bid-status.contact-us' | translate }}" (eventClick)="onEventClickRoute('contact-us')"></fun-new-button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'bid-status.change-ownership' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'bid-status.ko-external-text' | translate }}</p>
                        <fun-new-button [buttonType]="btnAccept" title="{{ 'bid-status.contact-us' | translate }}" (eventClick)="onEventClickRoute('contact-us')"></fun-new-button>
                    </div>
                </section>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #soldInternalWaitingSignBuyer>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.won-bid' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list digital-way">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="selected">
                                <span class="title">{{ 'bid-status.seller-sign' | translate }}</span>
                                <!--<span class="title">{{ 'bid-status.buyer-sign' | translate }}</span>-->
                                <span class="title">{{ 'bid-status.before' | translate }} {{ bidData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'bid-status.sign-text-buyer' | translate }}</p>
                                </div>
                            </li>
                            <li>
                                <span class="title">{{ 'bid-status.buyer-sign' | translate }}</span>
                                <!--<span class="title">{{ 'bid-status.seller-sign' | translate }}</span>-->
                                <span class="mark mark-null">{{ 'bid-status.before' | translate }} {{ bidData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li>
                                <span class="title">{{ 'bid-status.administrator-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.before' | translate }} {{ bidData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li>
                                <span class="title">{{ 'bid-status.pay' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.before' | translate }} {{ bidData.internal_waiting_payment_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li>
                                <span class="title">{{ 'bid-status.after-check-pay' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.change-ownership' | translate }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'bid-status.seller-sign' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'bid-status.sign-text-seller' | translate }}</p>
                    </div>
                </section>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #soldInternalWaitingSignSeller>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.won-bid' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list digital-way">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="done">
                                <span class="title">{{ 'bid-status.seller-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.before' | translate }} {{ bidData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li [ngClass]="{'selected': bidData.signed_buyer === false && bidData.signed_seller === true,
                            'done': bidData.signed_buyer === true && bidData.signed_seller === true }">
                                <span class="title">{{ 'bid-status.buyer-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.before' | translate }} {{ bidData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'bid-status.sign-text-seller' | translate }}</p>
                                    <fun-new-button [buttonType]="'secondaryDark'" title="{{ 'bid-status.back-my-bids' | translate }}"></fun-new-button>
                                </div>
                            </li>
                            <li [ngClass]="{'selected': bidData.signed_buyer === true && bidData.signed_seller === true }">
                                <span class="title">{{ 'offer-status.administrator-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.before' | translate }} {{ bidData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li>
                                <span class="title">{{ 'bid-status.pay' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.before' | translate }} {{ bidData.internal_waiting_payment_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li>
                                <span class="title">{{ 'bid-status.after-check-pay' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.change-ownership' | translate }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100" *ngIf="bidData.signed_buyer === false && bidData.signed_seller === true">{{ 'bid-status.buyer-sign' | translate }}</h3>
                        <h3 class="font-sans text-xl text-eggplant-100" *ngIf="bidData.signed_buyer === true && bidData.signed_seller === true">{{ 'offer-status.administrator-sign' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24" *ngIf="bidData.signed_buyer === false && bidData.signed_seller === true">{{ 'bid-status.sign-text-buyer' | translate }}</p>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24" *ngIf="bidData.signed_buyer === true && bidData.signed_seller === true">{{ 'offer-status.sign-text-administrator' | translate }}</p>
                        <fun-new-button [buttonType]="btnSecondary" title="{{ 'bid-status.back-my-bids' | translate }}" (eventClick)="onEventClickRoute('my-bids')"></fun-new-button>
                    </div>
                </section>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #soldInternalWaitingSignBuyerKO>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-expired' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal">
                <section class="content-boxed row">
                    <div class="col-6 space">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'bid-status.bid-resolution' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'bid-status.ko-sign-buyer-text' | translate }}</p>
                        <fun-new-button [buttonType]="btnAccept" title="{{ 'bid-status.view-projects' | translate }}" (eventClick)="onEventClickRoute('list-projects')"></fun-new-button>
                    </div>
                    <div class="col-6"></div>
                </section>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #soldInternalWaitingSignSellerKO>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-expired' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal">
                <section class="content-boxed row">
                    <div class="col-6 space">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'bid-status.bid-resolution' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'bid-status.ko-sign-seller-text' | translate }}</p>
                        <fun-new-button [buttonType]="btnAccept" title="{{ 'bid-status.view-projects' | translate }}" (eventClick)="onEventClickRoute('list-projects')"></fun-new-button>
                    </div>
                    <div class="col-6"></div>
                </section>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #soldInternalWaitingAdminKO>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.won-bid' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal">
                <section class="content-boxed row">
                    <div class="col-6 space">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'bid-status.bid-resolution' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'bid-status.ko-sign-admin-text' | translate }}</p>
                        <fun-new-button [buttonType]="btnAccept" title="{{ 'bid-status.view-projects' | translate }}" (eventClick)="onEventClickRoute('list-projects')"></fun-new-button>
                    </div>
                    <div class="col-6"></div>
                </section>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #soldInternalWaitingPayment>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.won-bid' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list digital-way">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="done">
                                <span class="title">{{ 'bid-status.buyer-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.before' | translate }} {{ bidData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'bid-status.seller-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.before' | translate }} {{ bidData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'offer-status.administrator-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.before' | translate }} {{ bidData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="selected">
                                <span class="title">{{ 'bid-status.pay' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.before' | translate }} {{ bidData.internal_waiting_payment_due_date | date:'dd/MM/yyyy' }}</span>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'bid-status.pay-text' | translate }}</p>
                                    <p *ngIf="walletData?.balance && walletData?.balance < bidData.price">{{ 'bid-status.balance-text' | translate }}</p>
                                    <ul class="list-confirm card-type">
                                        <li>
                                            <span>{{ 'bid-status.balance-available' | translate }}</span>
                                            <span *ngIf="walletData">{{ walletData?.balance | localeNumber }} €</span>
                                            <span *ngIf="!walletData">
                                                {{ 'bid-status.loading' | translate }}...
                                            </span>
                                        </li>
                                        <li>
                                            <span>{{ 'bid-status.amount-pay' | translate }}</span>
                                            <span>{{ bidData.price | localeNumber }} €</span>
                                        </li>
                                    </ul>
                                    <fun-new-button
                                    *ngIf="walletData?.balance && walletData?.balance >= bidData.price && !isPayProcess"
                                    [buttonType]="btnAccept"
                                    (eventClick)="onEventClickPayBid()"
                                    title="{{ 'bid-status.pay-now' | translate }}"></fun-new-button>
                                    <div *ngIf="isPayProcess">
                                        {{ 'bid-status.paying' | translate }}...
                                    </div>
                                    <fun-new-button
                                    *ngIf="walletData?.balance && walletData?.balance < bidData.price"
                                    [buttonType]="btnAccept"
                                    (eventClick)="onEventClickRechargeBalance()"
                                    title="{{ 'bid-status.recharge-balance' | translate }}"></fun-new-button>
                                </div>
                            </li>
                            <li>
                                <span class="title">{{ 'bid-status.after-check-pay' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.change-ownership' | translate }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'bid-status.pay' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-12">{{ 'bid-status.pay-text' | translate }}</p>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24" *ngIf="walletData?.balance && walletData?.balance < bidData.price">{{ 'bid-status.balance-text' | translate }}</p>
                        <ul class="list-confirm card-type">
                            <li>
                                <span>{{ 'bid-status.balance-available' | translate }}</span>
                                <span *ngIf="walletData">{{ walletData?.balance | localeNumber }} €</span>
                                <span *ngIf="!walletData">
                                    {{ 'bid-status.loading' | translate }}...
                                </span>
                            </li>
                            <li>
                                <span>{{ 'bid-status.amount-pay' | translate }}</span>
                                <span>{{ bidData.price | localeNumber }} €</span>
                            </li>
                        </ul>
                        <fun-new-button
                        *ngIf="walletData?.balance && walletData?.balance >= bidData.price && !isPayProcess"
                        [buttonType]="btnAccept"
                        (eventClick)="onEventClickPayBid()"
                        title="{{ 'bid-status.pay-now' | translate }}"></fun-new-button>
                        <div *ngIf="isPayProcess">
                            {{ 'bid-status.paying' | translate }}...
                        </div>
                        <fun-new-button
                        *ngIf="walletData?.balance && walletData?.balance < bidData.price"
                        [buttonType]="btnAccept"
                        (eventClick)="onEventClickRechargeBalance()"
                        title="{{ 'bid-status.recharge-balance' | translate }}"></fun-new-button>
                    </div>
                </section>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #soldInternalWaitingPaymentKO>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-expired' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal">
                <section class="content-boxed row">
                    <div class="col-6 space">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'bid-status.bid-resolution' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'bid-status.ko-pay-text' | translate }}</p>
                        <fun-new-button [buttonType]="btnAccept" title="{{ 'bid-status.view-projects' | translate }}" (eventClick)="onEventClickRoute('list-projects')"></fun-new-button>
                    </div>
                    <div class="col-6">
                    </div>
                </section>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #soldInternalOK>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.won-bid' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list digital-way">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="done">
                                <span class="title">{{ 'bid-status.buyer-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.signed' | translate }} {{ bidData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'bid-status.seller-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.signed' | translate }} {{ bidData.internal_waiting_sign_due_date |date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'offer-status.administrator-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.before' | translate }} {{ bidData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'bid-status.pay' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.payment-made' | translate }} {{ bidData.ok_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'bid-status.change-ownership' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.made-on' | translate }} {{ bidData.ok_date | date:'dd/MM/yyyy' }}</span>
                                <div class="tab-content hidden-lg">
                                    <h3></h3>
                                    <p>{{ 'bid-status.ok-internal-text' | translate:{'offer_amount':bidData.offer_amount, 'project_name':bidData.project_name} }}</p>
                                    <fun-new-button [buttonType]="btnAccept" title="{{ 'bid-status.view-my-projects' | translate }}" (eventClick)="onEventClickRoute('dashboard')"></fun-new-button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'bid-status.change-ownership' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'bid-status.ok-internal-text' | translate:{'offer_amount':bidData.offer_amount, 'project_name':bidData.project_name} }}</p>
                        <fun-new-button [buttonType]="btnAccept" title="{{ 'bid-status.view-my-projects' | translate }}" (eventClick)="onEventClickRoute('dashboard')"></fun-new-button>
                    </div>
                </section>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #soldInternalKO>

    <div class="container bid">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.bid-sent' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ bidData.date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.seller-decision' | translate }}</span>
                    <span class="mark mark-null text-eggplant-100/[0.2]">{{ 'bid-status.until' | translate }} {{ bidData.decision_due_date | date:'dd/MM/yyyy' }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.resolution' | translate }}</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'bid-status.canceled-bid' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal content-list">
                <section class="content-boxed row">
                    <div class="col-6">
                        <ul class="pagination-list">
                            <li class="done">
                                <span class="title">{{ 'bid-status.buyer-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.signed' | translate }} {{ bidData.internal_waiting_sign_due_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'bid-status.seller-sign' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.signed' | translate }} {{ bidData.internal_waiting_sign_due_date |date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'bid-status.pay' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.payment-made' | translate }} {{ bidData.ko_date | date:'dd/MM/yyyy' }}</span>
                            </li>
                            <li class="done">
                                <span class="title">{{ 'bid-status.change-ownership' | translate }}</span>
                                <span class="mark mark-null">{{ 'bid-status.cancel-on' | translate }} {{ bidData.ko_date | date:'dd/MM/yyyy' }}</span>
                                <div class="tab-content hidden-lg">
                                    <p class="margin-top">{{ 'bid-status.ko-internal-text' | translate }}</p>
                                    <fun-new-button [buttonType]="btnAccept" title="{{ 'bid-status.view-my-projects' | translate }}" (eventClick)="onEventClickRoute('list-projects')"></fun-new-button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <h3 class="font-sans text-xl text-eggplant-100">{{ 'bid-status.change-ownership' | translate }}</h3>
                        <p class="font-sans text-l text-eggplant-100/[0.55] pb-24">{{ 'bid-status.ko-internal-text' | translate }}</p>
                        <fun-new-button [buttonType]="btnAccept" title="{{ 'bid-status.view-my-projects' | translate }}" (eventClick)="onEventClickRoute('list-projects')"></fun-new-button>
                    </div>
                </section>
            </div>
        </div>
    </div>

</ng-template>


<!-- <div class="container-footer">
    <div class="container">
        <fun-new-button buttonType="secondary" title="Volver" class="button" (eventClick)="prevStep()"></fun-new-button>
        <fun-new-button buttonType="default" title="Continuar" class="button" (eventClick)="nextStep()"></fun-new-button>
    </div>
</div>

<div class="container hidden-lg">
    <div class="row">
        <ul class="pagination-tab children:font-sans children:text-xl show-all">
            <li *ngIf="stateValue == 1" (click)="nextStep()">
                <span class="number">1</span>
                <span class="title">Puja enviada</span>
            </li>
            <li *ngIf="stateValue == 2" (click)="nextStep()">
                <span class="number">2</span>
                <span class="title">Decisión de vendedor</span>
            </li>
            <li *ngIf="stateValue == 3" (click)="nextStep()">
                <span class="number">3</span>
                <span class="title">Resolución</span>
            </li>
        </ul>
    </div>
</div> -->

<div class="space-bottom"></div>
