import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { UtilsService } from 'src/app/services/utils.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-private-layout',
  templateUrl: './private-layout.component.html',
  styleUrls: ['./private-layout.component.scss'],
})
export class PrivateLayoutComponent implements OnInit {

  nameUser;
  menuOpened: boolean;
  currentBg: string;

  constructor(public utilsService: UtilsService, private storage: Storage, private router: Router) {}

  ngOnInit() {
    this.getUsername();
    this.toggleMenu(true);
    this.currentBg = 'bg-1';
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url.includes('/es/portfolio') || val.url.includes('/en/portfolio') || val.url.includes('/ca/portfolio')) {
          this.currentBg = 'bg-2';
        }
        if (val.url.includes('/es/movimientos') || val.url.includes('/en/movements') || val.url.includes('/ca/moviments')
        || val.url.includes('/es/calendario-de-pagos') || val.url.includes('/en/payment-calendar') || val.url.includes('/ca/calendari-de-pagaments')) {
          this.currentBg = 'bg-3';
        } if ( val.url.includes('/es/ayuda') || val.url.includes('/ca/ajuda') || val.url.includes('/en/help')) {
          this.currentBg = 'bg-4';
        }
        if (val.url.includes('es/mi-perfil') || val.url.includes('en/my-profile') || val.url.includes('ca/el-meu-perfil')) {
          this.currentBg = 'bg-5';
        }
        if (val.url.includes('es/retribucion-flexibe') || val.url.includes('en/flexible-remuneration') || val.url.includes('ca/retribució-flexibe')) {
          this.currentBg = 'bg-6';
        }
      }
    });
  }

  navigateTo(path) {
    this.utilsService.navigateTo('/' + path);
  }

  getUsername() {
    this.storage.get('NAME').then((val) => {
      this.nameUser = val;
    });
  }

  toggleMenu(value) {
    this.menuOpened = value;
    const el = document.getElementById(`sideMenu`);
    if (value) {
      el.style.width = `226px`;
      el.style.transition = 'width 1.5s';
    } else {
      el.style.width = '80px';
      el.style.transition = 'width 1.5s';
    }
  }

}
