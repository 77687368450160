<div class="general-container" [ngClass]="{'success': successOperation}">
  <img src="../../assets/images/img/info.svg" alt="info" class="info" (click)="openInfo()">
  <div class="row operation" *ngIf="!successOperation">
      <div class="col-12 first">
          <form [formGroup]="bankForm" (submit)="save()" >
              <fun-input
              (keyup)="controlForm()"
              placeholder="ej: Miguel Angel Pérez"
              [parentForm]="bankForm"
              [formControlKeys]="formControlKeys"
              [errorMessages]="errorMessages"
              [isSubmit]="isSubmit"
              controlName="bank"
              type="text"
              [errorLogin]="errorLogin"
              labelName="{{ 'MOVEMENTS.MONEY-OUT.NAME' | translate }}"
              ></fun-input>
              <fun-input
              (keyup)="controlForm()"
              placeholder="**** **** **** **** ****"
              [parentForm]="bankForm"
              [formControlKeys]="formControlKeys"
              [errorMessages]="errorMessages"
              [isSubmit]="isSubmit"
              controlName="iban"
              type="text"
              [errorLogin]="errorLogin"
              labelName="{{ 'MOVEMENTS.MONEY-OUT.IBAN-NUMBER' | translate }}"
              isIban="true"
              ></fun-input>
               <fun-new-button [buttonType]="sendXHR" [size]="'xl'" [classExtra]="' !w-full '" (eventClick)="save()"
               title="{{ 'MOVEMENTS.MONEY-OUT.CREATE-ACCOUNT' | translate }}" type="submit" class="col-12 button"></fun-new-button>
          </form>
      </div>
  </div>
  <div *ngIf="successOperation" class="sucess">
      <img src="../../assets/images/illustrations/plants.svg" alt="congratulations">
      <div class="col-12">
          <h2>{{ 'MOVEMENTS.MONEY-OUT.INCOMING-DATA' | translate }}</h2>
          <p>{{ 'MOVEMENTS.MONEY-OUT.CHECKING-INFO' | translate }}</p>
      </div>

      <div class="col-12 btn-container">
        <fun-new-button [buttonType]="'primaryDark'" [size]="'xl'" title="{{ 'MOVEMENTS.MONEY-OUT.VIEW-PROJECTS' | translate }}"
        (eventClick)="goProjects()" class="button" [classExtra]="' !w-full '"></fun-new-button>
      </div>
  </div>
</div>
