import { Component, OnInit, Input } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'fun-calendar-pay-overview',
  templateUrl: './calendar-pay-overview.component.html',
  styleUrls: ['./calendar-pay-overview.component.scss'],
})
export class CalendarPayOverviewComponent implements OnInit {

  @Input() calendarBenefits: any;

  benefitsByMonth = [];
  fullBenefits = [];

  constructor(private utils: UtilsService) { }

  ngOnInit() {
    for (let i = 0; i < this.calendarBenefits.length; i++) {
      for (let j = 0; j < this.calendarBenefits[i].benefits.length; j++) {
        this.calendarBenefits[i].benefits[j]['month'] = this.utils.parseMonthShort(this.calendarBenefits[i].date[1]);
        this.calendarBenefits[i].benefits[j]['day'] = this.calendarBenefits[i].date[2];
      }
    }
  }

}
