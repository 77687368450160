import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'fun-register-company-data',
  templateUrl: './register-company-data.component.html',
  styleUrls: ['./register-company-data.component.scss']
})
export class RegisterCompanyDataComponent implements OnInit {

  @Output() public sendInfoEvent = new EventEmitter<any>();
  @Output() public sendErrorEvent = new EventEmitter<any>();
  @Output() public submitEvent = new EventEmitter<any>();
  @Input() public data;
  @Input() isMobile: boolean;

  public companyDataForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorAmount = false;
  public errorConcept = false;

  selectIsOpen = false;

  cnaeList = [];
  cnaeListCharged = false;

  constructor(
    public chdRef: ChangeDetectorRef,
    public translate: TranslateService,
    private languageService: LanguageService,
    private http: HttpClient
  ) { }

  @HostListener('document:keyup.enter', ['$event']) onKeyupEnterHandler(event: KeyboardEvent) {
    if (this.companyDataForm.valid) {
      this.sendInfoEvent.emit(this.companyDataForm.value);
      this.submitEvent.emit();
    } else {
      if ((event.target as Element).id === 'socialDenomination') {
        document.getElementById('nif').focus();
      } else if ((event.target as Element).id === 'nif') {
        document.getElementById('nif').blur();
      }
      this.sendErrorEvent.emit();
      this.chdRef.detectChanges();
    }
  }

  ngOnInit() {
    this.languageService.loadTranslationsOverride(this.translate.currentLang).subscribe(resp => {
      this.translate.setTranslation(this.translate.currentLang, resp, true);
      this.getCnaeList();
    });
    this.companyDataForm = new FormGroup({
      socialDenomination: new FormControl(this.data.socialDenomination ? this.data.socialDenomination : '', [Validators.required, Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚ 1234567890]+$')]),
      nif: new FormControl(this.data.nif ? this.data.nif : '', [Validators.required, Validators.pattern('^[A-Z][0-9]{8}$')]),
      cnae: new FormControl(this.data.cnae ? this.data.cnae : (this.isMobile ? '0' : null), [Validators.required, Validators.min(0.01)]),
      web: new FormControl(this.data.web ? this.data.web : '')
    });
    this.formControlKeys = Object.keys(this.companyDataForm.controls);
    this.errorMessages = {
      required:  this.translate.instant('input-errors-messages.required'),
      pattern: this.translate.instant('input-errors-messages.format'),
    };
    this.getInfo();
    this.chdRef.detectChanges();
  }

  getCnaeList() {
    this.translate.get('CNAE_LIST').subscribe(
      (response) => {
        const num = Object.keys(response).length;
        for (let i = 0; i < num; i++) {
          const code = Object.keys(response)[i];
          this.translate.get('CNAE_LIST.' + code).subscribe(
            (response) => {
              const item = response;
              const cnaeList = {
                id: code,
                name: item
              };
              this.cnaeList.push(cnaeList);
              if(i === (num - 1)) {
                this.cnaeListCharged = true;
              }
            }
          );
        }
      }
    );
  }

  getInfo() {
    if (this.companyDataForm.valid) {
      this.sendInfoEvent.emit(this.companyDataForm.value);
    } else {
      this.sendErrorEvent.emit();
      this.chdRef.detectChanges();
    }
  }

  changeCnae() {
    document.getElementById('web').focus();
    this.getInfo();
  }

}
