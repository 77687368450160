import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { Storage } from '@ionic/storage';
import { SidemenuService } from './sidemenu.service';
import { NavigationEnd, Router } from '@angular/router';
import { ProfileService } from 'src/app/services/profile.service';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnInit {

  @Output() private toggleOpeneEvent = new EventEmitter<any>();

  nameUser;
  menuList = [
    {
      title: this.translate.instant('MENU.INV'),
      path: 'list-of-projects',
      icon: 'files',
      selected: false,
      reouteEs: '/es/listado-de-proyectos',
      reouteEn: '/ca/llistat-de-projectes',
      reouteCa: '/en/list-of-projects'
    },
    {
      title: this.translate.instant('MENU.WALLET'),
      path: 'portfolio',
      icon: 'portfolio',
      selected: false,
      reouteEs: '/es/portfolio',
      reouteEn: '/ca/portfolio',
      reouteCa: '/en/portfolio'
    },
    {
      title: this.translate.instant('MENU.MOVEMENTS'),
      path: 'movements',
      icon: 'coin',
      selected: false,
      reouteEs: '/es/movimientos',
      reouteEn: '/ca/movimientos',
      reouteCa: '/en/movements'
    },
    {
      title: this.translate.instant('MENU.HELP'),
      path: 'help',
      icon: 'help',
      selected: false,
      reouteEs: '/es/ayuda',
      reouteEn: '/ca/ajuda',
      reouteCa: '/en/help'
    },
  ];

  opened: boolean;
  hasRicEnergyLogo = false;

  constructor(
    public utilsService: UtilsService,
    private storage: Storage,
    private sideMenuService: SidemenuService,
    private router: Router,
    private profileService: ProfileService,
    private authService: AuthService,
    private translate: TranslateService,
    private chdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.opened = false;
    this.toggleMenu();
    this.getUsername();
    this.getPageSelected();
    this.sideMenuService.toggleMenuEvent.subscribe(() => {
      this.toggleMenu();
    });
    this.authService.profileGeneratedEvent.subscribe(() => {
      setTimeout(() => {
        this.getUsername();
      }, 400);
      this.chdRef.detectChanges();
    });
    this.storage.get('USERNAME').then(val => {
      const emailStrings = this.translate.instant('RIC_ENERGY.EMAILS');
      const emailsPatternList = emailStrings.split(',');
      for (let i = 0; i < emailsPatternList.length; i++) {
        if (val.includes(emailsPatternList[i]) && environment.enableRicenergyPortfolio) {
          this.hasRicEnergyLogo = true;
        }
      }
    });
  }

  async getUsername() {
    this.nameUser = await this.sideMenuService.getUserName();
  }

  navigateTo(path) {
    this.utilsService.navigateTo('/' + path);
  }

  toggleMenu() {
    this.opened = !this.opened;
    this.toggleOpeneEvent.emit(this.opened);
  }

  getPageSelected() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        for (let i = 0; i < this.menuList.length; i++) {
          if (
            val.url.includes(this.menuList[i].reouteEs)
            || val.url.includes(this.menuList[i].reouteEn)
            || val.url.includes(this.menuList[i].reouteCa)
            ) {
              this.menuList[i].selected = true;
            }
        }
      }
    });
  }

  completeProfile() {
    this.profileService.getUserData().subscribe(resp => {
      const model = {
        username: resp.username,
        mailVerified: resp.enabled,
        profileType: resp.profileType,
        mobile: resp.mobile,
        phone: resp.mobile,
        mobileVerified: resp.mobileVerified
      }
      this.authService.completeProfile(model);
    });
  }

}
