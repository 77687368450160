import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverviewProjectService } from 'src/app/services/overview-project.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-invest-test-convenience',
  templateUrl: './invest-test-convenience.component.html',
  styleUrls: ['./invest-test-convenience.component.scss'],
})
export class InvestTestConvenienceComponent implements OnInit {
  @Output() emitSuccess = new EventEmitter<any>();
  @Output() testFail = new EventEmitter<any>();
  @Input() testInfo: any;
  @Input() profile: any;
  finished = false;
  loading = true;

  text: string;
  image: number;
  btnList: any;

  percentInvested: any;
  step = 0;
  itemListSelected = [];
  answer;
  answerList = [];
  btnType: string;
  prevBtn: string;
  titleBtn: string;
  times: number;
  remAtt: number;

  experience: boolean;
  extraSteps: number;

  hasToDoTest: boolean;
  check: string;
  dontShowChecked = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService,
    private overviewService: OverviewProjectService,
    private profileService: ProfileService
    ) { }

  ngOnInit() {
    this.hasToDoTest = this.testInfo !== undefined ? true : false;
    this.text = this.translate.instant('TEST_CONV.FINISH.TXT_1');
    this.image = 14;
    this.btnList = [
      {
        type: 'secondaryDark',
        index: 1,
        title: 'Completar',
      },
      {
        type: 'primaryDark',
        index: 2,
        title: 'Entiendo los riesgos',
      }
    ];
    this.getExtraSteps();
    this.enableBtn();
    this.initTest();
    this.cdRef.detectChanges();
  }

  getExperience(prof) {
    this.profile = prof;
    if (this.hasToDoTest) {
      this.next();
    } else {
      this.text = this.translate.instant('TEST_CONV.FINISH.TXT_2');
      this.image = 15;
      this.btnList = [
        {
          type: 'primaryDark',
          index: 2,
          title: 'Terminar',
        }
      ];
      this.finished = true;
    }
    this.getExtraSteps();
    this.cdRef.detectChanges();
  }

  getExtraSteps() {
    this.experience = (this.profile.isExperiencedInvestor !== null || this.profile.dontRequestInvestmentExperienceInfo === true) ? false : true;
    this.extraSteps = this.experience ? 2 : 1;
    this.loading = false;
    this.cdRef.detectChanges();
  }

  initTest() {
    this.itemListSelected = [];
    this.answerList = [];
    for (let j =0 ; j < this.testInfo.length; j++) {
      for (let i = 0; i < this.testInfo[j].answers.length; i++) {
        this.testInfo[j].answers[i].selected = false;
        this.cdRef.detectChanges();
      }
      this.itemListSelected.push(false);
      this.answerList.push(false);
      this.cdRef.detectChanges();
    }
    this.loading = false;
    this.finished = false;
    this.cdRef.detectChanges();
  }

  prev() {
    if (this.step > 0) {
      this.step--;
    }
    this.enableBtn();
    this.cdRef.detectChanges();
  }

  next() {
    this.step++;
    if (this.testInfo !== undefined && this.testInfo.length + this.extraSteps <= this.step) {
      this.checkTest();
    }
    this.enableBtn();
    this.cdRef.detectChanges();
  }

  checkTest() {
    this.finished = true;
    this.loading = true;
    this. getExtraSteps();
    const correctAnswer = this.answerList.filter((item) => item === true);
    if (correctAnswer.length >= this.answerList.length / 2) {
      this.text = this.translate.instant('TEST_CONV.FINISH.TXT_2');
      this.image = 15;
      this.btnList = [
        {
          type: 'primaryDark',
          index: 2,
          title: 'Terminar',
        }
      ];
      this.sendTest();
    } else {
      this.text = this.translate.instant('TEST_CONV.FINISH.TXT_3');
      this.image = 16;
      this.btnList = [
        {
          type: 'secondary',
          index: 1,
          title: 'Repetir',
        },
        {
          type: 'primaryDark',
          index: 2,
          title: 'Entiendo los riesgos',
        }
      ];
      this.loading = false;
    }
    this.cdRef.detectChanges();
  }

  sendTest() {
    this.loading = true;
    const model = {
      convenienceTestLoan: true,
      convenienceTestShares: true,
      log: this.testInfo,
      username: this.profile.username
    };
    this.overviewService.convenienceTest(model).subscribe(() => {
      this.loading = false;
      this.cdRef.detectChanges();
    }, error => {
      this.loading = false;
      this.cdRef.detectChanges();
    });
    this.cdRef.detectChanges();
  }

  clickBtn(index) {
    if (index === 2) {
      if (!this.dontShowChecked) {
        this.emitSuccess.emit();
      } else {
        this.loading = true;
        this.profileService.updateProfile({dontRequestInvestmentExperienceInfo: true}).subscribe(() => {
          this.emitSuccess.emit();
        }, error => {
          this.emitSuccess.emit();
        })
      }

    } else {
      if (this.hasToDoTest) {
        this.getExtraSteps();
        this.step = this.extraSteps - 1 >= 0 ? this.extraSteps - 1 : 0;
        this.initTest();
      } else {
        this.step = 0;
        this.finished = false;
      }
    }
    this.cdRef.detectChanges();
  }

  selectQuestion(id, question) {
    for (let i = 0; i < this.testInfo.length; i++) {
      if (this.testInfo[i]['id'] === id) {
        for (let j = 0; this.testInfo[i]['answers'].length; j++) {
          if (this.testInfo[i]['answers'][j].id === question.id) {
            this.testInfo[i]['answers'][j].selected = true;
            this.itemListSelected[i] = true;
            this.answerList[i] = this.testInfo[i]['answers'][j].correct;
            this.enableBtn();
          } else {
            this.testInfo[i]['answers'][j].selected = false;
          }
          this.cdRef.detectChanges();
        }
      }
      this.cdRef.detectChanges();
    }
    this.cdRef.detectChanges();
  }

  enableBtn() {
    if (this.step === 0) {
      this.prevBtn = 'secondary-disabled';
      this.btnType = 'primaryDark';
    } else if (this.itemListSelected[this.step - this.extraSteps]) {
      this.btnType = 'primaryDark';
      this.prevBtn = 'secondary';
    } else {
      this.btnType = 'primaryDark-disabled';
      this.prevBtn = 'secondary';
    }
    this.cdRef.detectChanges();
  }

  otherMoment() {
    if (this.experience) {
      this.check = this.translate.instant('TEST_CONV.FINISH.TXT_4');
    }
    this.loading = false;
    this.finished = true;
    this.cdRef.detectChanges();
  }

  checked(value) {
    this.dontShowChecked = value;
    this.cdRef.detectChanges();
  }
}
