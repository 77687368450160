import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'fund-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss']
})
export class WarningComponent implements OnInit {
  @Input() place: any;
  @Input() txtOffer: any;
  @Input() data: any;
  @Output() public continueClick = new EventEmitter<void>();
  @Output() public backClick = new EventEmitter<void>();
  buttonTitle: string;
  btnType: string;
  text: string;

  constructor(public alertService: AlertService, private translate: TranslateService) { }

  ngOnInit() {
    switch (this.place) {
      case'input':
        this.btnType = 'primaryDark';
        this.buttonTitle = this.translate.instant('BID.UNDERSTAND');
        break;
      case'bid-status':
        this.btnType = 'primaryDark';
        this.buttonTitle = this.translate.instant('DELETE_ACCOUNT.CANCEL');
        break;
      case'offer-status':
        this.btnType = 'primaryDark';
        this.buttonTitle = this.translate.instant('BID.REJECT');
        break;
      case'accept-bid':
        this.btnType = 'primaryDark';
        this.text = this.translate.instant('BID.ACCEPT_BID_MSG', { name: this.data.name, price: this.data.price });
        this.buttonTitle = this.translate.instant('register.btn.accept');
        break;
      case undefined:
        this.btnType = 'primaryDark';
        this.buttonTitle = this.translate.instant('BID.CONFIG_ACCOUNT');
        break;
      default:
        this.btnType = 'primaryDark';
        break;
    }
  }
  continue() {
    this.continueClick.emit();
  }
  close() {
    this.backClick.emit();
    this.alertService.removeModal();
  }

}
