<div class="general-container">
    <div *ngIf="step === 1" class="container header">
        <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px] text-center bg-gradient-2-text" *ngIf="side === 'recover'">E-mail</h2>
        <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px] text-center bg-gradient-2-text" *ngIf="side === 'change'">{{ 'pwd.password' | translate }}</h2>
        <p class="font-sans text-center text-xl text-gray-40" *ngIf="side === 'recover'">{{ 'pwd.need' | translate }}</p>
        <p class="font-sans text-center text-xl text-gray-40" *ngIf="side === 'change'">{{ 'pwd.recover' | translate }}</p>
    </div>
    <div *ngIf="step === 1" class="input-container">
        <form *ngIf="side === 'recover'" [formGroup]="emailForm">
            <div class="row">
                <div class="col-12">
                    <fun-input
                    *ngIf="side === 'recover' && email === undefined"
                    placeholder="E-mail"
                    [parentForm]="emailForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="email"
                    type="text"
                    [errorLogin]="errorMail"
                    labelName="E-mail"
                     ></fun-input>
                     <p class="block w-full font-sans text-eggplant-80 border border-eggplant-75 rounded
                     shadow-dimension placeholder:font-sans placeholder:text-l placeholder:text-eggplant-55
                     bg-cream-100/[.10] mt-6 py-12 pl-12 pr-28" *ngIf="side === 'recover' && email !== undefined">{{ email }}</p>
                </div>
            </div>
        </form>
        <form *ngIf="side === 'change'" [formGroup]="passwordForm">
            <div class="row">
                <div class="col-12">
                     <fun-input
                     *ngIf="side === 'change'"
                     placeholder="**********"
                     [parentForm]="passwordForm"
                     [formControlKeys]="formControlKeys"
                     [errorMessages]="errorMessages"
                     [isSubmit]="isSubmit"
                     controlName="pwd1"
                     type="password"
                     [errorLogin]="errorPwd1"
                     labelName="Nueva Contraseña"
                      ></fun-input>
                      <fun-input
                      class="second-inp"
                      *ngIf="side === 'change'"
                      placeholder="**********"
                      [parentForm]="passwordForm"
                      [formControlKeys]="formControlKeys"
                      [errorMessages]="errorMessages"
                      [isSubmit]="isSubmit"
                      controlName="pwd2"
                      type="password"
                      [errorLogin]="errorPwd2"
                      labelName="Repite tu nueva contraseña"
                       ></fun-input>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="done" *ngIf="step === 3">
    <img *ngIf="side === 'recover'" src="../../../../../assets/images/img/rocket.svg" alt="e-mail">
    <img *ngIf="side === 'change'" src="../../../../../assets/images/img/rocket.svg" alt="e-mail">
    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px] text-center" *ngIf="side === 'recover'">{{ 'pwd.sent' | translate }}</h2>
    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px] text-center" *ngIf="side === 'change'">{{ 'pwd.restored' | translate }}</h2>
    <p class="font-sans text-center text-xl text-gray-40" *ngIf="side === 'recover'">{{ 'pwd.check' | translate }}</p>
    <p class="font-sans text-center text-xl text-gray-40" *ngIf="side === 'change'">{{ 'pwd.invest' | translate }}</p>
    <fun-new-button *ngIf="side === 'change'" class="next" [buttonType]="'primaryDark'" title="{{ 'btn.access' | translate }}" (eventClick)="viewprojects()"></fun-new-button>
</div>
<div *ngIf="step === 1" class="footer">
    <div class="prev">
        <fun-new-button [buttonType]="'ghost'" icon="arrow-left" [iconPosition]="'left'" [classExtra]="' !w-full '"
        title="{{ 'btn.back' | translate }}" size="xl"  (eventClick)="prev()"></fun-new-button>
    </div>
    <div class="next">
        <fun-new-button [buttonType]="'primaryDark'" icon="arrow-right" [iconPosition]="'right'" [classExtra]="' !w-full '"
        title="{{ 'btn.send' | translate }}" size="xl"  (eventClick)="submit()"></fun-new-button>
    </div>
</div>
<div class="spinner" *ngIf="step === 2">
    <fun-skeleton-list></fun-skeleton-list>
</div>
