import {
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  Renderer2,
  ChangeDetectorRef,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { ActionSheetService } from './action-sheet.service';

@Component({
  selector: 'fun-action-sheet',
  templateUrl: './action-sheet.component.html',
  styleUrls: ['./action-sheet.component.scss']
})
export class ActionSheetComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('componentPlaceholder', { read: ViewContainerRef, static: true }) componentPlaceholder: ViewContainerRef;
  @Output() public changeLevel = new EventEmitter<any>();

  public ref: ComponentRef<ActionSheetComponent>;
  childComponentRef$: Observable<ComponentRef<any>>;
  private close$: ReplaySubject<any>;
  type: string;
  title: string;
  status: string;
  enableCloseOut = false;
  closeOut = 'true';
  side = 'modal';
  currentLevel: any;

  constructor(
    private renderer: Renderer2,
    private chdRef: ChangeDetectorRef,
    private modalService: ActionSheetService,
    private platform: Platform
  ) {
    this.close$ = new ReplaySubject();
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'pop');
    this.currentLevel = 'first';
    if (this.side === 'modal') {
      this.side = 'cero';
      setTimeout(() => {
        this.side = 'modal';
        this.chdRef.detectChanges();
      }, 5);
    }
  }

  ngAfterViewInit() {
    if (this.platform.is('android')) {
      this.platform.backButton.subscribe(() => {
        this.modalService.removeModal();
      });
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'pop');
  }

  onClose(): Observable<any> {
    return this.close$.asObservable();
  }

  setTitle(title: string): void {
    this.title = title;
    this.chdRef.detectChanges();
  }
  setSide(side: string) {
    setTimeout(() => {
      if (side === 'top') {
        this.side = side;
      } else {
        this.side = 'modal';
      }
      this.chdRef.detectChanges();
    }, 50);
    this.chdRef.detectChanges();
  }

  setStatus(status: string): void {
    this.status = status;
    this.chdRef.detectChanges();
  }
  secondLevel() {
    this.currentLevel = 'second';
    this.chdRef.detectChanges();
  }
  changeCurrentLevel() {
    if (this.currentLevel === 'first') {
      this.currentLevel = 'second';
    } else {
      this.currentLevel = 'first';
    }
    this.changeLevel.emit(this.currentLevel);
    this.chdRef.detectChanges();
  }

  close(externalClose: boolean = false) {
    if (this.side === 'modal') {
      this.side = 'cero';
      this.chdRef.detectChanges();
      setTimeout(() => {
        this.modalService.removeModal();
      }, 50);
    } else {
      this.modalService.removeModal();
      this.chdRef.detectChanges();
    }
    this.chdRef.detectChanges();
  }

  getChildComponent<T>(): Observable<T> {
    return this.childComponentRef$.pipe(map((component: ComponentRef<T>) => component.instance));
  }

}
