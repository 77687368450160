<div class="general-container">
  <div class="span-container">
      <!--<span id="price-box">{{ inputValue }}</span>-->
  </div>
  <!---->
  <div *ngIf="localInvestProject === true && hasEconomicRights" class="invest-content local-invest-content">
      <div class="row locla-shared-container">
          <section class="col-6 select-content">
              <h2 class="text-h600 md:text-h800 text-center bg-gradient-2-text">{{ 'local_invest.invest_title' | translate }} {{ data.projectName }}</h2>
              <p class="text-center text-gray-40 text-l pb-24">{{ 'local_invest.invest_subtitle' | translate }}</p>
                  <div class="inp" *ngIf="hasSharedList">
                      <fun-input
                      placeholder="{{ 'local_invest.share_type' | translate }}"
                      [parentForm]="sharedForm"
                      [formControlKeys]="formControlKeys"
                      [errorMessages]="errorMessages"
                      [isSubmit]="isSubmit"
                      controlName="shared"
                      type="select"
                      labelName="{{ 'local_invest.share_type' | translate }}"
                      (selectChange)="changeSharedType($event)"
                      [items]="sharedList"
                      [selectedItem]="selectItm"
                      ></fun-input>
                  </div>
          </section>
          <section class="col-6 info-content">
              <div class="tick_container" *ngIf="hasSharedList">
                  <p>{{ 'local_invest.estimated_prof' | translate }} <span *ngIf="shareInfo.profitability !== undefined">{{ shareInfo.profitability | number:'1.2-2':'es' }}%</span></p>
                  <p>{{ 'local_invest.invest_duration' | translate }} {{ shareInfo.long_description }} {{ 'local_invest.years' | translate }}</p>
                  <p>{{ 'local_invest.repurchase_option' | translate }} <span *ngIf="shareInfo.repurchase">{{ 'local_invest.yes' | translate }}</span><span *ngIf="!shareInfo.repurchase">No</span></p>
                  <p>{{ 'MOVEMENTS.NOTICEBOARD' | translate }}: <span *ngIf="shareInfo.can_operate_in_marketplace">{{ 'local_invest.yes' | translate }}</span><span *ngIf="!shareInfo.can_operate_in_marketplace">No</span></p>
              </div>
          </section>
      </div>
  </div>
  <!---->
  <div *ngIf="localInvestProject !== true" class="invest-content">
      <h2 class="text-h600 md:text-h800 text-center bg-gradient-2-text" *ngIf="data.status !== 14">{{ 'invest.Process.Title' | translate }}</h2>
      <h2 class="text-h600 md:text-h800 text-center bg-gradient-2-text" *ngIf="data.status === 14">{{ 'OVERVIEW.CAPITAL_INCREASE.TITLE' | translate }}</h2>
      <p class="text-center text-gray-40 text-l pb-2 mx-auto md:w-3/4" *ngIf="data.status !== 14">{{ 'invest.text' | translate }}
          <span  *ngIf="this.data.allowedAmountModules !== '' ">{{ 'invest.allow' | translate }} </span>
          <span class="bolder" *ngIf="this.data.allowedAmountModules !== '' "> {{ this.data.allowedAmountModules }}</span></p>
        <fun-info *ngIf="hasReservedMoney"  [data]="infoReserveData" (clickBtnEvent)="invest()"></fun-info>
      <p class="increase" *ngIf="data.status === 14" innerHTML="{{ 'OVERVIEW.CAPITAL_INCREASE.INCREASE_TEXT' | translate: { amount: data.max_allowed_amount_to_invest_for_user } }}"></p>
      <form class="md:grid md:grid-cols-2 gap-x-24" [formGroup]="cashForm" (submit)="invest()">
          <div class="pay-info">
            <div class="amount">
                <!--pattern="\d*,?\d*?" en un input tipo text-->
                <fun-input
                placeholder="0 €"
                [parentForm]="cashForm"
                [formControlKeys]="formControlKeys"
                [errorMessages]="errorMessages"
                [isSubmit]="isSubmit"
                controlName="cash"
                type="invest"
                [errorLogin]="errorCash"
                labelName="{{ 'local_invest.cap_to_invest' | translate }} "
                [value]="amount"
                (keyup)="sendValue()"
                #inputAmount></fun-input>
                <div class="co2">
                    <span class="text-s text-gray-40">{{ 'invest.Process.avoid' | translate }}</span>
                    <h3 class="text-eggplant-80 text-l" *ngIf="!moreTanAvailable">{{ prevent | co2 }}</h3>
                    <h3 class="text-eggplant-80 text-l" *ngIf="moreTanAvailable">-</h3>
                </div>
                <div class="promotional_money_content flex" *ngIf="promotionalMoney > 0">
                    <div class="">
                        <ion-toggle [checked]="addPromoChecked" (ionChange)="checkAddPromo(); calculate();"></ion-toggle>
                    </div>
                    <p class="text-m text-eggplant-100/[0.4] relative pl-12" [ngClass]="{'checked': addPromoChecked }">{{ 'INVEST.INVEST' | translate }} {{ promotionalMoney | number:'1.0-0':'es' }}€ {{ 'INVEST.PROMO_AVAILABLE' | translate }}
                        <span *ngIf="addPromoChecked && cashForm.value.cash !== '' && cashForm.value.cash !== '0' ">{{ 'INVEST.TOTAL_TO_INVEST' | translate }} {{ totalMoney | number:'1.0-0':'es' }}€</span>
                    </p>
                </div>
                <div *ngIf="errorMaxAmount" class="error_amount_msg">
                    <p>{{ 'OVERVIEW.CAPITAL_INCREASE.LIMIT_INCREASE' | translate }} {{ data.max_allowed_amount_to_invest_for_user }}€</p>
                </div>
            </div>
            <div class="advise" *ngIf="profile.isExperiencedInvestor === null || showAdvice">
                <p class="text-s text-gray-40" *ngIf="profile.isExperiencedInvestor === null">{{ 'INVEST.RISK' | translate }}
                    <span class="text-orange-100 cursor-pointer" *ngIf="profile.dontRequestInvestmentExperienceInfo !== true" (click)="openTest()">{{ 'INVESTMENT_RESERVATION.READ_MORE' | translate }}</span>
                </p>
                <p class="text-s text-gray-40" *ngIf="profile.isExperiencedInvestor !== null && showAdvice">{{ 'INVEST.CAPITAL' | translate }}</p>
            </div>
            <div *ngIf="!isInvestCommitment" class="code" [ngClass]="{'ref_code_included': hasReferredCode }">
                <p class="pb-6 text-l text-eggplant-80" (click)="openCode()">{{ 'invest.Process.Code' | translate }}<span> {{ symbol }} </span></p>
                <fun-input *ngIf="isOpen"
                placeholder="Introduce tu código"
                [parentForm]="cashForm"
                [formControlKeys]="formControlKeys"
                [errorMessages]="errorMessages"
                [isSubmit]="isSubmit"
                controlName="promotion"
                type="text"
                [value]="promoCode"
                [errorLogin]="errorCash"
                ></fun-input>
                <p *ngIf="hasReferredCode" class="text-l text-gray-40">{{ 'local_invest.ref_code' | translate }}</p>
            </div>
          </div>
          <div class="pay-mode" *ngIf="!isInvestCommitment">
              <div class="mt-32">
                  <span class="block text-eggplant-80 text-l">{{ 'invest.Process.pay' | translate }}</span>
              </div>
              <div class="flex flex-col gap-y-24 methods">
                <div class="method first">
                    <label>
                        <input type="radio" class="card-input-element" name="stockPackageId" id="pm-tdc"
                        value="1" formControlName="stockPackageId" (click)="setTypeForm(1)"/>
                        <div class="panel panel-default cursor-pointer flex" [ngClass]="{'checked-card': method === 1, 'no-checked-card': method !== 1 }">
                            <div class="px-24 py-16 w-full">
                                <p class="text-eggplant-80 text-xl text-left">{{ 'invest.Process.card' | translate }}</p>
                                <h4 class="text-left text-l text-gray-40">{{ 'invest.Process.max' | translate }}</h4>
                            </div>
                            <div class="flex items-center justify-center pr-24">
                                <img src="../../../../../assets/images/invest/visa-mastercard.svg" alt="">
                            </div>
                        </div>
                    </label>
                </div>
                <div *ngIf="data.hasWallet" class="method second">
                    <label>
                        <input type="radio" class="card-input-element" name="stockPackageId" id="pm-wallet"
                        value="2" formControlName="stockPackageId" (click)="setTypeForm(2)"/>
                        <div class="panel panel-default cursor-pointer flex" [ngClass]="{'checked-card': method === 2,'no-checked-card': method !== 2 }">
                            <div class="px-24 py-16 w-full">
                                <p class="text-eggplant-80 text-xl text-left">{{ 'invest.Process.Fundeen' | translate }}</p>
                                <h4 class="text-left text-l text-gray-40">{{ 'invest.Process.available' | translate }} {{ data.moneyOutBalance | number:'1.0-0':'es' }} €</h4>
                            </div>
                            <div class="flex items-center justify-center pr-24">
                                <img src="../../../../../assets/images/invest/fundeen-payment.svg" alt="">
                            </div>
                        </div>
                    </label>
                </div>
              </div>
          </div>
      </form>
  </div>
  <div class="button-container">
      <div *ngIf="hasSharedType && !localInvestProject" class="prev">
          <fun-new-button size="xl" (eventClick)="prev()" icon="arrow-left" [iconPosition]="'left'"
            [buttonType]="'ghost'" title="{{ 'local_invest.go_back' | translate }}"></fun-new-button>
      </div>
      <div class="next">
          <fun-new-button size="xl" (eventClick)="invest()" class="next-btn" [classExtra]="' !w-full '"
          [buttonType]="sendXHR" title="{{ 'AUTOINVEST.FOOTER.NEXT' | translate }}"></fun-new-button>
      </div>
  </div>
</div>
