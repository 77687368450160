import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionSheetService } from 'src/app/shared/components/action-sheet/action-sheet.service';
import { CommunicationService } from './communication.service';

@Injectable({
  providedIn: 'root',
})
export class PortfolioService {
  constructor(
    private communicationService: CommunicationService,
    public actionSheetService: ActionSheetService,
    private translate: TranslateService
  ) {}

  getProjects() {
    return this.communicationService.get('apiservices/investment/v1/investments/detailProjectsInvested/', null);
  }

  getGlobalInfo() {
    return this.communicationService.get('apiservices/dashboard/v1/dashboard/investedProjectStatistics/', null);
  }

  getUpdates() {
    return this.communicationService.get('apiservices/project/v1/projects/updates', null);
  }
  getNextBenefits() {
    return this.communicationService.get('apiservices/investment/v1/investments/nextBenefits', null);
  }
}
