import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MultipleValidator } from '../invest-multiple.validator';
import { ToastService } from '../../toast/toast.service';
import { InputComponent } from '../../input/input.component';
import { BiometricService } from 'src/app/services/biometric.service';

@Component({
  selector: 'app-invest-body',
  templateUrl: './invest-body.component.html',
  styleUrls: ['./invest-body.component.scss'],
})
export class InvestBodyComponent implements OnInit {
  @ViewChild('inputAmount', { static: false }) inputAmountRef: InputComponent;
  @Output() public moneyToInvest = new EventEmitter<any>();
  @Output() public increaseToInvest = new EventEmitter<any>();
  @Output() public setPayType = new EventEmitter<any>();
  @Output() public setCodePromotion = new EventEmitter<any>();
  @Output() public amountValue = new EventEmitter<any>();
  @Output() public localInvestIdEvent = new EventEmitter<any>();
  @Output() public addPromotionalMoneyEvent = new EventEmitter<any>();
  @Output() public openTestEvent = new EventEmitter<any>();
  @Input() data: any;
  @Input() errors: any;
  @Input() amount: any;
  @Input() localInvestProject: any;
  @Input() profile: any;

  public cashForm: FormGroup;
  public sharedForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorCash = false;
  public sendXHR;
  public disableInputs = false;
  public isOpen = false;
  public symbol: string;
  public method: number;
  public prevent = 0;
  moreTanAvailable: boolean;
  inputValue: any;
  ref: string;
  hasReferredCode: boolean;
  promoCode: string;

  sharedList = [];
  hasSharedList = false;
  sharedValue: number;
  hasSharedType: any;
  shareInfo: any;

  promotionalMoney = 0;
  totalMoney: number;
  addPromoChecked = false;
  pendingPromos = [];
  selectItm = 0;
  isInvestCommitment = false;
  reservedValue = 0;
  errorMaxAmount = false;
  hasEconomicRights: boolean;
  showAdvice = false;

  reservedMsg: string;
  hasReservedMoney = false;
  infoReserveData: any;

  constructor(
    private chdRef: ChangeDetectorRef,
    public translate: TranslateService,
    public toastService: ToastService,
    private biometricService: BiometricService
    ) {
    this.cashForm = new FormGroup({
      cash: new FormControl('',
      [Validators.required, Validators.min(1)]),
      promotion: new FormControl('', [Validators.minLength(1)]),
      stockPackageId: new FormControl('', [])
    });
    this.sharedForm = new FormGroup({
      shared: new FormControl('', [Validators.required])
    });
    this.formControlKeys = Object.keys(this.cashForm.controls);
    this.errorMessages = {
      min: this.translate.instant('input-errors-messages.incorrect-amount'),
      pattern: this.translate.instant('input-errors-messages.incorrect-amount'),
      required:  this.translate.instant('input-errors-messages.required'),
    };
  }

  ngOnInit() {
    if (this.data.status === 14 && this.amount === '') {
      this.amount = this.data.max_allowed_amount_to_invest_for_user.toString();
    }
    this.amount = this.amount.toString();
    // Con recompra las B
    this.isInvestCommitment = this.data.investCommitment ? true : false;
    if (this.data.reserved_list.length > 0) {
      this.data.reserved_list.forEach(element => {
        this.reservedValue = this.reservedValue + element.amount;
      });
    }
    if (this.reservedValue > 0) {
      this.reservedMsg = this.translate.instant('FORMALITIES.YOUR_RESERVE_TXT', { reservedValue: this.reservedValue});
      this.hasReservedMoney = true;
      this.amount = this.reservedValue.toString();
      this.infoReserveData = {
        txt: this.reservedMsg,
        btnTitle: this.translate.instant('AUTOINVEST.FOOTER.NEXT')
      }
      this.chdRef.detectChanges();
    }
    if (this.data.economicRights !== null && this.data.economicRights !== undefined && this.data.economicRights.length > 1) {
      this.hasEconomicRights = true;
      for (let i = 0; i < this.data.economicRights.length; i++) {
        let el: any = {};
        el.id = i;
        el.name = this.data.economicRights[i].description;
        el.id_element = this.data.economicRights[i].id;
        el.profitability = this.data.economicRights[i].profitability;
        el.can_operate_in_marketplace = this.data.economicRights[i].can_operate_in_marketplace;
        el.repurchase = this.data.economicRights[i].description.includes('B') ? true : false;
        el['long_description'] = this.data.economicRights[i].long_description;
        this.sharedList.push(el);
      }
      this.shareInfo = this.sharedList[0];
      const model = {
        id: this.shareInfo.id_element,
        prof: this.shareInfo.profitability
      };
      this.localInvestIdEvent.emit(model);
      this.hasSharedList = true;
      this.sendXHR = 'colorFull';
    } else {
      this.hasEconomicRights = false;
      this.sendXHR = 'colorFull-disabled';
    }
    if (this.data.economicRights !== null && this.data.economicRights!== undefined && this.data.economicRights.length <= 1) {
      this.localInvestProject = false;
    }
    this.promoCode = '';
    registerLocaleData(es);
    this.inputValue = this.amount;
    this.totalMoney = Number(this.inputValue) + this.promotionalMoney;
    this.symbol = ' + ';
    this.method = 0;
    this.calculate();
    this.hasReferredCode = (this.data.isFirstInvestment && this.checkValue(this.data.promotionalCode)) ? true : false;
    if (this.hasReferredCode) {
      this.isOpen = true;
      this.promoCode = this.data.promotionalCode;
    }
    if (this.data.economicRights !== null && this.data.economicRights !== undefined && this.hasEconomicRights) {
      this.hasSharedType = true;
    } else {
      this.hasSharedType = false;
    }
    this.pendingPromos = this.data.pendingP;
    let sum = 0;
    for (let i = 0; i < this.pendingPromos.length; i++) {
      sum += this.pendingPromos[i].netAmount;
    }
    const sumParse = Number(sum.toFixed(2)); // Corrige error decimal de js
    this.promotionalMoney = Math.floor(sumParse);
    const payload = {
      promos: this.pendingPromos,
      add: this.addPromoChecked
    };
    this.addPromotionalMoneyEvent.emit(payload);
    this.chdRef.detectChanges();
  }

  ngAfterViewInit() {}

  ngOnChanges() {
    this.cashForm = new FormGroup({
      cash: new FormControl('',
      [Validators.required, MultipleValidator(this.data.allowedAmountModules), Validators.min(1)]),
      promotion: new FormControl('', [Validators.minLength(1)]),
      stockPackageId: new FormControl('', [])
    });
    this.formControlKeys = Object.keys(this.cashForm.controls);
    this.errorMessages = {
      min: this.translate.instant('input-errors-messages.incorrect-amount'),
      max: this.translate.instant('input-errors-messages.incorrect-amount'),
      pattern: this.translate.instant('input-errors-messages.incorrect-amount'),
      required:  this.translate.instant('input-errors-messages.required')
    };
    this.chdRef.detectChanges();
    if (this.localInvestProject !== true) {
      this.inputAmountRef.triggerKeyup();
    }
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) {
    let valueCash = this.cashForm.value.cash.toString();
    valueCash = valueCash.replace(/\D/g, '');
    if (this.cashForm.valid && this.method !== 0 && valueCash % 1 === 0) {
      this.sendXHR = 'colorFull';
      this.chdRef.detectChanges();
    } else {
      if (this.localInvestProject !== true) {
        this.sendXHR = 'colorFull-disabled';
      }
      this.chdRef.detectChanges();
    }
    this.inputValue = valueCash;
    this.totalMoney = Number(this.inputValue) + this.promotionalMoney;
    this.increase(valueCash);
    this.calculate();
    this.controlBtn();
    this.chdRef.detectChanges();
  }

  @HostListener('document:click', ['$event']) onClickHandler(event: KeyboardEvent) {
    let valueCash = this.cashForm.value.cash.toString();
    valueCash = valueCash.replace(/\D/g, '');
    if (this.cashForm.valid && this.method !== 0 && valueCash % 1 === 0) {
      this.sendXHR = 'colorFull';
      this.chdRef.detectChanges();
    } else {
      if (this.localInvestProject !== true) {
        this.sendXHR = 'colorFull-disabled';
      }
      this.chdRef.detectChanges();
    }
    this.inputValue = valueCash;
    this.totalMoney = Number(this.inputValue) + this.promotionalMoney;
    this.increase(valueCash);
    this.calculate();
    this.controlBtn();
    this.chdRef.detectChanges();
  }

  sendValue() {
    let valueCash = this.cashForm.value.cash.toString();
    valueCash = valueCash.replace(/\D/g, '');
    this.amountValue.emit(Number(valueCash));
  }

  setTypeForm(numb) {
    this.method = numb;
    const obje = {
      cashForm: this.cashForm,
      methos : this.method
    };
    this.setPayType.emit(obje);
    let valueCash = this.cashForm.value.cash;
    valueCash.toString();
    valueCash = valueCash.replace(/\D/g, '');
    if (valueCash > 0 && this.method !== 0) {
      if (this.localInvestProject !== true) {
        this.sendXHR = 'colorFull';
      }
      this.chdRef.detectChanges();
    }
    this.controlBtn();
    this.chdRef.detectChanges();
  }

  controlBtn() {
    let valueCash = this.cashForm.value.cash.toString();
    valueCash = valueCash.replace(/\D/g, '');
    if (((Number(valueCash) + this.data.contributed) > this.data.total) && !this.addPromoChecked) {
      this.sendXHR = 'colorFull-disabled';
    } else if (this.addPromoChecked) {
      if ((Number(valueCash) + this.data.contributed + this.promotionalMoney) > this.data.total) {
        this.sendXHR = 'colorFull-disabled';
      }
    }
    if (this.method === 2 && (this.data.moneyOutBalance < valueCash)) {
      this.sendXHR = 'colorFull-disabled';
    }
    this.chdRef.detectChanges();
  }

  invest() {
    if (this.localInvestProject !== true) {
      let valueCash = this.cashForm.value.cash.toString();
      valueCash = valueCash.replace(/\D/g, '');
      this.isSubmit = true;
      if (this.cashForm.valid && Number(valueCash) % 1 === 0 && this.method !== 0 &&
        (Number(valueCash) + this.data.contributed) <= this.data.total &&
        (this.method === 1 || (this.method === 2 && (this.data.moneyOutBalance >= valueCash)))
        ) {
          if (this.localInvestProject !== true) {
            this.sendXHR = 'colorFull';
          }
          this.disableInputs = true;
          if (this.cashForm.value.promotion !== '') {
            this.setCodePromotion.emit(this.cashForm.value.promotion);
          }
          const value = {
            invest: Number(valueCash),
            code: this.cashForm.value.promotion,
            mode: this.method,
          };
          this.moneyToInvest.emit(value);
      }
      if (Number(valueCash) % 1 !== 0) {
        this.errorCash = true;
      }
    } else {
      this.localInvestProject = false;
      this.sendXHR = 'colorFull-disabled';
    }
    this.chdRef.detectChanges();
    this.inputAmountRef.triggerKeyup();
  }

  openCode() {
    this.isOpen = !this.isOpen;
    if (this.isOpen === false) {
      this.symbol = ' + ';
    } else {
      this.symbol = ' - ';
    }
    this.chdRef.detectChanges();
  }

  increase(money) {
    if (money === '') {
      this.increaseToInvest.emit(0);
    } else {
      this.increaseToInvest.emit(Number(money));
    }
    this.chdRef.detectChanges();
  }

  calculate() {
    let valueCash = this.cashForm.value.cash.toString();
    valueCash = valueCash.replace(/\D/g, '');
    if (this.data.status === 14) {
      if (Number(valueCash) > this.data.max_allowed_amount_to_invest_for_user) {
        this.isSubmit = true;
        this.errorCash = true;
        this.errorMaxAmount = true;
      } else {
        this.isSubmit = false;
        this.errorCash = false;
        this.errorMaxAmount = false;
      }
    }
    if (Number(valueCash) && Number(valueCash) !== 0) {
      const moneyToCalculate = this.addPromoChecked && this.promotionalMoney > 0 ? this.totalMoney : valueCash;
      if (this.data.co2kwh && this.data.co2kwh !== 0 && this.data.co2kwh !== null && this.data.co2kwh !== undefined) {
        this.prevent = this.data.power * 1500 * this.data.co2kwh * this.data.term * Number(moneyToCalculate) / this.data.total;
      } else {
        this.prevent = this.data.power * 1500 * 0.1 * this.data.term * Number(moneyToCalculate) / this.data.total;
      }
    } else {
      this.prevent = 0;
    }
    if (Number(valueCash) > (this.data.total - this.data.contributed)) {
      this.moreTanAvailable = true;
    } else {
      this.moreTanAvailable = false;
    }
    this.calculateSocialCap();
    this.chdRef.detectChanges();
  }

  checkValue(value) {
    if (value && value !== null && value !== undefined && value !== '') {
      return true;
    }
    return false;
  }

  changeSharedType(value) {
    this.sharedValue = this.sharedForm.value.shared;
    if (this.sharedValue !== undefined) {
      this.selectItm = this.sharedValue;
      for (let i = 0; i < this.data.economicRights.length; i++) {
        if (i === this.sharedValue) {
          this.shareInfo = this.data.economicRights[i];
          this.shareInfo.repurchase = this.shareInfo.description.includes('B') ? true : false;
          const model = {
            id: this.data.economicRights[i].id,
            prof: this.data.economicRights[i].profitability,
          };
          this.localInvestIdEvent.emit(model);
        }
      }
      this.sendXHR = 'colorFull';
    }
    this.chdRef.detectChanges();
  }

  prev() {
    this.localInvestProject = true;
    this.sendXHR = 'colorFull';
    this.chdRef.detectChanges();
  }

  checkAddPromo() {
    this.addPromoChecked = !this.addPromoChecked;
    const payload = {
      promos: this.pendingPromos,
      add: this.addPromoChecked
    };
    this.addPromotionalMoneyEvent.emit(payload);
    this.biometricService.makeMobileVibrate();
    this.chdRef.detectChanges();
  }

  openTest() {
    this.openTestEvent.emit();
    this.chdRef.detectChanges();
  }

  calculateSocialCap() {
    if (this.profile.isExperiencedInvestor !== null) {
      let socialCapLimit;
      const value = Number(this.cashForm.value.cash.replaceAll('.', ''));
      if (this.profile.profileType === 1) {
        socialCapLimit = (this.profile.annualGrossIncome + this.profile.finantialPatrimony) * 0.05;
      } else {
        socialCapLimit = (this.profile.rangeOwnResources + this.profile.rangeTurnover + this.profile.rangebalance) * 0.05;
      }
      if (socialCapLimit < value) {
        this.showAdvice = true;
      } else {
        this.showAdvice = false;
      }
    }
    this.chdRef.detectChanges();
  }
}
