<a class={{btnClass}} (click)="clickBtn()">
    <div  *ngIf="icon && iconPosition === 'left'" class="flex items-center mr-8"
    [ngClass]="{'border-r': type !== 'ghost'}">
      <div class="mr-8 overflow-hidden">
        <fun-icon [name]="icon" [style]="iconClass"></fun-icon>
      </div>
    </div>

    <div [class]="textClass">{{title}}</div>
    <div *ngIf="((icon !== 'menu') && (icon !== 'x')) && (iconPosition === 'right')" class="flex items-center"
    [ngClass]="{'border-l': type !== 'ghost'}">
      <div class="ml-8 overflow-hidden">
         <fun-icon [name]="icon" [style]="iconClass"></fun-icon>
      </div>
    </div>

    <div *ngIf="((icon === 'menu') || (icon === 'x')) && (iconPosition === 'right')" class="flex items-center">
      <div class="overflow-hidden">
         <fun-icon [name]="icon" [style]="iconClass"></fun-icon>
      </div>
    </div>
</a>