import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ModalController, NavController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'fun-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {

  @Input() public realList;

  constructor(
    private navCtrl: NavController,
    private localize: LocalizeRouterService,
    public utilsService: UtilsService,
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  readNotification(id) {
    const navigationExtras: NavigationExtras = {
      state: {
        notificationId: id
      },
    };
    this.navCtrl.navigateRoot([this.localize.translateRoute('/notifications')], navigationExtras);
    this.closeModal();
  }


  navigateTo(path) {
    this.utilsService.navigateTo('/' + path);
    this.closeModal();
  }

  closeModal() {
    this.modalController.dismiss();
  }

}
