<div id="content" class="content block fixed w-full h-full left-0 z-100 bg-white shadow-xl overflow-auto scrollbar-none"
[ngClass]="{'appear': !mobileScreen, 'dissapear': closeProccess}">
  <div>
    <fun-new-detail-overview
      [slug]="project.slug"
      (closeModalEvent)="closeModal()"
      [page]="page"
      [openProjectInvestmentProccess]="openProjectInvestmentProccess">
    </fun-new-detail-overview>
  </div>
</div>
