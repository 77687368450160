import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MovementsService } from 'src/app/services/movements.service';
import { ActionSheetService } from '../../../action-sheet/action-sheet.service';
import { IncomeInfoComponent } from '../../../income-info/income-info.component';
import { ToastService } from '../../../toast/toast.service';
import { CreateBankAccountService } from './money-out-change-account.service';
import { BICValidator, IbanValidator } from './money-out-change-account.validator';

@Component({
  selector: 'app-money-out-change-account',
  templateUrl: './money-out-change-account.component.html',
  styleUrls: ['./money-out-change-account.component.scss'],
})
export class MoneyOutChangeAccountComponent implements OnInit {
  @ViewChild('documentId', {static: false}) documentId: ElementRef;
  @Output() public goProject = new EventEmitter<any>();
  @Input() public walletId: any;
  @Input() public username: any;

  public successOperation = false;
  private fb: FormBuilder;
  public bankForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorLogin = false;
  public moneyOutInfo;
  public skeletonActive = false;
  public sendXHR;

  errorIban = false;
  errorFile = false;
  errorFileId;
  errorFileText;

  mandatoryFields;

  constructor(
    private chdRef: ChangeDetectorRef,
    private bankService: CreateBankAccountService,
    private toastService: ToastService,
    private translate: TranslateService,
    public actionSheetService: ActionSheetService,
    private movementsService: MovementsService
    ) {
    this.bankForm = new FormGroup({
      bank: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚ ]+$')]),
      iban: new FormControl('', [Validators.required, IbanValidator]),
    });

    this.formControlKeys = Object.keys(this.bankForm.controls);
    this.errorMessages = {
      required:  this.translate.instant('input-errors-messages.required'),
      pattern: this.translate.instant('input-errors-messages.format'),
      minlength: this.translate.instant('MOVEMENTS.MONEY-OUT.CHARACTERS'),
      maxlength: this.translate.instant('MOVEMENTS.MONEY-OUT.CHARACTERS')
    };
  }

  ngOnInit() {
    this.chdRef.detectChanges();
    this.sendXHR = 'primaryDark-disabled';
  }
  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) {
    this.chdRef.detectChanges();
  }
  @HostListener('document:keyup.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.chdRef.detectChanges();
  }

  openInfo() {
    const modalParams = {
      page: 'cash-out'
     };

    this.actionSheetService.showModal(IncomeInfoComponent, modalParams, '', '', true).subscribe(
      (modal: any) => {
        this.translate.get('MOVEMENTS.DEPOSIT-FUNDS.INCOME-INFORMATION').subscribe((title) => {
          modal.setTitle(title);
        });
        modal.onClose().subscribe();
      }
    );
  }

  controlForm() {
    if (this.bankForm.valid) {
      this.sendXHR = 'primaryDark';
    } else {
      this.sendXHR = 'primaryDark-disabled';
    }
    this.chdRef.detectChanges();
  }

  save() {
    this.isSubmit = true;
    this.chdRef.detectChanges();
    if (this.bankForm.valid) {
      this.mandatoryFields = false;
    } else {
      this.mandatoryFields = true;
    }
    if (this.bankForm.value.iban &&  !this.bankService.validateIBAN(this.bankForm.value.iban)) {
      this.errorIban = true;
    } else {
      this.errorIban = false;
    }
    if (!this.mandatoryFields && !this.errorIban) {
      this.sendXHR = 'primaryDark';
      this.bankForm.value.iban = this.cleanString(this.bankForm.value.iban);
      const params = {
        holder: this.bankForm.value.bank,
        iban: this.bankForm.value.iban,
        wallet: this.walletId
      };
      this.createAccount(params);
      this.sendXHR = 'primaryDark-disabled';
      this.chdRef.detectChanges();
    }
  }
  cleanString(value) {
    let item;
    item = value.toUpperCase();
    item = value.trim();
    item = value.replace(/\s/g, '');
    return item;
  }

  goProjects() {
    this.goProject.emit();
  }

  createAccount(model) {
    this.movementsService.createAccount(model).subscribe(() => {
      this.successOperation = true;
      this.chdRef.detectChanges();
    }, error => {
      this.successOperation = false;
      this.isSubmit = false;
      this.toastService.showToast('Al parecer ha ocurrido un error al adjuntar tu número de cuenta. Por favor prueba de nuevo', 'warning');
      this.chdRef.detectChanges();
    });
  }

  getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
    return zoneOriginalInstance || fileReader;
  }
}
