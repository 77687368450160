<div class="general-container px-16 md:px-0">
  <h2 class="text-eggplant-100/[0.25] font-sans text-h600">{{ 'overview.about' | translate }}</h2>
  <div>
      <div class="text-container">
          <section class="[&>p]:text-eggplant-100 [&>p]:pb-12 [&>p]:text-xl md:[&>p]:text-h400 [&>p]:font-sans opened" innerHTML="{{ text }}"></section>
      </div>
  </div>
  <h2 *ngIf="imageUrl !== null && imageUrl !== undefined" class="text-eggplant-100/[0.25] font-sans text-h600 pb-40">{{ 'OVERVIEW_PROJECT.IMG' | translate }}</h2>
  <div *ngIf="imageUrl !== null && imageUrl !== undefined" class="relative overflow-hidden">
    <ion-slides pager="true" #sliderImg (ionSlideDidChange)="getCurrentItem()" [options]="slideOpts">
        <div class="swiper-slide" *ngIf="aboutProjectInfo.id === 41">
          <app-youtube-video-player #videoPlayer></app-youtube-video-player>
        </div>
        <ion-slide *ngFor="let image of imageUrl">
            <div class="image-container" [ngStyle]="{'background':' url(' + image + ')', 'background-size': 'cover', 'background-position': 'center'}"></div>
        </ion-slide>
    </ion-slides>
    <div *ngIf="(imageUrl.length > 1)" class="w-34 h-34 md:w-96 md:h-96 arrow arrow-left" (click)="prev()" [ngClass]="{'arrow-disabled': sliderIndex === 0 }">
      <fun-icon [name]="'arrow-left'" [style]="'w-24 h-24 md:w-64 md:h-64 stroke-2 stroke-white'"></fun-icon>
    </div>
    <div *ngIf="(imageUrl.length > 1)" class="w-34 h-34 md:w-96 md:h-96 arrow arrow-right" (click)="next()" [ngClass]="{'arrow-disabled': sliderIndex === imageUrl.length - 1 }">
      <fun-icon [name]="'arrow-right'" [style]="'w-24 h-24 md:w-64 md:h-64 stroke-2 stroke-white'"></fun-icon>
    </div>
  </div>

</div>
