import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import * as momentNs from 'moment';
import { MarketPlaceBidCreateModel } from 'src/app/shared/models/marketplace.models';
import { RoundNumberService } from 'src/app/services/round-number.service';
import { HelpService } from 'src/app/services/help.service';
const moment = momentNs;

@Component({
  selector: 'fun-bid-create',
  templateUrl: './bid-create.component.html',
  styleUrls: ['./bid-create.component.scss'],
})
export class BidCreateComponent implements OnInit {

  createBidOfferData: MarketPlaceBidCreateModel;
  isBidCreatedData: boolean;

  @Input('createBidOffer')
  set createBidOffer(value: MarketPlaceBidCreateModel) {
    this.createBidOfferData = value;
    this.chdRef.detectChanges();
  }

  @Input('isBidCreated')
  set isBidCreated(value: boolean) {
    this.isBidCreatedData = value;
    this.chdRef.detectChanges();
  } // response api service

  @Output() eventConfirmBid = new EventEmitter<object>(); // data to api service
  @Output() eventButtonsRoute = new EventEmitter<string>(); // final buttons
  dataBid: any;
  remainDays: number;
  isCheckAcceptRisks = false;
  isCheckAcceptContactData = false;
  stepValue = 1;
  innerWidth: number;
  bidCreateForm: FormGroup;
  stepsContinue = [true, true, false, true];
  selectIsOpen = false;
  backBtn = 'secondaryDark-disabled';
  loaded = false;

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 40) {
      this.stepsContinue[1] = true;
      this.chdRef.detectChanges();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth >= 991) {
      this.stepsContinue[1] = true;
    } else {
      this.stepsContinue[1] = true; // false; En la app de forma nativa no me hacia el scroll y no habilitaba el boton
    }
    this.chdRef.detectChanges();
  }

  constructor(
    private formBuilder: FormBuilder,
    private chdRef: ChangeDetectorRef,
    private roundService: RoundNumberService,
    private helpService: HelpService
  ) { }

  ngOnInit() {
    this.createBidOfferData.adquisition_price = this.roundService.roundNumber(this.createBidOfferData.adquisition_price);
    registerLocaleData(es);
    this.controlStep();
    this.innerWidth = window.innerWidth;
    if (this.innerWidth >= 991) {
      this.stepsContinue[1] = true;
    } else {
      this.stepsContinue[1] = true; // false; En la app de forma nativa no me hacia el scroll y no habilitaba el boton
    }

    const today = moment( moment(new Date()).format('YYYY/MM/DD'), 'YYYY/MM/DD');
    const endDate = moment(this.createBidOfferData.closing_date, 'YYYY/MM/DD');
    this.remainDays = endDate.diff(today, 'days');

    this.bidCreateForm = this.formBuilder.group({
      amount: ['', [Validators.required, Validators.min(1)]],
      acceptRisks: ['', Validators.requiredTrue],
      acceptShareMyContact: ['', Validators.requiredTrue]
    });

    this.onFormValuesChange();
    this.loaded = true;
    this.chdRef.detectChanges();
  }

  get f() { return this.bidCreateForm.controls; }

  onFormValuesChange(): void {
    this.bidCreateForm.valueChanges.subscribe(() => {
      this.chdRef.detectChanges();
    });
  }

  onChangeAcceptRisks() {
    this.isCheckAcceptRisks = true;
    this.validateForm();
    this.chdRef.detectChanges();
  }

  onChangeAcceptContactData() {
    this.isCheckAcceptContactData = true;
    this.validateForm();
    this.chdRef.detectChanges();
  }

  onChangeAmount() {
    this.validateForm();
    this.chdRef.detectChanges();
  }

  validateForm() {
    if (this.bidCreateForm.invalid) {
      this.stepsContinue[2] = false;
      return;
    }
    this.stepsContinue[2] = true;
    this.chdRef.detectChanges();
  }

  selectOpen() {
    this.selectIsOpen = !this.selectIsOpen;
    this.chdRef.detectChanges();
  }

  nextStep() {
    this.stepValue = this.stepValue + 1;
    if (this.stepValue === 3) {
      this.dataBid = {
        offer: this.createBidOfferData.offer_id,
        price: this.bidCreateForm.value.amount,
      };
    }
    if (this.stepValue === 4) {
      this.eventConfirmBid.emit(this.dataBid);
    }
    this.controlStep();
    this.chdRef.detectChanges();
  }

  prevStep() {
    if (this.stepValue >= 1) {
      this.stepValue = this.stepValue - 1;
    }
    this.controlStep();
    this.chdRef.detectChanges();
  }
  controlStep() {
    if (this.stepValue === 1) {
      this.backBtn = 'secondaryDark-disabled';
    } else {
      this.backBtn = 'secondaryDark';
    }
    this.chdRef.detectChanges();
  }

  onEventClick(route: string) {
    this.eventButtonsRoute.emit(route);
  }
  openHelp(level, index) {
    this.helpService.openHelp(level, index, 'BID_STATUS');
  }

}
