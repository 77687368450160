<nav>
    <div class="static">
      <p (click)="selected('/my-profile')" [ngClass]="{ active: page === 'my-profile' }">
        <a>{{ 'submenu.my-profile' | translate }}</a>
      </p>
      <p (click)="selected('/movements')" [ngClass]="{ active: page === 'movements' }">
        <a>{{ 'submenu.movements' | translate }}</a>
      </p>
      <p (click)="selected('/notifications')" [ngClass]="{ active: page === 'notifications' }">
        <a>{{ 'submenu.notifications' | translate }}</a>
      </p>
      <p (click)="selected('/referral-program')" [ngClass]="{ active: page === 'referral-program' }">
        <a>{{ 'submenu.referral-program' | translate }}</a>
      </p>
    </div>

    <div class="slider">
      <ion-slides [options]="slideOpts" #slider>
          <ion-slide>
            <p (click)="selected('/my-profile')" [ngClass]="{ active: page === 'my-profile' }">
              <a>{{ 'submenu.my-profile' | translate }}</a>
            </p>
          </ion-slide>
          <ion-slide>
            <p (click)="selected('/movements')" [ngClass]="{ active: page === 'movements' }">
              <a>{{ 'submenu.movements' | translate }}</a>
            </p>
          </ion-slide>
          <ion-slide>
            <p (click)="selected('/notifications')" [ngClass]="{ active: page === 'notifications' }">
              <a>{{ 'submenu.notifications' | translate }}</a>
            </p>
          </ion-slide>
          <ion-slide>
            <p (click)="selected('/referral-program')" [ngClass]="{ active: page === 'referral-program' }">
              <a>{{ 'submenu.referral-program' | translate }}</a>
            </p>
          </ion-slide>
          <ion-slide>
            <p>
              <a></a>
            </p>
          </ion-slide>
      </ion-slides>
    </div>
  </nav>
