import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fun-update-profile-picture',
  templateUrl: './update-profile-picture.component.html',
  styleUrls: ['./update-profile-picture.component.scss'],
})
export class UpdateProfilePictureComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
