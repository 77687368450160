import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fun-register-person-civil-status',
  templateUrl: './register-person-civil-status.component.html',
  styleUrls: ['./register-person-civil-status.component.scss']
})
export class RegisterPersonCivilStatusComponent implements OnInit {

  @Input('value') value;
  @Input() isMobile: boolean;
  @Input() disabled: boolean;
  @Output() public sendInfoEvent = new EventEmitter<any>();

  status: any;

  public selectForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorAmount = false;
  public errorConcept = false;

  public selectIsOpen = false;
  hasStatusPrev: boolean;

  statusList = [
    {id: 1, name: this.translate.instant('register.civil_status.status_1') },
    {id: 2, name: this.translate.instant('register.civil_status.status_2') },
    {id: 3, name: this.translate.instant('register.civil_status.status_3') },
    {id: 4, name: this.translate.instant('register.civil_status.status_4') },
    {id: 5, name: this.translate.instant('register.civil_status.status_5') },
    {id: 6, name: this.translate.instant('register.civil_status.status_6') },
    {id: 7, name: this.translate.instant('register.civil_status.status_7') }
  ];

  constructor(public chdRef: ChangeDetectorRef, public translate: TranslateService) { }

  ngOnInit() {
    if (this.value && this.value !== undefined && this.value !== null && this.value !== '') {
      this.status = this.value;
      this.hasStatusPrev = true;
    } else {
      this.hasStatusPrev = false;
      this.status = null;
    }
    this.selectForm = new FormGroup({
      status: new FormControl(this.status !== 0 ? this.status : null, [Validators.required, Validators.min(0.01)]),
    });
    this.chdRef.detectChanges();
  }

  statusSelected() {
    this.hasStatusPrev = true;
    this.sendInfoEvent.emit(this.selectForm.value.status);
    this.chdRef.detectChanges();
  }

}
